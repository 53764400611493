import React, { useState, useEffect, useContext } from 'react';
import { FieldOptionFetchResponse, FieldData, PriceCurrencyMultiFieldType } from 'types/FieldTypes';
import { CurrencyType } from '../../../views/products/ProductTypes';
import FormFieldContext from '../FormFieldContext';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { formatPriceString } from 'services/utils/amountFormatting';
import { parsePriceToNumberOnUserLocale } from 'services/utils/parsePriceString';
import Dropdown from '../basefields/Dropdown';
import FieldWrapper from '../FieldWrapper';
import FieldViewMode from '../fields/elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';

interface PriceCurrencyMultiFieldProps extends PriceCurrencyMultiFieldType {
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void;
    dropdownData?: Record<string, FieldOptionFetchResponse>;
    errorMessage?: string | string[] | undefined;
    shouldAutoFocus?: boolean;
}

const PriceCurrencyMultiField: React.FC<PriceCurrencyMultiFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, placeholder, data, helperText, tooltipText, onChange, dropdownData, 
    viewInEditMode, shouldAutoFocus, alignment, isEditable, disabled
}) => {
    const { t } = useTranslation();
    const { userLocale, defaultCurrency } = useSettings();
    const { editing, updatedData, setUpdatedData, handleSubmit } = useContext(FormFieldContext);
    const { errorMessages, setUnsavedChanges } = useGlobalContext();
    const [price, setPrice] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState<CurrencyType | null>(null);
    const [currencies, setCurrencies] = useState<CurrencyType[]>([]);

    // Set the currencies from the dropdown data
    useEffect(() => {
        if (dropdownData && dropdownData.currency && dropdownData.currency.results) {
            // Cast the results to currency type
            const currencyResults = dropdownData.currency.results as unknown as CurrencyType[];

            // Map de currency response to the right type
            const processedCurrencies: CurrencyType[] = currencyResults.map((currency) => ({
                key: currency.key,
                id: currency.id,
                name: currency.name,
                symbol: currency.symbol,
            }));
    
            // Set the currencies
            setCurrencies(processedCurrencies);
        }
    }, [dropdownData]);
    
    // Load the current values inside the fields
    useEffect(() => {
        // Set the current price from the data
        if (data && data[name]) {
            setPrice(formatPriceString(data[name], userLocale));
        }

        // Set the current currency from the data
        if (data && `${name}_currency` in data && data[`${name}_currency`]) {
            // Find the current currency in the currency options
            const currentCurrency = currencies.find(currency => currency.key == data[`${name}_currency`]);

            // Set the current currency
            if (currentCurrency) {
                setSelectedCurrency(currentCurrency);
            }

        // Set the default currency as current currency
        } else {
            // Find the current currency in the currency options
            const defaultCurrencyOption = currencies.find(currency => currency.key == defaultCurrency);
            
            // Set the current currency
            if (defaultCurrencyOption) {

                // Set the default currency
                setSelectedCurrency(defaultCurrencyOption);

                // Update the updated data with the default currency without selecting it
                setUpdatedData((prevData: Record<string, any>) => ({
                    ...updatedData,
                    ...prevData,
                    [`${name}_currency`]: defaultCurrencyOption.key,
                }));
            }
        }
    }, [data, name, currencies]);

    // Updates the value after changing the price
    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Set the price
        setPrice(e.target.value);

        // Parse to float value but keep de decimal separation
        const parsedValue = parsePriceToNumberOnUserLocale(e.target.value, userLocale);

        setUpdatedData((prevData: Record<string, any>) => ({
            ...updatedData,
            ...prevData,
            [name]: parsedValue
        }));
        if (e.target.value !== data[name]) {
            setUnsavedChanges(viewKey, true);
        }
    };

    // Handle the currency change
    const handleCurrencyChange = (selectedValue: string) => {

        // Find the selected value inside the currencies
        const selectedCurrencyOption = currencies.find(currency => currency.id == parseInt(selectedValue));

        if (selectedCurrencyOption) {
            // Set the selectedValueInt as selected currency
            setSelectedCurrency(selectedCurrencyOption);

            // Update the updated data
            setUpdatedData((prevData: Record<string, any>) => ({
                ...updatedData,
                ...prevData,
                [`${name}_currency`]: selectedCurrencyOption.key,
            }));

            // Set unsaved changes
            setUnsavedChanges(viewKey, selectedCurrencyOption.key !== data["currency"]);

            // Callback the on change
            if (onChange) {
                onChange({
                    target: {
                        value: `${selectedCurrencyOption.key}`
                    }
                } as React.ChangeEvent<HTMLInputElement>);
            }
        }        
    }

    // Submits the value after pressing enter
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (name && !errorMessages[name]) {
                if (handleSubmit) handleSubmit();
            }
        }
    }

    // Get the error message from the errorState
    const errorMessage = name && errorMessages[name];

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <div className='price-currency'>
                    <input 
                        type="text"
                        id={`${name}_price`}
                        name={`${name}_price`}
                        value={price}
                        onChange={handlePriceChange}
                        placeholder={t(placeholder)}
                        disabled={disabled}
                        autoFocus={shouldAutoFocus}
                        className={errorMessage ? 'is-invalid' : ''}
                        onKeyDown={handleKeyDown}
                    />
                    <Dropdown<CurrencyType>
                        options={currencies}
                        id={`${name}_currency`}
                        name={`${name}_currency`}
                        disabled_selected={t('product.general.currency_placeholder')}
                        selectedOption={selectedCurrency}
                        // value={selectedCurrency ? selectedCurrency.id : null}
                        onChange={(selectedValue) => handleCurrencyChange(selectedValue)}
                        selectionFormat={(option) => `${option.key} (${option.symbol})`}
                        optionFormat={(option) => `${option.name}`}
                        allowNoneOption={false}      
                        disabled={disabled}                      
                    />
                </div>
            ) : (
                // View mode
                <FieldViewMode 
                    type="price"
                    value={price} 
                    currencySymbol={selectedCurrency && selectedCurrency.symbol ? selectedCurrency.symbol : null}
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default PriceCurrencyMultiField;
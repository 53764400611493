import { useAuthContext } from "services/authentication/AuthenticationContext";
import { FeaturesPerPackage } from "./packageFeatures";
import { roleSpecificFeatures } from "./roleSpecificFeatures";
import { getReleasedFeatures } from "./releasedFeatures";

/*
 * permissionChecks.ts
 * Custom hooks which can be used to check if a certain feature is active in the
 * current package of the environment, if the current user is allowed to see a
 * feature based on its role or if a current user is allowed for a certain right.
 */

// Checks if the feature is available in the current package of the environment
export const usePackageFeature = (featureName: string): boolean => {
    const { environmentPackage } = useAuthContext();
    let isEnabled = false;

    if (environmentPackage in FeaturesPerPackage) {
        const features = FeaturesPerPackage[environmentPackage as keyof typeof FeaturesPerPackage];
        if (features.includes(featureName)) {
            isEnabled = true;
        }
    }
    
    return isEnabled;
};


// Checks if the feature is active in the environment and if the current user
// is allowed to see it based the allowed features in its role
export const useActiveFeature = (featureName: string): boolean => {
    const { activeFeatures = [], allowedFeatures = [] } = useAuthContext();

    const isActive = activeFeatures.includes(featureName);
    const isRoleSpecific = roleSpecificFeatures.includes(featureName);

    // If the feature is role specific, check if the feature is in activeFeatures and allowedFeatures
    if (isRoleSpecific) {
        return isActive && allowedFeatures.includes(featureName);
    }

    // If the feature is not role specific, check if the feature is in activeFeatures
    return isActive;
};


// Checks if the current user has permissions for a certain right
export const useAllowedRight = (rightName: string): boolean => {
    const { allowedRights = [] } = useAuthContext();
    return allowedRights.includes(rightName);
}

// Helper function to filter the options to only the curently active, allowed and released features
export const useFilteredDropdownOptionsByFeature = (
    dropdownOptions: { value: string; name: string; requiredFeature?: string }[]
) => {
    const { activeFeatures, allowedFeatures } = useAuthContext();
    const releasedFeatures = getReleasedFeatures();
    
    return dropdownOptions.filter(dropdownOption => {

        // If the required feature prop is given, check if it is active, allowed and released
        if (dropdownOption.requiredFeature) {
            return (
                activeFeatures.includes(dropdownOption.requiredFeature) &&
                allowedFeatures.includes(dropdownOption.requiredFeature) &&
                releasedFeatures.includes(dropdownOption.requiredFeature)
            );
        }

        // If no required feature prop is given, always include the option
        return true;
    });
};
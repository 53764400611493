import React from 'react';
import { ImportFields, ImportValidations } from 'components/import/ImportTypes';
import ImportWizard from 'components/import/ImportWizard';
import { faBuilding, faBuildingUser, faUser } from '@fortawesome/free-solid-svg-icons';

// Rename the name of the config
const ContactImport: React.FC = () => {

    // Set the api object
    const apiObject = 'contact'

    // Set the url to return to on close
    const closeUrl = 'contacts'

    // Define the import types
    const importTypes = [
        {   
            value: 'combined_contacts', 
            label: 'import.type.contact.combined_list',
            icon: faBuildingUser
        },
        { 
            value: 'organizations', 
            label: 'import.type.contact.organization_list',
            icon: faBuilding
        },
        { 
            value: 'persons', 
            label: 'import.type.contact.person_list',
            icon: faUser
        },
    ]

    // Define the import fields
    const importFields: ImportFields = {
        baseFields: [
            {
                contact: [
                    { value: 'name', name: 'contact.name', required: true },
                    { value: 'number', name: 'contact.number' },
                    { value: 'reference', name: 'contact.reference' },
                    { value: 'coc_number', name: 'contact.coc_number' },
                    { value: 'vat_number', name: 'contact.vat_number' },
                    { value: 'iban_number', name: 'contact.iban_number' },
                    { value: 'contact_type', name: 'contact.contact_type', description: 'contact.contact_type_description' },
                    { value: 'phone', name: 'contact.phone', allowDuplicates: true },
                    { value: 'email', name: 'contact.email', allowDuplicates: true },
                ],
            },
            {
                location: [
                    { value: 'street', name: 'location.street', conditionalRequired: true },
                    { value: 'house_number', name: 'location.house_number' },
                    { value: 'postal_code', name: 'location.postal_code' },
                    { value: 'city', name: 'location.city', conditionalRequired: true },
                    { value: 'country', name: 'location.country' },
                ],
            },
            {
                person: [
                    { value: 'title', name: 'person.title' },
                    { value: 'full_name', name: 'person.full_name' },
                    { value: 'first_name', name: 'person.first_name' },
                    { value: 'last_name', name: 'person.last_name' },
                    { value: 'role', name: 'person.role' },
                ],
            }
        ],
        dynamicFields: (importType: string) => {
            if (importType === 'combined_contacts') {
                return [
                    {
                        contact: [
                            { value: 'entity_type', name: 'contact.entity_type', description: 'contact.entity_type_description' },
                        ]
                    }
                ];
            }
            return [];
        }
    };

    // Define import validations
    const importValidations: ImportValidations = {
        validations: [
            {
                field: 'number',
                validation: 'notSelected',
                title: 'import.mapping.contact.number',
                message: 'import.validation.contact_number_not_selected_description',
                resolve: 'generateNumber',
            },
            {
                field: 'house_number',
                validation: 'notSelected',
                dependentOn: ['street', 'city'],
                title: 'import.mapping.location.house_number',
                message: 'import.validation.house_number_not_selected_description',
                resolve: 'splitHouseNumber',
            },
            {
                field: 'contact_type',
                validation: 'mapValuesToOptions',
                title: 'import.validation.contact_type_mapping_title',
                message: 'import.validation.contact_type_mapping_description',
                options: [
                    { value: 'contact', label: 'contact.contact_type.contact' },
                    { value: 'prospect', label: 'contact.contact_type.prospect' },
                    { value: 'client', label: 'contact.contact_type.client' },
                    { value: 'supplier', label: 'contact.contact_type.supplier' },
                ],
            },
        ],
        dynamicValidations: (importType: string) => {
            if (importType === 'combined_contacts') {
                return [
                    {
                        field: 'entity_type',
                        validation: 'notSelected',
                        title: 'import.validation.entity_type_not_selected_title',
                        message: 'import.validation.entity_type_not_selected_description',
                        resolve: 'determineContactEntityType'
                    },
                    {
                        field: 'entity_type',
                        validation: 'mapValuesToOptions',
                        title: 'import.validation.entity_type_mapping_title',
                        message: 'import.validation.entity_type_mapping_description',
                        options: [
                            { value: 'person', label: 'contact.entity_type.person' },
                            { value: 'organization', label: 'contact.entity_type.organization' },
                        ],
                    },
                ]
            }
            return [];
        }
    };

    return (
        <ImportWizard
            apiObject={apiObject}
            importTypes={importTypes}
            importFields={importFields}
            importValidations={importValidations}
            closeUrl={closeUrl}
        />
    );
};

export default ContactImport;
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { EventsListType, EventsByResourceType, ResourceType, SchedulingViewType, EventType } from './SchedulingTypes';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import JobForm from '../../views/jobs/JobForm';
import IconButton from 'components/buttons/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faGripLines, faInbox, faRotateLeft, faRotateRight, faTableColumns, faTableList, faWrench } from '@fortawesome/free-solid-svg-icons';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { useFetchData } from 'services/api/useFetchData';
import { UserType } from '../../views/users/UserTypes';
import { AppointmentType, JobType } from '../../views/jobs/JobTypes';
import JobModal from '../../views/jobs/JobModal';
import { useWebSocket } from 'services/api/useWebSocket';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { generateDisplayDays, determineAppointmentFetchParams, getSchedulingSettings, navigatePeriod, generateDayKey, generateTimeKeyFromTimeString, generateTimeSlots, generateHeaderHours, generateTimeKeyFromDateTimeString, determineNearestTimeSlot } from './functions/schedulingUtils';
import DropdownButton from 'components/buttons/DropdownButton';
import CustomizeSchedulingModal from './CustomizeSchedulingModal';
import DateNavigationButton from 'components/buttons/DateNavigationButton';
import { useSettings } from 'services/settings/SettingsContext';
import JobInbox from './JobInbox';
import useJobInboxHeight from './functions/useJobInboxHeight';
import { convertRowsToLocalDateTimes } from 'services/utils/sortDates';
import useCalculatePixelsPerMinute from './functions/usePixelsPerMinute';
import Event from './Event';
import useAdjustRowsSize from './functions/useAdjustRowsSize';
import '../../style/scss/scheduling.scss'
import { useGlobalContext } from 'GlobalContext';
import { v4 as uuidv4 } from 'uuid';

const transformUserToResourceType = (data: UserType[]) => {
    return data.map((user: any) => ({
        id: user.id.toString(),
        name: `${user.full_name}`,
        image: user.profile_picture, 
        remark: user.skills, 
        // remark: 'test'
    }))
}

// DONE
// - Create scheduling view with hours
// - Load appointments inside the resource/day/hours
// - Show hours (in quarters) in scheduler
// - Drag appointments to change day/hours/resource
// - Styled columns view next to stacked view
// - Today indicator
// - Hours in header tonen
// - border div 
// - Change view button
// - Fix appointment dragging locally


// TODO
// - Event/appointment stylen die in de unassigned row staat
// - Update appointment in backend after dragging -> convert dayKey & timeSlot in to new startDateTime and endDateTime
// - Load new appointments by websocket
// - Fix dragging of resources locally
// - Now indicator (line)
// - Drag job from inbox to scheduling to schedule
// - On save: convert local datetimes back to utc
// - Customize scheduling modal
// - Calendar hover to add job (hover with +)

// LATER:
// - Appointment box with text and colors/icons ?
// - Reveal scheduler after completely loading


interface DraggingItemType {
    itemId: number;
    draggableKey: string;
    dragOverKey?: string;
}

const Scheduler: React.FC = () => {
    const { t } = useTranslation();
    const { environmentHash } = useAuthContext();
    const { setFloatingAlert } = useGlobalContext();
    const { environmentSettings, userLocale, userTimezone } = useSettings();
    const { initializeModal, modalLoading } = useModal();
    const [view, setView] = useState<SchedulingViewType>(getSchedulingSettings(environmentSettings));
    const [days, setDays] = useState<Date[]>(generateDisplayDays(view));
    const [timeSlots, setTimeSlots] = useState<string[] | undefined>(generateTimeSlots(view));
    const [headerHours, setHeaderHours] = useState<string[] | undefined>(generateHeaderHours(view))
    const [users, setUsers] = useState<UserType[]>([]);
    const [resources, setResources] = useState<ResourceType[]>([]);
    const [events, setEvents] = useState<EventsListType>({});
    const [eventsByResource, setEventsByResource] = useState<EventsByResourceType>({})
    const [renderOnTimeline, setRenderOnTimeline] = useState<boolean>(view.viewType === 'employee_timeline');

    const userFetchParams = useMemo(() => ({ is_active: true }), []);
    const [appointmentFetchParams, setAppointmentFetchParams] = useState<{ from?: string, to?: string }>((determineAppointmentFetchParams(days)));
    const { response: fetchedUsers, loading: usersLoading } = useFetchData({ apiUrl: 'get_user_list', params: userFetchParams, transform: transformUserToResourceType });    
    const { response: fetchedAppointments, loading: appointmentsLoading } = useFetchData({ apiUrl: 'get_appointment_list', params: appointmentFetchParams });
    // const { message: appointmentsWebSocket } = useWebSocket({ url: `appointments_update/${environmentHash}/` });
    const [showInbox, setShowInbox] = useState(false);
    const [showProfilePictures, setShowProfilePictures] = useState(true);
    const schedulingBoardRef = useRef(null);
    const calendarRef = useRef(null);
    const jobInboxHeight = useJobInboxHeight(schedulingBoardRef);
    const pixelsPerMinute = useCalculatePixelsPerMinute(calendarRef, view, timeSlots); 
    const resourceRowSize = useAdjustRowsSize(schedulingBoardRef, calendarRef, view.direction);
    const [draggingItem, setDraggingItem] = useState<DraggingItemType | null>(null);

    // Set the scheduling view and other states when environment settings change
    useEffect(() => {
        const { scheduling_default_view, scheduling_direction, scheduling_display_mode, scheduling_display_start_time,
            scheduling_display_end_time, scheduling_automatic_open_job_inbox, scheduling_show_profile_pictures
        } = environmentSettings;

        // Set the view with the scheduling settings
        if (scheduling_default_view && scheduling_direction && scheduling_display_mode) {
            setView({
                viewType: scheduling_default_view,
                direction: scheduling_direction,
                showDays: scheduling_display_mode,
                displayDate: new Date(),
            });
        }

        // Set render on timeline state based on the scheduling view
        if (scheduling_default_view) {
            setRenderOnTimeline(scheduling_default_view === 'employee_timeline');
        }

        // Set the display times if they exist in the scheduling settings (and convert it to time keys ('07:00' instead of '07:00:00'))
        if (scheduling_default_view === 'employee_timeline' && scheduling_display_start_time && scheduling_display_end_time) {
            setView(prev => ({ ...prev, showTimes: {
                    startTime: generateTimeKeyFromTimeString(scheduling_display_start_time),
                    endTime: generateTimeKeyFromTimeString(scheduling_display_end_time)
                }
            }));

            setTimeSlots(generateTimeSlots(view));
        }

        // Set states based on the scheduling settings
        if (scheduling_automatic_open_job_inbox) setShowInbox(true);
        if (scheduling_show_profile_pictures !== undefined) setShowProfilePictures(scheduling_show_profile_pictures);
    }, [environmentSettings]);

    // Show the right scheduling days, times and header hours on page load
    useEffect(() => {
        setDays(generateDisplayDays(view));
        setTimeSlots(generateTimeSlots(view));
        setHeaderHours(generateHeaderHours(view));
    }, [view]);

    // Fetch appointments whenever the days or view change
    useEffect(() => {
        const updatedAppointmentParams = determineAppointmentFetchParams(days);
        setAppointmentFetchParams(updatedAppointmentParams);
    }, [days, view]);

    // Set the fetched users as resources
    useEffect(() => {
        if (fetchedUsers) {
            setUsers(fetchedUsers);

            // Create the unassigned resource
            const unassignedResource = { id: 'unassigned', name: 'unassigned' }

            // Set both the unassigned and users as resources
            setResources([unassignedResource, ...fetchedUsers]);
        };
    }, [fetchedUsers]);

    // Set the fetched data on page load
    useEffect(() => {
        if (fetchedAppointments) {
            // Convert appointment dates to local date times
            const { rowsWithLocalDatetimes } = convertRowsToLocalDateTimes<AppointmentType>(fetchedAppointments.results, 'start_datetime', 'end_datetime', userLocale, userTimezone);

            // Initialize the appoinment state maps
            const createdEventsList: EventsListType = {};
            const createdResourceEvents: EventsByResourceType = {};

            // Loop through the received appointments to convert them to events
            rowsWithLocalDatetimes.forEach((appointment: AppointmentType) => {

                // Appointments may have multiple assignees. Create an event for every assignee
                if (appointment.assignees && appointment.assignees.length > 0) {

                    // Loop through every assignee of the appointment to create an event
                    appointment.assignees.forEach(assignee => {

                        if (appointment.id !== null && appointment.start_datetime && appointment.end_datetime) {

                            // Configure the resource id for this assignee
                            const resourceId = assignee.id.toString();
                            
                            // Configure the event id. Combination of appointment.id and resource.id
                            const eventId = `${appointment.id}-${resourceId}`

                            // Map the appointment details to a new event
                            const event: EventType = {
                                id: eventId,
                                appointmentId: appointment.id,
                                resourceId: assignee.id.toString(),
                                startDateTime: appointment.start_datetime,
                                endDateTime: appointment.end_datetime,
                                startTime: generateTimeKeyFromDateTimeString(appointment.start_datetime),
                                endTime: generateTimeKeyFromDateTimeString(appointment.end_datetime),
                                deleted: appointment.deleted,
                                
                                // Set the day key and eventually the time slot to render the event in
                                dayKey: generateDayKey(new Date(appointment.start_datetime)),
                                ...(renderOnTimeline && { 
                                    timeSlot: determineNearestTimeSlot(appointment.start_datetime) 
                                }),

                                // Map appoinment information data
                                status: appointment.status,
                                job: appointment.job ?? null,
                                jobInternalReference: appointment.job_internal_reference ?? null,
                                jobLocationCity: appointment.job_work_location_city ?? null,
                                jobClientName: appointment.job_client_name ?? null,
                                jobMainDescription: appointment.job_main_description ?? null,
                                jobWorkflow: appointment.job_workflow ?? null,
                                jobStatus: appointment.job_status ?? null,
                            };

                            // Add the new event to the created events state
                            createdEventsList[eventId] = event;

                            // Add the event to the corresponding resource
                            if (!createdResourceEvents[resourceId]) {
                                createdResourceEvents[resourceId] = [];
                            }
                            createdResourceEvents[resourceId].push(eventId);
                        }
                    });

                // Create unassigned events for appointments without assignee
                } else {     
                    
                    if (appointment.id !== null && appointment.start_datetime && appointment.end_datetime) {

                        // Configure the event id. Combination of appointment.id and resource id (in this case 'unassigned')
                        const eventId = `${appointment.id}-unassigned`

                        // Map the appointment details to a new event
                        const event: EventType = {
                            id: eventId,
                            appointmentId: appointment.id,
                            resourceId: 'unassigned',
                            startDateTime: appointment.start_datetime,
                            endDateTime: appointment.end_datetime,
                            startTime: generateTimeKeyFromDateTimeString(appointment.start_datetime),
                            endTime: generateTimeKeyFromDateTimeString(appointment.end_datetime),
                            deleted: appointment.deleted,
                            
                            // Set the day key and eventually the time slot to render the event in
                            dayKey: generateDayKey(new Date(appointment.start_datetime)),
                            ...(renderOnTimeline && { 
                                timeSlot: determineNearestTimeSlot(appointment.start_datetime) 
                            }),

                            // Map appoinment information data
                            status: appointment.status,
                            job: appointment.job ?? null,
                            jobInternalReference: appointment.job_internal_reference ?? null,
                            jobLocationCity: appointment.job_work_location_city ?? null,
                            jobClientName: appointment.job_client_name ?? null,
                            jobMainDescription: appointment.job_main_description ?? null,
                            jobWorkflow: appointment.job_workflow ?? null,
                            jobStatus: appointment.job_status ?? null,
                        };

                        // Add the new event to the created events state
                        createdEventsList[eventId] = event;

                        // Add the event to the unassigned resource
                        if (!createdResourceEvents['unassigned']) {
                            createdResourceEvents['unassigned'] = [];
                        }
                        createdResourceEvents['unassigned'].push(eventId);
                    }
                }
            });

            setEvents(createdEventsList);
            setEventsByResource(createdResourceEvents);
        }
    }, [fetchedAppointments]);

    // Adjust the rows height to fill the screen
    useAdjustRowsSize(schedulingBoardRef, calendarRef, view.direction);
    
    // Handle date navigation and fetch corresponding appointments
    const handleNavigate = (direction: 'prev' | 'next' | 'date', date?: Date) => {
        let updatedView = view;

        // Handle next / prev buttons
        if (direction === 'prev' || direction === 'next') {
            updatedView = navigatePeriod(view, direction);

        // Handle date selection
        } else if (direction === 'date' && date) {
            updatedView = { ...view, displayDate: date };
        }

        // If the view is changed, update the states
        if (updatedView !== view) {
            // Update the view
            setView(updatedView);
            
            // Generate new days for the updated view
            const newDays = generateDisplayDays(updatedView);
            setDays(newDays);

            // Update the params to trigger a new appointment fetch
            const updatedAppointmentParams = determineAppointmentFetchParams(newDays);
            setAppointmentFetchParams(updatedAppointmentParams);
        }
    };

    // Generate time-slot class name, to show divider borders
    const generateTimeSlotClass = (timeSlot: string, startTime: string, endTime: string): string => {
        // If the timeslot is the same as the view start- and end time, return undefined
        if (timeSlot === startTime || timeSlot === endTime) {
            return 'time-slot';
        }

        // Determine the timeslot class based on the minutes
        const minutes = timeSlot.split(':')[1];
        if (minutes === '30') return `time-slot half-hour`;
        if (minutes === '00') return `time-slot whole-hour`;

        return 'time-slot';
    };

    // // Handle start dragging of a job inbox item
    // const handleDragStart = (e: React.DragEvent<HTMLDivElement>, draggableKey: string) => {

    //     // Set is dragging to true, to prevent loading extra weeks on dragging/scrolling down
    //     setIsDragging(true);

    //     // Set the draggableKey (`inboxJob/jobId/weekJobsRow/weekKey`) into the data transfer
    //     e.dataTransfer.setData("text/plain", draggableKey);
    //     e.dataTransfer.effectAllowed = "move";

    //     // Get the dragging item id from the draggableKey (`inboxJob/jobId/weekJobsRow/weekKey`)
    //     const itemId = Number(draggableKey.split('/')[1]);

    //     // Get the target client height as placeholder height
    //     const target = e.currentTarget as HTMLDivElement;
    //     const placeholderHeight = target.clientHeight;

    //     // Set the dragging item
    //     setDraggingItem({ itemId, draggableKey, placeholderHeight });

    //     // Hide the original item 
    //     target.style.opacity = '0.4';
    //     // setTimeout(() => {
    //     //     target.style.display = 'none';
    //     // }, 0);
    // }

    // Handle drag start, register dragging item
    const handleDragStart = (e: React.DragEvent, draggableKey: string) => {

        // Set the draggable key (`event/${appointmentId}/${currentResource}/${currentDay}/${currentTimeSlot}/${calculateDuration()}`) into the data transfer
        e.dataTransfer.setData("text/plain", draggableKey);
        e.dataTransfer.effectAllowed = "move";

        // Get the draggable item id from the draggable key
        const itemId = Number(draggableKey.split('/')[1]);

        // Set the dragging item
        setDraggingItem({ itemId, draggableKey });

        // Give the draggable item some opacity
        const target = e.currentTarget as HTMLElement;
        target.style.opacity = '0.4';
    }

    // Handle drag over, update dragging item to show the draggable placeholder
    const handleDragOver = (e: React.DragEvent, dragOverKey: string) => {
        // Allow dropping
        e.preventDefault();

        // Handle appointment dragover
        if (draggingItem && draggingItem.draggableKey?.startsWith('event')) {

            // Get the initial variables of the dragging item (e.g. `event/${appointmentId}/${currentResource}/${currentDay}/${currentTimeSlot}/${calculateDuration()}`)
            const [ initialDay, initialTimeSlot ] = draggingItem.draggableKey.split('/').slice(3, 5);

            // Get the variables of the drag over key (e.g. `timeSlot/${resourceKey}/${dayKey}/${timeSlot}`)
            const [ dragOverDay, dragOverTimeSlot ] = dragOverKey.split('/').slice(2, 4);

            // Only set the dragover key if it's not the dragged on the initial day or timeslot
            if (initialDay !== dragOverDay || initialTimeSlot !== dragOverTimeSlot) {
                if (draggingItem.dragOverKey !== dragOverKey) {
                    setDraggingItem({ ...draggingItem, dragOverKey })
                }
            }
        } 

        // Handle inbox job dragover
        if (draggingItem && draggingItem.draggableKey?.startsWith('inboxJob')) {
            if (draggingItem.dragOverKey !== dragOverKey) {
                setDraggingItem({ ...draggingItem, dragOverKey })
            }
        }
    };

    // Handle the dragging of an event, update the event data and associated appointment
    const handleEventDrag = (e: React.DragEvent, droppableKey: string) => {
        e.preventDefault();

        // Get the variables from the draggable source
        const draggableKey = e.dataTransfer.getData("text/plain");

        // Handle dropping of an event
        if (draggableKey.startsWith('event')) {
            // Get the initial variables of the dragging item (e.g. `event/${appointmentId}/${currentResource}/${currentDay}/${currentTimeSlot}/${calculateDuration()}`)
            const [ appointmentIdString, initialResource ] = draggableKey.split('/').slice(1, 3);

            // Get the variables of the droppable area (e.g. `timeSlot/${resourceKey}/${dayKey}/${timeSlot}`)
            const [ newResource, newDayKey, newTimeSlot ] = droppableKey.split('/').slice(1, 4);

            // Update the events state
            setEvents(prevEvents => {
                const updatedEvents = { ...prevEvents };
                const initialEventId = `${appointmentIdString}-${initialResource}`;
                const newEventId = `${appointmentIdString}-${newResource}`

                // Find the current event
                const currentEvent = updatedEvents[initialEventId];

                // Delete the old event
                delete updatedEvents[initialEventId];

                // Add the dragged event as new event with new id
                updatedEvents[newEventId] = {
                    ...currentEvent,
                    id: newEventId,
                    resourceId: newResource,
                    // startDateTime:  --> TO DO: convert dayKey and timeSlot into new startDateTime + endDateTime
                    // endDateTime 
                    dayKey: newDayKey,
                    timeSlot: newTimeSlot
                };

                return updatedEvents
            });

            // Update the resource events state
            setEventsByResource(prevEventsByResource => {
                const updatedEventsByResource = { ...prevEventsByResource };
                const initialEventId = `${appointmentIdString}-${initialResource}`;
                const newEventId = `${appointmentIdString}-${newResource}`

                // Remove the event from the initial resource
                updatedEventsByResource[initialResource] = updatedEventsByResource[initialResource].filter(eventId => eventId !== initialEventId);

                // Add the event to the new resource
                if (!updatedEventsByResource[newResource]) {
                    updatedEventsByResource[newResource] = [];
                }
                updatedEventsByResource[newResource].push(newEventId);

                return updatedEventsByResource;
            })
        
            // TODO: Show this after successfully saving in backend
            // Show floating alert when succesfully dragging
            setFloatingAlert({ 
                type: 'success',
                message: 'Appointment changed successfully!'
            });
        }

        // Handle dropping of a inbox job
        if (draggableKey.startsWith('inboxJob')) {

            // Get the job id from the initial dragging item (e.g. `inboxJob/jobId/weekJobsRow/weekKey` or `inboxJob/jobId/pastJobsRow`)
            const jobId = draggableKey.split('/')[1];

            // `inboxJob/jobId/{durationMinutes}/{clientName}/weekJobsRow/weekKey`

            // Get the variables of the droppable area (e.g. `timeSlot/${resourceKey}/${dayKey}/${timeSlot}`)
            const [ newResource, newDayKey, newTimeSlot ] = droppableKey.split('/').slice(1, 4);

            // Create a temporary appointment uuid to serve as id
            const tempAppointmentId = uuidv4();
            
            // Create a new event
            const newEvent: EventType = {
                id: `temp-${tempAppointmentId}-${newResource}`,
                appointmentId: tempAppointmentId,
                resourceId: newResource,
                // startDateTime: appointment.start_datetime,  // TODO
                // endDateTime: appointment.end_datetime,  // TODO
                startTime: newTimeSlot,
                // endTime: generateTimeKeyFromDateTimeString(appointment.end_datetime),
                dayKey: newDayKey,
                timeSlot: newTimeSlot,
                status: 'scheduled',
                deleted: false,
                job: parseInt(jobId),
                jobClientName: null
            }

            console.log("newEvent.startTime", newEvent.startTime);

            // Update the new event in the event state
            setEvents(prev => ({ ...prev, [newEvent.id]: newEvent }));

            // Update the new event in the resource events state
            setEventsByResource(prev => ({ ...prev, [newResource]: [...(prev[newResource] || []), newEvent.id] }));
        }

        setDraggingItem(null);
    }

    // Renders the placeholder for a dragging item
    const renderDraggingPlaceholder = (draggingItem: DraggingItemType, currentDragKey: string, resourceSize?: number | string) => {

        if (draggingItem.dragOverKey) {
            // Get the variables of the dragover key
            const [ dragOverResource, dragOverDay, dragOverTimeSlot ] = draggingItem.dragOverKey.split('/').splice(1, 3);

            // Get the duration pixels
            let durationPixels;
            if (draggingItem.draggableKey.startsWith('event')) {
                // In case of dragging an event, get the calculated duration pixels
                durationPixels = draggingItem.draggableKey.split('/')[5];
            }

            if (draggingItem.draggableKey.startsWith('inboxJob')) {
                // In case of dragging an inbox job, set the duration pixels to 1 hour
                durationPixels = pixelsPerMinute! * 60;
            }

            // Get the variables of the current drag position
            const [ resource, dayKey, timeSlot ] = currentDragKey.split('/').splice(1, 3);

            // Configure placeholder if the current drag happens over the current timeslot
            if (currentDragKey.startsWith('timeSlot') && durationPixels && resourceSize && dragOverResource === resource && dragOverDay === dayKey && dragOverTimeSlot === timeSlot) {

                // Generate placeholder for events in stacked resource rows
                if (view.direction === 'stacked') {
                    return (<div style={{
                        position: 'absolute',
                        left: '0px',
                        top: '2px',
                        width: `${durationPixels}px`,
                        height: typeof resourceSize === 'number' ? `${Number(resourceSize) - 4}px` : resourceSize,
                        backgroundColor: 'rgba(211,211,211,0.5)',
                        pointerEvents: 'none'
                    }} />)
                }

                // Generate placeholder for events in columns resource rows
                if (view.direction === 'columns') {
                    return (<div style={{
                        position: 'absolute',
                        left: '2px',
                        top: '0px',
                        width: typeof resourceSize === 'number' ? `${Number(resourceSize) - 4}px` : resourceSize,
                        height: `${durationPixels}px`,
                        backgroundColor: 'rgba(211,211,211,0.5)',
                        pointerEvents: 'none'
                    }} />)
                }
            }

            // Configure placeholder if the current drag happens over the current day column
            if (currentDragKey.startsWith('dayColumn') && dragOverResource === resource && dragOverDay === dayKey) {
                return (<div style={{
                    position: 'relative',
                    marginTop: '0.25rem',
                    height: '1.25rem',
                    padding: '0.25rem',
                    borderRadius: '0.25rem',
                    backgroundColor: 'rgba(211,211,211,0.5)',
                    pointerEvents: 'none'
                }} />)
            }
        }
    };

    const handleDragEnd = (e: React.DragEvent) => {
        const target = e.currentTarget as HTMLElement;
        target.style.opacity = '1';
    }

    const handleAddJob = (e: React.MouseEvent) => {
        // e.preventDefault();
        // initializeModal(React.cloneElement(<JobForm />, {}), {title: t('button.add_object_label', { object_name: t(`job.general.object_name.singular`) }) } );
    }

    const today = new Date().toLocaleDateString('nl-NL', { weekday: 'short', day: 'numeric', month: 'numeric' });

    // Handle the change of the view by the change view button
    const handleChangeView = (newViewType: 'employee_list' | 'employee_timeline') => {
        setView(prev => { 
            const updatedView = { ...prev, viewType: newViewType } 

            if (newViewType === 'employee_timeline') {
                const { scheduling_display_start_time, scheduling_display_end_time } = environmentSettings;

                if (scheduling_display_start_time && scheduling_display_end_time) {
                    updatedView.showTimes = {
                        startTime: generateTimeKeyFromTimeString(scheduling_display_start_time),
                        endTime: generateTimeKeyFromTimeString(scheduling_display_end_time),
                    };
                }
            }
            return updatedView;
        });

        setRenderOnTimeline(newViewType === 'employee_timeline');
    };

    return (
        <>
            {/* Buttons on top of the page */}
            <div className='container scheduling-header'>
                <div className='content-left'>
                    <PrimaryButton
                        onClick={() => initializeModal(React.cloneElement(<JobForm />, {}), {title: t('button.add_object_label', { object_name: t(`job.general.object_name.singular`) }) } )}
                        onlyViewRestriction={true}
                        label={t('button.plus_add_object_label', { object_name: t(`job.general.object_name.singular`) })}
                        size="small"/>
                </div>
                <div className='content-middle'></div>
                <div className='content-right button-container'>
                    <DateNavigationButton
                        onNavigate={handleNavigate}
                        currentDate={days} />
                    <IconButton
                        onClick={() => {setView(prev => ({ ...prev, direction: prev.direction === 'columns' ? 'stacked' : 'columns' }))}}
                        icon={<FontAwesomeIcon icon={view.direction === 'stacked' ? faRotateLeft : faRotateRight} />}
                        tooltipText='scheduling.scheduling_header.change_view_direction_tooltip'
                        size="small" />
                    <DropdownButton
                        label={view.viewType === 'employee_list' ? 'scheduling.scheduling_header.list_view_label' : view.viewType === 'employee_timeline' ? 'scheduling.scheduling_header.timeline_view_label' : 'Change view'}
                        activeItem={view.viewType === 'employee_list' ? 'scheduling.scheduling_header.list_view_label' : view.viewType === 'employee_timeline' ? 'scheduling.scheduling_header.timeline_view_label' : ''}
                        size="small"
                        items={[{ label: 'scheduling.scheduling_header.list_view_label', onClick: () => handleChangeView('employee_list') },
                                { label: 'scheduling.scheduling_header.timeline_view_label', onClick: () => handleChangeView('employee_timeline') } ]} />
                    <IconButton
                        onClick={() => initializeModal(React.cloneElement(<CustomizeSchedulingModal jobId={3} setLoading={() => {}} />), { modalSize: 'medium' })}
                        icon={<FontAwesomeIcon icon={faWrench} />}
                        tooltipText='scheduling.scheduling_header.customize_button_tooltip'
                        size="small" />
                    <IconButton
                        onClick={() => {setShowInbox(!showInbox)}}
                        icon={<FontAwesomeIcon icon={faInbox} />}
                        label='Inbox'
                        isActive={showInbox}
                        size="small" />
                </div>
            </div>

            <div ref={schedulingBoardRef} className={`scheduling-board ${view.direction} ${view.viewType} grid-${days.length}-columns ${showInbox ? 'show-inbox' : ''}`}>
                <div ref={calendarRef} className='calendar'>

                    {/* Render calendar header */}
                    <div className={`header grid-${days.length}-columns`}>
                        <div className='name-column'></div>
                        {days.map((day, index) => {
                            return (
                                <div className='day-column' key={index}>
                                    <div className='date-name'>
                                        <span className={day.toLocaleDateString('nl-NL', { weekday: 'short', day: 'numeric', month: 'numeric' }) === today ? 'today' : ''}>
                                            {day.toLocaleDateString('nl-NL', { weekday: 'short', day: 'numeric', month: 'numeric' })}
                                        </span>
                                    </div>
                                    {renderOnTimeline && headerHours && (
                                        <div className='header-hours'>
                                            {headerHours.map((headerHour, hourIndex) => {
                                                return (
                                                    <div key={hourIndex} className={`header-hour ${hourIndex === 0 ? 'invisible' : ''}`}>
                                                        {headerHour}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </div>

                    {/* Render resource rows */}
                    <div className='resources'>
                    {resources.map((resource) => {
                        const isUnassigned = resource.id === 'unassigned';

                        const resourceRowClass = view.direction === 'stacked' 
                            ? (isUnassigned ? `unassigned-row grid-${days.length}-columns` : `resource-row adjust-height grid-${days.length}-columns`)
                            : (isUnassigned ? `unassigned-row adjust-width` : `resource-row adjust-width`)

                        const resourceSize = isUnassigned ? '2.75rem' : resourceRowSize;

                        return (
                            <div className={resourceRowClass} draggable={isUnassigned ? false : true}>

                                {/* Resource name */}
                                <div className='name-column'>
                                    {isUnassigned ? (
                                        t('scheduling.scheduling_board.unassigned_name_label')
                                    ) : (
                                        <>
                                            {resource.image && showProfilePictures && <div style={{ backgroundImage: `url(${resource.image})` }} className='resource-image' />}
                                            <div className='resource-details'>
                                                <div>{resource.name}</div>
                                                {resource.remark && <div className='remark'>{resource.remark}</div>}
                                            </div>
                                            <div className={`order-icon tooltip-icon ${resources.length === 1 ? 'visibility-hidden' : ''}`}>
                                                <FontAwesomeIcon icon={faGripLines}  />
                                                <span className="tooltip tooltip-left">{t('general.reorder')}</span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            
                                {/* Render days */}
                                {days.map((day) => {
                                    const dayKey = generateDayKey(day);
                                    const resourceEvents = (eventsByResource[resource.id] || [])
                                        .map(eventId => events[eventId])
                                        .filter(event => event !== undefined);
                                    const dayEvents = resourceEvents.filter(event => event.dayKey === dayKey);                                
                                    const droppableKey = `dayColumn/${resource.id}/${dayKey}/`
                                    
                                    return (
                                        <div className='day-column'>
                                            {renderOnTimeline && timeSlots ? (
                                                timeSlots.map((timeSlot) => {
                                                    // Generate timeslot class name, to show divider borders
                                                    const timeSlotClass = generateTimeSlotClass(timeSlot, view.showTimes!.startTime, view.showTimes!.endTime);
                                                    const droppableKey = `timeSlot/${resource.id}/${dayKey}/${timeSlot}/`

                                                    return (
                                                        <div className={timeSlotClass}
                                                             onDragOver={(e) => (handleDragOver(e, droppableKey))}
                                                             onDrop={(e) => handleEventDrag(e, droppableKey)}
                                                             onClick={(e) => handleAddJob(e)}>
                                                            {dayEvents.filter(event => event.timeSlot === timeSlot).map((event) => (
                                                                <Event
                                                                    event={event}
                                                                    currentResource={resource.id}
                                                                    currentDay={dayKey}
                                                                    currentTimeSlot={timeSlot}
                                                                    pixelsPerMinute={pixelsPerMinute}
                                                                    resourceSize={resourceSize}
                                                                    resourceDirection={view.direction}
                                                                    handleDragStart={handleDragStart}
                                                                    handleDragEnd={handleDragEnd}
                                                                />
                                                            ))}
                                                            {draggingItem && renderDraggingPlaceholder(draggingItem, droppableKey, resourceSize)}
                                                        </div>
                                                    )
                                                })
                                            ) : (
                                                <div className='day-list'
                                                     onDragOver={(e) => (handleDragOver(e, droppableKey))}
                                                     onDrop={(e) => handleEventDrag(e, droppableKey)}
                                                     onClick={(e) => handleAddJob(e)}>
                                                    {dayEvents.map((event) => (
                                                        <Event 
                                                            event={event}
                                                            currentResource={resource.id}
                                                            currentDay={dayKey}
                                                            resourceDirection={view.direction}
                                                            handleDragStart={handleDragStart}
                                                            handleDragEnd={handleDragEnd}
                                                        />
                                                    ))}
                                                    {draggingItem && renderDraggingPlaceholder(draggingItem, droppableKey)}
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                    </div>
                </div>

                {/* Job Inbox */}
                {showInbox && (
                    <JobInbox inboxHeight={jobInboxHeight}
                              onLoaded={() => {}}
                              onDrag={(itemId: number, draggableKey: string) => {setDraggingItem({ itemId, draggableKey })}}
                              onClose={() => setShowInbox(false)} />
                )}
            </div>
        </>
    )
}

export default Scheduler;
import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const LedgerAccountForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'ledgeraccount'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        { 
            type: 'radio', 
            name: 'ledger_account_type',
            label: 'ledgeraccount.general.ledger_account_type_label',
            defaultValue: 'revenue', 
            options: [
                { value: 'revenue', name: 'ledgeraccount.ledger_account_type.revenue'},
                { value: 'costs', name: 'ledgeraccount.ledger_account_type.costs'},
            ],
        },
        {
            name: 'ledger_code',
            label: 'ledgeraccount.general.ledger_code_label',
            type: 'text',
            placeholder: 'ledgeraccount.general.ledger_code_placeholder',
            helperText: 'ledgeraccount.general.ledger_code_helperText'
        },
        {
            name: 'name',
            label: 'ledgeraccount.general.name_label',
            type: 'text',
            placeholder: 'ledgeraccount.general.name_placeholder',
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default LedgerAccountForm;
import React from 'react';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const ProductCategoryForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'productcategory'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        {
            name: 'name',
            label: 'productcategory.general.name_label',
            type: 'text',
            placeholder: 'productcategory.general.name_placeholder',
        },
        { 
            type: 'dropdown', 
            name: 'ledger_account', 
            label: 'productcategory.general.ledger_account_label', 
            apiObject: 'ledgeraccount',
            disabled_selected: 'productcategory.general.ledger_account_disabled_selected',
            params: { is_active: true, ledger_account_type: 'revenue' }
        },        
        { 
            type: 'dropdown', 
            name: 'visibility', 
            label: 'productcategory.general.visibility_label', 
            tooltipText: 'productcategory.general.visibility_tooltipText',
            disabled_selected: 'forms.select_type_disabled_selected',
            defaultValue: { value: 'whole_company' },
            allowNoneOption: false,
            showSearch: false,
            hardcodedOptions: [
                { value: 'whole_company', name: 'productcategory.visibility.whole_company'},
                { value: 'only_office', name: 'productcategory.visibility.only_office'},
            ],
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default ProductCategoryForm;
import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import { renderDateTime } from 'components/lists/functions/renderListColumn';
import { useSettings } from 'services/settings/SettingsContext';

// Rename the name of the function:
const TrialList: React.FC = () => {
    const { userLocale } = useSettings();

    // Specify the options of this list:
    const query = {
        endpoint: 'get_trial_as_superuser_list',
        defaultParams: { 'is_active': true },
        object: 'trial_as_superuser'
    }
    const objectName = 'trial.general.object_name'
    const showSearch = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = true

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '2fr', field: 'start_date', label: 'trial.general.start_date_label', render: (_, row) => renderDateTime(row.start_date, userLocale) },
        { width: '3fr', field: 'company_name', label: 'trial.general.company_name_label' },
        { width: '3fr', field: 'main_user', label: 'trial.general.main_user_label' },
    ];

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'active',
            label: 'trial.general.active_trials_filter_label',
            params: { 'is_active': true },
            default: true,
        },
        {
            group: 'general.archive',
            filters: [
                {
                    id: 'deactivated',
                    label: 'trial.general.trial_archive_filter_label',
                    shortName: 'general.deactivated',
                    params: { 'is_active': false },
                }
            ]   
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            filters={filters}
            columns={columns}
            showAddButton={false}
            showSearch={showSearch}
            isPaginated={isPaginated}
        />
    );
};

export default TrialList;
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface DropzoneProps {
    acceptedFileTypes: string[];
    placeholder: string;
    onFileSelect: (file: File | null) => void;
}

const Dropzone: React.FC<DropzoneProps> = ({ 
    acceptedFileTypes, placeholder, onFileSelect
}) => {
    const { t } = useTranslation();
    const [dragOver, setDragOver] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);

    // Notice the dragging of files across the entire screen
    useEffect(() => {
        const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setDragOver(true);
        };

        const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setDragOver(false);
        };

        // Handle the drop of a file, return it to the on file select call back
        const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            setDragOver(false);

            // Select the file
            const file = event.dataTransfer.files ? event.dataTransfer.files[0] : null;

            // Return to parent component
            onFileSelect(file);
        };

        // Add and clean event listeners
        document.addEventListener('dragover', handleDragOver as any);
        document.addEventListener('dragleave', handleDragLeave as any);
        document.addEventListener('drop', handleDrop as any);

        return () => {
            document.removeEventListener('dragover', handleDragOver as any);
            document.removeEventListener('dragleave', handleDragLeave as any);
            document.removeEventListener('drop', handleDrop as any);
        };
    }, [onFileSelect]);

    // Show the file selector window when clicking on the dropzone
    const handleFileSelectClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Handle file input change, return it 
    const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Select the file
        const file = event.target.files ? event.target.files[0] : null;

        // Return to parent component
        onFileSelect(file);
    };

    return (
        <div className={`dropzone ${dragOver ? 'hover' : ''}`}
             onClick={handleFileSelectClick}>
            <input type='file'
                   onChange={handleFileInputChange} 
                   accept={acceptedFileTypes.join(',')}
                   ref={fileInputRef} 
            />
            <span onClick={handleFileSelectClick}>
                {t(placeholder)}
            </span>
        </div>
    );
}

export default Dropzone;
import axios, { CancelTokenSource } from 'axios';
import { apiBaseUrl } from 'App';
import { getCsrfToken } from 'services/authentication/csrfFunctions';

/*
 * deleteData.tsx
 * General utility function that handles deleting data to from the server. The function
 * generates a deletion request and generates a cancel token that cancels requests from 
 * canceled submissions, which prevents unwanted server requests.
 */

interface SaveDataProps {
    apiObject?: string;
    apiUrl?: string;
    itemId?: string | number | null;
    source?: CancelTokenSource;
}

export async function deleteData({ apiObject, apiUrl, itemId, source } : SaveDataProps) {

    // Retrieve the csrfToken
    const csrfToken = getCsrfToken();

    // Check if CSRF token is available
    if (!csrfToken) {
        console.error("CSRF token is not available, aborting the request.");
        return;
    }

    // Check wheter an apiObject of apiUrl are given
    if (!apiUrl && (!apiObject || !itemId)) {
        console.error("Missing props, aborting the request.");
        return;
    }

    // Configure the url
    let url: string | undefined;
    if (apiUrl) {
        url = `${apiBaseUrl}/${apiUrl}/`;
    } else if (apiObject && itemId) {
        url = `${apiBaseUrl}/delete_${apiObject}/${itemId}/`
    }

    // Configure the API request
    const apiRequestConfig = {
        url,
        method: 'delete',
        withCredentials: true,
        headers: {
            'X-CSRFToken': csrfToken,
        },
        cancelToken: source?.token,
    };

    // Delete the data from the server
    try {
        const response = await axios(apiRequestConfig);
        return response;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            console.error('Deleting not possible', error);
            throw error;
        }
    }
}
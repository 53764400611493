import React from 'react';
import { useTranslation } from 'react-i18next';

interface ImportProgressProps {
    progress: number;
    totalRows: number;
    processedRows: number;
    importStatus: string;
    failedRows: Array<{ row_number: number; error: string }> | null;
}

const ImportProgress: React.FC<ImportProgressProps> = ({ 
    progress, totalRows, processedRows, importStatus, failedRows 
}) => { 
    const { t } = useTranslation();

    return (
        <>
            {importStatus === 'in_progress' && (
                <div className='import-progress'>
                    <h2>{t('import.general.import_in_progress_header')}</h2>
                    <div>
                        <div className="progress-message">
                            <p>{t('import.general.import_progressing_label', { progress, processedRows, totalRows})}</p>
                        </div>
                        <div className="progress-bar">
                            <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
                        </div>
                    </div>
                </div>
            )}

            {importStatus === 'completed' && (
                <div className='import-progress'>
                    <h2>{t('import.general.import_completed_header')}</h2>
                    <div>
                        <div className="progress-message">
                            <p>{t('import.general.import_completed_label', { processedRows, totalRows })}</p>
                        </div>
                        <div className="progress-bar">
                            <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
                        </div>
                    </div>
                </div>
            )}

            {importStatus === 'completed_with_errors' && (
                <div className='import-progress'>
                    <h2>{t('import.general.import_completed_with_errors_header')}</h2>
                    <div>
                        <div className="progress-message">
                            <p>{t('import.general.import_completed_with_errors_label', { processedRows, totalRows })}</p>
                        </div>
                        <div className="progress-bar">
                            <div className="progress-bar-fill" style={{ width: `${progress}%` }}></div>
                        </div>

                        <div>
                            <h5>{t('import.general.not_imported_rows_label')}</h5>
                            <p>{t('import.general.not_imported_rows_description')}</p>
                            <div>
                                <ul>
                                    {failedRows?.map((row) => (
                                        <li key={row.row_number}>
                                            {t('import.general.row_number')} {row.row_number}: {row.error}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ImportProgress;
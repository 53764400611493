import React from 'react';
import { DetailCardType } from 'types/DetailPageTypes';
import { TabType } from 'types/TabTypes';
import DetailPage from 'components/layout/DetailPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { HeaderButtons } from 'types/ButtonTypes';

// Rename the name of the function:
const PdfLayoutPage: React.FC = () => {

    // Specify the parameters of this detail page:
    const apiObject = 'pdf_layout'
    const pageObject = 'pdf_layout'
    const statusBar = false
    const headerTitle = 'layout.general.customize_pdf_layout_title'

    // Define the header buttons:
    const headerButtons: HeaderButtons = [
        {   
            type: 'icon',
            icon: <FontAwesomeIcon icon={faTrash} />,
            size: 'medium',
            tooltipText: 'general.delete',
            action: 'delete',
            onSuccess: 'redirect',
            redirectTo: 'settings/templates/layouts',
        },
    ]

    // Define the detail page tabs:
    const PdfLayoutExample: TabType[] = [{ type: 'pdf-layout' }]

    // Set the default pdf layout field cards
    const defaultFieldCards: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'layout.general.layout_settings_title',
            alignment: 'horizontal',
            fields: [
                {
                    name: 'name',
                    label: 'layout.general.name_label',
                    type: 'text',
                    placeholder: 'layout.general.name_placeholder',
                },
                { 
                    type: 'dropdown', 
                    name: 'template', 
                    label: 'layout.general.template_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    dataCondition: [{ 'connected_feature': 'job' }],
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'work_order_template', name: 'layout.template.work_order'},
                        { value: 'order_invoice_template', name: 'layout.template.order_invoice'},
                    ],
                },
                { 
                    type: 'multiselect', 
                    name: 'workflow', 
                    label: 'layout.general.workflow_label',
                    apiObject: 'workflow',
                    params: {
                        is_active: true,
                        connected_feature: 'job'
                    },
                    dataCondition: [{ 'connected_feature': 'job' }],
                    disabled_selected: 'forms.select_type_disabled_selected',
                    tooltipText: 'layout.general.workflow_tooltipText',
                    allowNoneOption: false,
                    showSearch: false,
                    optionFormat: (option) => option.name,
                    selectionFormat: 'name',
                },
                { 
                    type: 'multiselect', 
                    name: 'workflow', 
                    label: 'layout.general.workflow_label',
                    apiObject: 'workflow',
                    params: {
                        is_active: true,
                        connected_feature: 'proposal'
                    },
                    dataCondition: [{ 'connected_feature': 'proposal' }],
                    disabled_selected: 'forms.select_type_disabled_selected',
                    tooltipText: 'layout.general.workflow_tooltipText',
                    allowNoneOption: false,
                    showSearch: false,
                    optionFormat: (option) => option.name,
                    selectionFormat: 'name',
                },
                { 
                    type: 'multiselect', 
                    name: 'workflow', 
                    label: 'layout.general.workflow_label',
                    apiObject: 'workflow',
                    params: {
                        is_active: true,
                        connected_feature: 'invoice'
                    },
                    dataCondition: [{ 'connected_feature': 'invoice' }],
                    disabled_selected: 'forms.select_type_disabled_selected',
                    tooltipText: 'layout.general.workflow_tooltipText',
                    allowNoneOption: false,
                    showSearch: false,
                    optionFormat: (option) => option.name,
                    selectionFormat: 'name',
                },
                { 
                    type: 'dropdown', 
                    name: 'language', 
                    dependentOn: { 
                        fieldName: 'layout_type', value: 'pdf'
                    },
                    label: 'layout.general.language_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    defaultValue: { value: 'nl-NL' },
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'nl-NL', name: 'layout.language.nl-NL'},
                        { value: 'en-US', name: 'layout.language.en-US'},
                        { value: 'en-GB', name: 'layout.language.en-GB'},
                        { value: 'de-DE', name: 'layout.language.de-DE'},
                    ],
                },
            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.background_image_settings_title',
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'switch', 
                    name: 'show_background_image', 
                    label: 'layout.general.custom_background_image_label',
                },
                { 
                    type: 'image', 
                    name: 'background_image', 
                    label: 'layout.general.upload_background_image_label', 
                    imageType: 'portrait',
                    dropzoneText: 'product.general.image_placeholder',
                    generatedFileName: {
                        preFix: 'bg_image'
                    },
                    apiField: { 
                        fileUpload: 'pdf_layout_background_image_file', 
                        fileName: 'pdf_layout_background_image_filename'
                    },
                    dataCondition: [{'show_background_image': true}],
                },
            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.letterhead_settings_title',
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'switch', 
                    name: 'show_logo', 
                    label: 'layout.general.show_logo_label',
                    tooltipText: 'layout.general.show_logo_tooltipText',
                },
                { 
                    type: 'image', 
                    name: 'logo', 
                    label: 'layout.general.upload_logo_label', 
                    imageType: 'portrait',
                    dropzoneText: 'product.general.image_placeholder',
                    generatedFileName: {
                        preFix: 'logo'
                    },
                    apiField: { 
                        fileUpload: 'pdf_layout_logo_file', 
                        fileName: 'pdf_layout_logo_filename'
                    },
                    dataCondition: [{'show_logo': true}],
                },
                // { 
                //     type: 'tabs', 
                //     name: 'logo_position', 
                //     label: 'layout.general.logo_position_label', 
                //     showIconOnly: true,
                //     options: [
                //         { value: 'left', name: 'layout.position.left', icon: 'alignLeft' },
                //         { value: 'center', name: 'layout.position.center', icon: 'alignCenter' },
                //         { value: 'right', name: 'layout.position.right', icon: 'alignRight' }
                //     ],
                //     dataCondition: [{'show_logo': true}],
                // },
                { 
                    type: 'switch', 
                    name: 'show_sender', 
                    label: 'layout.general.show_sender_label',
                    tooltipText: 'layout.general.show_sender_tooltipText',
                },
                // { 
                //     type: 'tabs', 
                //     name: 'sender_position', 
                //     label: 'layout.general.sender_position_label', 
                //     showIconOnly: true,
                //     options: [
                //         { value: 'left', name: 'layout.position.left', icon: 'alignLeft' },
                //         { value: 'right', name: 'layout.position.right', icon: 'alignRight' }
                //     ],
                //     dataCondition: [{'show_sender': true}],
                // },
                {
                    type: 'dropdown', 
                    name: 'page_offset', 
                    label: 'layout.general.page_offset_label', 
                    tooltipText: 'layout.general.page_offset_tooltipText',
                    disabled_selected: 'forms.select_type_disabled_selected',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: '0rem', name: 'forms.none_option'},
                        { value: '1rem', name: '1'},
                        { value: '2rem', name: '2'},
                        { value: '3rem', name: '3'},
                        { value: '4rem', name: '4'},
                        { value: '5rem', name: '5'},
                        { value: '6rem', name: '6'},
                        { value: '7rem', name: '7'},
                        { value: '8rem', name: '8'},
                        { value: '9rem', name: '9'},
                        { value: '10rem', name: '10'},
                        { value: '11rem', name: '11'},
                        { value: '12rem', name: '12'},
                        { value: '13rem', name: '13'},
                        { value: '14rem', name: '14'},
                        { value: '15rem', name: '15'},
                        { value: '16rem', name: '16'},
                        { value: '17rem', name: '17'},
                        { value: '18rem', name: '18'},
                    ],
                },
                {
                    type: 'dropdown', 
                    name: 'page_padding', 
                    label: 'layout.general.page_padding_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: '0px', name: '0 px'},
                        { value: '4px', name: '4 px'},
                        { value: '8px', name: '8 px'},
                        { value: '12px', name: '12 px'},
                        { value: '16px', name: '16 px'},
                        { value: '24px', name: '24 px'},
                        { value: '28px', name: '28 px'},
                        { value: '32px', name: '32 px'},
                        { value: '40px', name: '40 px'},
                    ],
                },
                {
                    type: 'dropdown', 
                    name: 'page_bottom_padding', 
                    label: 'layout.general.page_bottom_padding_label', 
                    tooltipText: 'layout.general.page_bottom_padding_tooltipText',
                    disabled_selected: 'forms.select_type_disabled_selected',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: '0rem', name: 'forms.none_option'},
                        { value: '1rem', name: '1'},
                        { value: '2rem', name: '2'},
                        { value: '3rem', name: '3'},
                        { value: '4rem', name: '4'},
                        { value: '5rem', name: '5'},
                        { value: '6rem', name: '6'},
                        { value: '7rem', name: '7'},
                        { value: '8rem', name: '8'},
                        { value: '9rem', name: '9'},
                        { value: '10rem', name: '10'},
                    ],
                },

            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.formatting_settings_title',
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'dropdown', 
                    name: 'font_family', 
                    label: 'layout.general.font_family_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'arial', name: 'Arial'},
                        { value: 'helvetica', name: 'Helvetica'},
                        { value: 'times_new_roman', name: 'Times New Roman'},
                        { value: 'verdana', name: 'Verdana'},
                        { value: 'georgia', name: 'Georgia'},
                        { value: 'tahoma', name: 'Tahoma'},
                        { value: 'courier_new', name: 'Courier New'},
                        { value: 'trebuchet_ms', name: 'Trebuchet MS'},
                        { value: 'comic_sans_ms', name: 'Comic Sans MS'},
                    ],
                },
                { 
                    type: 'dropdown', 
                    name: 'font_size', 
                    label: 'layout.general.font_size_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: '9pt', name: '9pt'},
                        { value: '10pt', name: '10pt'},
                        { value: '11pt', name: '11pt'},
                        { value: '12pt', name: '12pt'},
                    ],
                },
            ],
        },
    ]

    // Set the work order template specific field cards
    const workOrderTemplateFieldCards: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'layout.general.title_settings_title',
            dataCondition: [{ 'template': 'work_order_template' }],
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'dropdown', 
                    name: 'work_order_title_type', 
                    label: 'layout.general.document_title_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'work_order', name: 'layout.document_title.work_order'},
                        { value: 'job', name: 'layout.document_title.job'},
                        { value: 'order', name: 'layout.document_title.order'},
                        { value: 'delivery_slip', name: 'layout.document_title.delivery_slip'},
                    ],
                },
            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.details_title',
            dataCondition: [{ 'template': 'work_order_template' }],
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'switch', 
                    name: 'work_order_show_creation_date', 
                    label: 'layout.general.show_creation_date_label',
                },
                // Temporarily hide subject: not yet implemented on the job
                // { 
                //     type: 'switch', 
                //     name: 'work_order_show_subject', 
                //     label: 'layout.general.show_subject_label',
                // },
                { 
                    type: 'switch', 
                    name: 'work_order_show_job_number', 
                    label: 'layout.general.show_job_number',
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_internal_reference', 
                    label: 'layout.general.show_internal_reference_label',
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_external_reference', 
                    label: 'layout.general.show_external_reference_label',
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_workflow', 
                    label: 'layout.general.show_workflow_label',
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_status', 
                    label: 'layout.general.show_status_label',
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_labels', 
                    label: 'layout.general.show_labels_label',
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_billing_type', 
                    label: 'layout.general.show_billing_type_label',
                },
            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.appointments_section_title',
            dataCondition: [{ 'template': 'work_order_template' }],
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'switch', 
                    name: 'work_order_show_appointments_section', 
                    label: 'layout.general.show_appointments_section_label',
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_appointments_only_scheduled', 
                    label: 'layout.general.show_appointments_only_scheduled',
                    dataCondition: [{'work_order_show_appointments_section': true}],
                    tooltipText: "layout.general.show_appointments_only_scheduled_tooltipText",
                },
            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.products_section_title',
            dataCondition: [{ 'template': 'work_order_template' }],
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'switch', 
                    name: 'work_order_show_items_section', 
                    label: 'layout.general.show_products_section_label',
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_items_amount_column', 
                    label: 'layout.general.show_product_amount_column_label',
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_items_unit_price_column', 
                    label: 'layout.general.show_product_unit_price_column_label',
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_items_total_price_column', 
                    label: 'layout.general.show_product_total_price_column_label',
                },
                // Temporarily hide vat column: not sure if vat needs to be on the work order
                // { 
                //     type: 'switch', 
                //     name: 'work_order_show_items_vat_column', 
                //     label: 'layout.general.show_product_vat_column_label',
                // },
            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.hours_section_title',
            dataCondition: [{ 'template': 'work_order_template' }],
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'switch', 
                    name: 'work_order_show_hours_section', 
                    label: 'layout.general.show_hours_section_label',
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_hours_travel_time', 
                    label: 'layout.general.show_hours_travel_time_label',
                    tooltipText: 'layout.general.show_hours_travel_time_tooltipText',
                    dataCondition: [{'work_order_show_hours_section': true}],
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_hours_assignee', 
                    label: 'layout.general.show_hours_assignee_label',
                    dataCondition: [{'work_order_show_hours_section': true}],
                },
                { 
                    type: 'switch', 
                    name: 'work_order_show_hours_prices', 
                    label: 'layout.general.show_hours_prices_label',
                    dataCondition: [{'work_order_show_hours_section': true}],
                },
                // Temporarily hide vat column: not sure if vat needs to be on the work order
                // { 
                //     type: 'switch', 
                //     name: 'work_order_show_hours_vat', 
                //     label: 'layout.general.show_hours_vat_label',
                //     dataCondition: [{'work_order_show_hours_section': true}],
                // },
                { 
                    type: 'switch', 
                    name: 'work_order_show_hours_at_fixed_price', 
                    label: 'layout.general.show_hours_at_fixed_price_label',
                    tooltipText: 'layout.general.show_hours_at_fixed_price_tooltipText',
                    dataCondition: [{'work_order_show_hours_section': true}],
                },
            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.signatures_section_title',
            dataCondition: [{ 'template': 'work_order_template' }],
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'switch', 
                    name: 'work_order_show_signature_section', 
                    label: 'layout.general.show_signatures_section_label',
                },
            ],
        },
        // {
        //     component: 'FieldsCard',
        //     title: 'layout.general.photo_page_section_title',
        //     dataCondition: { 'template': 'work_order_template' },
        //     alignment: 'horizontal',
        //     fields: [
        //         { 
        //             type: 'switch', 
        //             name: 'work_order_show_photo_page', 
        //             label: 'layout.general.show_photo_page_label',
        //         },
        //     ],
        // },
    ]

    // Set the order invoice template specific field cards
    const orderInvoiceTemplateFieldCards: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'layout.general.title_settings_title',
            dataCondition: [
                { 'template': 'order_invoice_template' },
                { 'connected_feature': 'job' }
            ],
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'dropdown', 
                    name: 'order_invoice_title_type', 
                    label: 'layout.general.document_title_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'order', name: 'layout.document_title.order'},
                        { value: 'order_confirmation', name: 'layout.document_title.order_confirmation'},
                    ],
                },
            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.details_title',
            dataCondition: [{ 'template': 'order_invoice_template' }],
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'switch', 
                    name: 'order_invoice_show_internal_reference', 
                    label: 'layout.general.show_internal_reference_label',
                },
                { 
                    type: 'switch', 
                    name: 'order_invoice_show_external_reference', 
                    label: 'layout.general.show_external_reference_label',
                },
                { 
                    type: 'switch', 
                    name: 'order_invoice_show_workflow', 
                    label: 'layout.general.show_workflow_label',
                    dataCondition: [{ 'connected_feature': 'job' }],
                },
            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.introduction_text_title',
            dataCondition: [
                // Only show introduction text setting in job pdf layouts
                { 'template': 'order_invoice_template' },
                { field: 'connected_feature', values: ['job'] },
            ],
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'switch', 
                    name: 'order_invoice_show_introduction_text', 
                    label: 'layout.general.show_introduction_text_label',
                },
                { 
                    type: 'textarea', 
                    name: 'order_invoice_introduction_text', 
                    label: 'layout.general.edit_introduction_text_label', 
                    placeholder: 'layout.general.edit_introduction_text_placeholder',
                    dataCondition: [{ 'show_introduction_text': true }],
                },
                
            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.item_lines_title',
            dataCondition: [{ 'template': 'order_invoice_template' }],
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'dropdown', 
                    name: 'order_invoice_price_display', 
                    label: 'layout.general.price_display_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: 'show_item_prices', name: 'layout.price_display.show_item_prices'},
                        { value: 'show_only_total_price', name: 'layout.price_display.show_only_total_price'},
                    ],
                },
                { 
                    type: 'dropdown', 
                    name: 'order_invoice_items_header_font_size', 
                    label: 'layout.general.items_header_font_size_label', 
                    disabled_selected: 'forms.select_type_disabled_selected',
                    allowNoneOption: false,
                    showSearch: false,
                    hardcodedOptions: [
                        { value: '9pt', name: '9pt'},
                        { value: '10pt', name: '10pt'},
                        { value: '11pt', name: '11pt'},
                        { value: '12pt', name: '12pt'},
                        { value: '14pt', name: '14pt'},
                        { value: '16pt', name: '16pt'},
                        { value: '18pt', name: '18pt'},
                        { value: '20pt', name: '20pt'},
                    ],
                },
                { 
                    type: 'switch', 
                    name: 'order_invoice_show_items_amount_column', 
                    label: 'layout.general.show_items_amount_column_label',
                },
                { 
                    type: 'switch', 
                    name: 'order_invoice_show_items_unit_price_column', 
                    label: 'layout.general.show_items_unit_price_column_label',
                },
                { 
                    type: 'switch', 
                    name: 'order_invoice_show_items_total_price_column', 
                    label: 'layout.general.show_items_total_price_column',
                },
                { 
                    type: 'switch', 
                    name: 'order_invoice_show_items_vat_column', 
                    label: 'layout.general.show_items_vat_column',
                },
                { 
                    type: 'text', 
                    name: 'order_invoice_items_amount_column_name', 
                    label: 'layout.general.items_amount_column_name_label', 
                    placeholder: 'layout.general.items_amount_column_name_placeholder',
                    dataCondition: [{ 'show_items_amount_column': true }],
                },
                { 
                    type: 'text', 
                    name: 'order_invoice_items_description_column_name', 
                    label: 'layout.general.items_description_column_name_label', 
                    placeholder: 'layout.general.items_description_column_name_placeholder',
                },
                { 
                    type: 'text', 
                    name: 'order_invoice_items_unit_price_column_name', 
                    label: 'layout.general.items_unit_price_column_name_label', 
                    placeholder: 'layout.general.items_unit_price_column_name_placeholder',
                    dataCondition: [{ 'show_items_unit_price_column': true }],
                },
                { 
                    type: 'text', 
                    name: 'order_invoice_items_total_price_column_name', 
                    label: 'layout.general.items_total_price_column_name_label', 
                    placeholder: 'layout.general.items_total_price_column_name_placeholder',
                    dataCondition: [{ 'show_items_total_price_column': true }],
                },
                { 
                    type: 'text', 
                    name: 'order_invoice_items_vat_column_name', 
                    label: 'layout.general.items_vat_column_name_label', 
                    placeholder: 'layout.general.items_vat_column_name_placeholder',
                    dataCondition: [{ 'show_items_vat_column': true }],
                },
            ],
        },
        {
            component: 'FieldsCard',
            title: 'layout.general.terms_and_conditions_title',
            dataCondition: [
                { 'template': 'order_invoice_template' },
                { field: 'connected_feature', notValues: ['invoice', 'proposal', 'purchase'] },
            ],
            alignment: 'horizontal',
            fields: [
                { 
                    type: 'switch', 
                    name: 'order_invoice_show_terms_and_conditions', 
                    label: 'layout.general.show_terms_and_conditions_label',
                },
                { 
                    type: 'textarea', 
                    name: 'order_invoice_terms_and_conditions', 
                    label: 'layout.general.edit_terms_and_conditions_label', 
                    placeholder: 'layout.general.edit_terms_and_conditions_placeholder',
                    dataCondition: [{ 'show_terms_and_conditions': true }],
                },
            ],
        },
    ]

    const detailCards = [...defaultFieldCards, ...workOrderTemplateFieldCards, ...orderInvoiceTemplateFieldCards];

    return (
        <DetailPage
            apiObject={apiObject}
            pageObject={pageObject}
            headerTitle={headerTitle}
            headerButtons={headerButtons}
            statusBar={statusBar}
            detailCards={detailCards}
            tabs={PdfLayoutExample}
        />
    );
};

export default PdfLayoutPage;
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';
import { renderPriceDecimalColumn } from 'components/lists/functions/renderListColumn';
import '../../style/scss/modals.scss';

export interface DetailModalSummaryProps {
    objectType: 'proposal' | 'job' | 'project' | 'invoice';
    data: any;
}

const DetailModalSummary: React.FC<DetailModalSummaryProps> = ({ objectType, data }) => {
    const { t } = useTranslation();
    const { userLocale } = useSettings();

    const renderSummary = useMemo(() => {
        if (data) {
            switch (objectType) {
                case 'invoice': {
                    const status = data['status'];
                    const amount = data['amount'];
                    const currency = data['currency']
    
                    const amountCurrency = renderPriceDecimalColumn(amount, currency, userLocale);
    
                    return (
                        <div className='summary-header'>
                            {status !== 'draft' && (
                                <>
                                    <div className='badge-container'>
                                        <div className={`status-badge ${status}`}>
                                            {t(`workflow.default_status_names.${status}`)}
                                        </div>                        
                                    </div>
                                    <div className='summary-details'>
                                        <p>Te ontvangen: {amountCurrency}</p>
                                    </div>
                                </>
                            )}
                        </div>
                    )
                }
                case 'proposal': {
                    const status = data['status'];
        
                    return (
                        <div className='summary-header'>
                            {status !== 'draft' && (
                                <>
                                    <div className='badge-container'>
                                        <div className={`status-badge ${status}`}>
                                            {t(`workflow.default_status_names.${status}`)}
                                        </div>                        
                                    </div>
                                    <div className='summary-details'>
                                        
                                    </div>
                                </>
                            )}
                        </div>
                    )
                }
                
                default:
                    return null;
            }
        }
    }, [data]) 

    return (
        <div className='summary'>
            <div className='wrapper'>
                {renderSummary}
            </div>
        </div>
    );
};

export default DetailModalSummary;
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/ModalContext';
import { useFetchData } from 'services/api/useFetchData';
import { ImportDataType } from 'components/import/ImportTypes';
import '../../style/scss/modals.scss';

const ImportDetailModal: React.FC<{ itemId?: number, viewKey?: string }> = ({ itemId, viewKey }) => {
    const { t } = useTranslation();
    const { revealModal } = useModal();
    const { response } = useFetchData({ apiUrl: `get_import/${itemId}` });    
    const [importData, setImportData] = useState<ImportDataType>();
    
    // Set the fetched response
    useEffect(() => {
        if (response) setImportData(response);
    }, [response]);

    // Reveal the modal after getting the data
    useEffect(() => {
        if (itemId !== null && importData) revealModal();
    }, [itemId, importData]);

    // Destructure the release data if it is set
    const { import_tag_name, processed_rows, status, failed_rows } = importData || {};

    return (
        <div className='import-details-modal'>
            <div className='header'>
                <div>
                    <h2>{import_tag_name}</h2>
                </div>
                <div className='right-header'>
                    <div className='details'>
                        <h5>{status}</h5>
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className='imported-rows'>
                    <h5>{t('Imported rows')}</h5>
                    <span>
                        {processed_rows}
                    </span>
                </div>

                {failed_rows && failed_rows.length > 0 ? (
                    <div className='failed-rows'>
                        <h5>{t('Failed rows')}</h5>
                        <div>
                            {failed_rows.map((row, index) => (
                                <div key={index}>
                                    {t('Row')} {row.row_number}: {row.error}
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className='failed-rows'>
                        <h5>{t('Failed rows')}</h5>
                        <p>{t('No failed rows data')}</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImportDetailModal;
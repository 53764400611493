import React, { useState, useContext, useEffect } from 'react';
import { FieldData, TimeRowsFieldsetType } from 'types/FieldTypes';
import { useTranslation } from 'react-i18next';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { formatDecimalToHoursAndMinutes } from 'services/utils/convertDecimalToHoursAndMinutes';
import { convertHoursAndMinutesToDecimal } from 'services/utils/convertHoursToDecimal';
import { formatPriceCurrencyString, formatPriceString } from 'services/utils/amountFormatting';
import { convertRowsToLocalDateTimes, sortRowsByDate } from 'services/utils/sortDates';
import { combineDateAndTimeInputsToDatetimeString, convertTimeStringToDate } from 'services/utils/dateTimeUtils';
import { convertLocalDateTimeToUTC, extractDateFromLocalDateTime, extractTimeFromLocalDateTime } from 'internationalization/timezoneConversions';
import { renderRowsFieldErrorMessages } from './functions/renderRowsFieldErrorMessages';
import FormFieldContext from '../FormFieldContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { TimeRowType, TimeTypeType, createNewTimeRow } from '../../../views/timesheets/TimesheetTypes';
import { HourlyRateType } from '../../../views/products/ProductTypes';
import { UserType } from '../../../views/users/UserTypes';
import { v4 as uuidv4 } from 'uuid';
import DateInput from '../basefields/DateInput';
import Dropdown from '../basefields/Dropdown';
import TimeInput from '../basefields/TimeInput';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';

const TimeRowsFieldset: React.FC<TimeRowsFieldsetType & { data: FieldData }> = ({
    name, jobId, data, dropdownData, helperText, isEditable
}) => {
    const { t } = useTranslation();
    const { editing, setUpdatedData, showErrorAlert } = useContext(FormFieldContext);
    const { errorMessages } = useGlobalContext();
    const { userLocale, userTimezone, getTimeFormat, defaultCurrency, defaultCurrencySymbol, environmentSettings } = useSettings();
    const hasRightCheck = useAllowedRight;
    const [timeRows, setTimeRows] = useState<TimeRowType[]>([]);
    const [timeTypes, setTimeTypes] = useState<TimeTypeType[]>([]);
    const [assignees, setAssignees] = useState<UserType[]>([]);
    const [hourlyRates, setHourlyRates] = useState<HourlyRateType[]>([]);
    const [durationOnly, setDurationOnly] = useState<boolean>(false);
    const [showEndDateField, setShowEndDateField] = useState<boolean>(false);
    const [showPriceFields, setShowPriceFields] = useState<boolean>(true);
    const is12HourFormat = getTimeFormat() === '12h';
    const [userHasOnlyViewRights] = useState<boolean>(hasRightCheck('only_view'));  

    // If there are timerows included in the fetched data, show them. Otherwise show an empty row
    useEffect(() => {
        // Get the current time registration mode from the environment settings
        if (environmentSettings.job_time_registration_mode) {
            const isDurationMode = environmentSettings.job_time_registration_mode === 'duration'; 

            if (data && data[name] && data[name].length === 0) {
                // If the response is empty, create an empty row
                const newTimeRow = createNewTimeRow({ jobId: jobId, uuid: uuidv4(), registration_mode: isDurationMode ? 'duration' : 'start-end' });
                setTimeRows([newTimeRow]);
            } else {
                // Convert the backend utc datetimes to local datetimes
                const { rowsWithLocalDatetimes } = convertRowsToLocalDateTimes<TimeRowType>(data[name], 'start_datetime', 'end_datetime', userLocale, userTimezone)

                // Initiate variable to show end date field
                let shouldShowEndDateField = false;

                // Convert start- and end-datetimes into separated date and time fields
                const processedRows = rowsWithLocalDatetimes.map((row: TimeRowType) => {
                    let startDate, startTime, endDate, endTime, utcStartDatetime, utcEndDatetime;

                    if (row.start_datetime) {
                        startDate = extractDateFromLocalDateTime(row.start_datetime) || null;
                        startTime = extractTimeFromLocalDateTime(row.start_datetime, userLocale, is12HourFormat) || null;
                        utcStartDatetime = convertLocalDateTimeToUTC(row.start_datetime, userLocale, userTimezone) || null;
                    }

                    if (row.end_datetime) {
                        endDate = extractDateFromLocalDateTime(row.end_datetime) || null;
                        endTime = extractTimeFromLocalDateTime(row.end_datetime, userLocale, is12HourFormat) || null;
                        utcEndDatetime = convertLocalDateTimeToUTC(row.end_datetime, userLocale, userTimezone) || null;
                    }

                    // Check if the end_date differs from the start date of non-deleted timerows
                    if (startDate && endDate && startDate !== endDate && !row.deleted) {
                        shouldShowEndDateField = true;
                    }

                    // Update the row with the dates and times
                    return {
                        ...row,
                        start_datetime: utcStartDatetime || null,
                        end_datetime: utcEndDatetime || null,
                        start_date: startDate || null,
                        start_time: startTime || null,
                        end_date: endDate || null,
                        end_time: endTime || null,
                        registration_mode: isDurationMode ? 'duration' : 'start-end',
                    };
                });

                // Set visibility of end date field
                setShowEndDateField(shouldShowEndDateField);

                // Determine the date field to sort the timerows on
                const dateField = durationOnly ? 'date' : 'start_date';

                // Sort the time rows on date
                const { sortedRows } = sortRowsByDate(processedRows, dateField);

                // Format the duration and total price
                sortedRows.map((row: TimeRowType) => {

                    // If a duration exists, convert it to a time field
                    if (row.duration !== null) {

                        // Format the duration decimal value into a string
                        row.duration_string = formatDecimalToHoursAndMinutes(row.duration)

                        // Calculate the total price is a hourly rate exists
                        if (row.hourly_rate !== null) {
                            
                            // Find the hourly rate based on its id
                            const hourlyRate = hourlyRates.find(rate => rate.id === row.hourly_rate);

                            // If the rate is found, calculate the total price
                            if (hourlyRate && hourlyRate.rate !== null) {
                                // Parse the rate into a number
                                const rate = parseFloat(hourlyRate.rate);
                
                                // If the duration is a number, calculate the price. Otherwise fallback to 0
                                if (!isNaN(row.duration)) {
                                    row.total_price = row.duration * rate;
                                } else {
                                    row.total_price = 0;
                                }
                            } else {
                                // If no hourly rate is set, fall back to zero
                                row.total_price = 0;
                            }
                        }
                    } else {
                        // If no duration exists, set the duration string to an empty string
                        row.duration_string = '';
                    }

                    return { ...row };
                });
                setTimeRows(sortedRows);
            }
        }
    }, [data, jobId, hourlyRates, environmentSettings]);  
    
    // Show the price fields based on the billing type of the job
    useEffect(() => {
        if (data && data['billing_type']) {
            // Get the billing type from the job data
            const billingType = data['billing_type'];

            // Determine the visibility of the price fields
            if (billingType === 'calculation' || billingType === 'non_billing') {
                setShowPriceFields(true);
            } else if (billingType === 'fixed') {
                setShowPriceFields(false);
            }
        }
    })

    // Set the time types and assignees from the dropdown data
    useEffect(() => {
        setTimeTypes(dropdownData?.timetype?.results || []);
        setAssignees(dropdownData?.user?.results || []);
    
        // Set the hourly rates and add a currency rate value
        setHourlyRates(transformedHourlyRates);
    }, [dropdownData]);

    // Set the duration only state based on the environment setting, store the value in the fields for validation
    useEffect(() => {
        // Determine the duration mode
        const isDurationMode = environmentSettings.job_time_registration_mode === 'duration';
    
        // Update duration only state
        setDurationOnly(isDurationMode);
    
        // Update the registration mode inside every timerow
        const updatedTimeRows = timeRows.map((timeRow) => ({
            ...timeRow,
            registration_mode: isDurationMode ? 'duration' : 'start-end',
        }));

        setTimeRows(updatedTimeRows);
    }, [environmentSettings]);

    // Add currency rate description to the hourly rates, to show currency in the hourly rate dropdown
    const transformedHourlyRates = (dropdownData?.hourlyrate?.results || []).map((rateObj: HourlyRateType) => ({
        ...rateObj,
        currency_rate: Number(rateObj.rate).toLocaleString(userLocale, {
            style: 'currency',
            currency: rateObj.rate_currency?.toUpperCase(),
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }),
    }));

    // Handle the date change for duration timerows
    const handleDurationDateChange = (selectedDate: string | null, identifier: string) => {
        const updatedTimeRows = [...timeRows];
        const itemIndex = updatedTimeRows.findIndex(item => item.id?.toString() === identifier || item.uuid === identifier);

        if (itemIndex !== -1) {
            // Set the selected value as 
            updatedTimeRows[itemIndex].date = selectedDate;

            // Update the time rows and updated data
            setTimeRows(updatedTimeRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedTimeRows }))
        }
    }

    // Handle the duration change
    const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>, identifier: string) => {
        // Copy the time rows and find the row index
        const updatedRows = [...timeRows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Get the new duration string value
            const newDurationString = event.target.value

            // Set the string value in duration string
            updatedRows[rowIndex].duration_string = newDurationString;

            // Convert the duration string into a decimal to save to the backend
            const convertedDurationDecimal = convertHoursAndMinutesToDecimal(newDurationString);

            // Set the new duration decimal in the duration field for the backend
            updatedRows[rowIndex].duration = convertedDurationDecimal;

            // Calculate and change the total price, if a hourly rate is set
            const hourlyRate = hourlyRates.find(rate => rate.id === updatedRows[rowIndex].hourly_rate);
            if (hourlyRate && hourlyRate.rate !== null) {
                // Parse the rate into a number
                const rate = parseFloat(hourlyRate.rate);

                // If the converted duration decimal is a number, calculate the price. Otherwise fallback to 0
                if (!isNaN(convertedDurationDecimal)) {
                    updatedRows[rowIndex].total_price = convertedDurationDecimal * rate;
                } else {
                    updatedRows[rowIndex].total_price = 0;
                }
            } else {
                // If no hourly rate is set, fall back to zero
                updatedRows[rowIndex].total_price = 0;
            }
                
            // Update the time rows and updated data
            setTimeRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    }

    // Handle the date change for start- and endtime time rows
    const handleDateChange = (selectedDate: string | null, fieldName: 'start_date' | 'end_date', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...timeRows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Get the row data
            const row = updatedRows[rowIndex];

            // Combine the selected date and time values into datetime fields
            let combinedDatetime, utcDatetime;
            if (selectedDate !== null) {

                // Handle datetime conversion when start date field is changed
                if (fieldName === 'start_date') {

                    // If a start time exists, combine the start datetime
                    if (row.start_time) {
                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(selectedDate, row.start_time);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the start datetime
                        updatedRows[rowIndex].start_datetime = utcDatetime;
                    }
                    
                    // If the start date and end date are equal, and end time exists, combine the start datetime
                    if ((row.end_date === null || (row.start_date === row.end_date)) && row.end_time) {

                        // Set the end date to the selected date
                        updatedRows[rowIndex].end_date = selectedDate;

                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(selectedDate, row.end_time);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the end datetime
                        updatedRows[rowIndex].end_datetime = utcDatetime;
                    }
                }

                // Handle datetime conversion when end date field is changed
                if (fieldName === 'end_date' && row.end_time) {
                    // Combine the date input and time input into a local datetime string
                    combinedDatetime = combineDateAndTimeInputsToDatetimeString(selectedDate, row.end_time);

                    // Convert the local datetime into an utc datetime string for the backend
                    utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                    // Update the end datetime
                    updatedRows[rowIndex].end_datetime = utcDatetime;
                }
            } else {
                // Handle in case the start date is removed
                if (fieldName === 'start_date') {
                    // If the start date and end date are equal, remove both start- and end-datetimes
                    if (row.start_date && row.end_date && (row.start_date === row.end_date)) {
                        updatedRows[rowIndex].start_datetime = null;
                        updatedRows[rowIndex].end_datetime = null;

                    // Otherwise only remove the start-datetime
                    } else {
                        updatedRows[rowIndex].start_datetime = null;
                    }
                }

                // Handle in case the end date is removed
                if (fieldName === 'end_date') {
                    // If the start date still exists and end time, set the end date to the start date
                    if (row.start_date && row.end_time) {
                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(row.start_date, row.end_time);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the end datetime
                        updatedRows[rowIndex].end_datetime = utcDatetime;

                    // Otherwise remove the end-datetime
                    } else {
                        updatedRows[rowIndex].end_datetime = null;
                    }
                }
            }

            // Set the selected value as 
            updatedRows[rowIndex][fieldName] = selectedDate;

            // Update the time rows and updated data
            setTimeRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    }

    // Handle time change
    const handleTimeChange = (selectedTime: string | null, fieldName: 'start_time' | 'end_time', identifier: string) => {
        // Copy the rows and find the item index
        const updatedRows = [...timeRows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Set the selected value as 
            updatedRows[rowIndex][fieldName] = selectedTime;

            // Get the row data
            const row = updatedRows[rowIndex];

            // Combine the time value to a datetime string if the start_date value exists
            let combinedDatetime, utcDatetime;
            if (selectedTime !== null && selectedTime !== '') {

                // Check if the start date exists
                if (row.start_date !== null) {

                    // Handle if the end time changed and the end date differs from the start date
                    if (row.end_date !== null && (row.start_date !== row.end_date) && fieldName === 'end_time') {

                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(row.end_date, selectedTime);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the end datetime
                        updatedRows[rowIndex].end_datetime = utcDatetime;
                    }

                    // Handle if both the end- or start-time changed, but the start- and end-dates are equal
                    if ((row.end_date !== null && (row.start_date === row.end_date)) || row.end_date === null) {

                        // Combine the date input and time input into a local datetime string
                        combinedDatetime = combineDateAndTimeInputsToDatetimeString(row.start_date, selectedTime);

                        // Convert the local datetime into an utc datetime string for the backend
                        utcDatetime = convertLocalDateTimeToUTC(combinedDatetime, userLocale, userTimezone);

                        // Update the start datetime
                        if (fieldName === 'start_time') {
                            updatedRows[rowIndex].start_datetime = utcDatetime;
                        }
            
                        // Update the end datetime
                        if (fieldName === 'end_time') {
                            updatedRows[rowIndex].end_datetime = utcDatetime;
                        }    
                    }
                }
            
            } else {
                // If the selected time is set to null, remove the start- or end-datetimes
                if (fieldName === 'start_time') {
                    updatedRows[rowIndex].start_datetime = null;
                }
    
                // Update the end datetime
                if (fieldName === 'end_time') {
                    updatedRows[rowIndex].end_datetime = null;
                }
            }

            // Update the duration after changing the time
            if (row.start_time && row.end_time) {

                // Calculate the duration if start- and end-datetime exists
                if (row.start_datetime && row.end_datetime) {

                    // Convert date time strings to date objects
                    const startDate = new Date(row.start_datetime);
                    const endDate = new Date(row.end_datetime);

                    // Check if both date objects are valid
                    if (startDate && endDate && !isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {
                        // Calculate the difference between end- and start time in milliseconds
                        const millisecondsDifference = endDate.getTime() - startDate.getTime();

                        // Convert milliseconds to hours
                        const newDurationHours = millisecondsDifference / (1000 * 60 * 60);

                        // Convert the duration to hours with minutes
                        const durationString = formatDecimalToHoursAndMinutes(newDurationHours);

                        // Update the duration string
                        updatedRows[rowIndex].duration_string = durationString;
                    }
                
                // Calculate the duration only on start- and end-time fields
                } else {
                    // Convert start- and end-time strings to date objects
                    const startTime = convertTimeStringToDate(row.start_time);
                    const endTime = convertTimeStringToDate(row.end_time);

                    if (startTime && endTime && !isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
                        // Calculate the difference between end- and start time in milliseconds
                        const millisecondsDifference = endTime.getTime() - startTime.getTime();

                        // Convert milliseconds to hours
                        const newDurationHours = millisecondsDifference / (1000 * 60 * 60);

                        // Convert the duration to hours with minutes
                        const durationString = formatDecimalToHoursAndMinutes(newDurationHours);

                        // Update the duration string
                        updatedRows[rowIndex].duration_string = durationString;
                    }
                }
            } else {
                // Fallback to 0 if one of the times is missing
                const fallback = formatDecimalToHoursAndMinutes(0);

                // Update the duration string with the fallback
                updatedRows[rowIndex].duration_string = fallback;
            }
            
            // Update the time rows and updated data
            setTimeRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    }

    // Handle hourly rate change
    const handleHourlyRateChange = (selectedValue: string, identifier: string) => {
        // Copy the time rows and find the item index
        const updatedRows = [...timeRows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Get the new selected value and parse it to a float
            const selectedValueFloat = parseFloat(selectedValue);

            // Set the selected value as 
            updatedRows[rowIndex].hourly_rate = selectedValueFloat;

            // Get the duration of the current row, and calculate the total price if it exists
            const duration = updatedRows[rowIndex].duration;
            if (duration !== null) {

                // Get the currently selected hourly rate
                const hourlyRate = hourlyRates.find(rate => rate.id === selectedValueFloat);
                if (hourlyRate && hourlyRate.rate !== null) {
                    // Parse the rate into a number
                    const rate = parseFloat(hourlyRate.rate);

                    // If the rate is a number, calculate the price. Otherwise fallback to 0
                    if (!isNaN(rate)) {
                        updatedRows[rowIndex].total_price = duration * rate;
                    } else {
                        updatedRows[rowIndex].total_price = 0;
                    }
                } else {
                    // If no hourly rate is set, or the hourly rate is changed to none, fall back to zero
                    updatedRows[rowIndex].total_price = 0;
                }
            }

            // Update the time rows and updated data
            setTimeRows(updatedRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedRows }))
        }
    };

    // Handle the dropdown change
    const handleDropdownChange = (selectedValue: string, fieldName: 'time_type' | 'assignee', identifier: string) => {
        // Copy the time rows and find the item index
        const updatedTimeRows = [...timeRows];
        const itemIndex = updatedTimeRows.findIndex(item => item.id?.toString() === identifier || item.uuid === identifier);

        if (itemIndex !== -1) {
            // Set the selected value as 
            updatedTimeRows[itemIndex][fieldName] = parseFloat(selectedValue)

            // Update the time rows and updated data
            setTimeRows(updatedTimeRows);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedTimeRows }))
        }
    };
    
    // Handle the creation of a new row
    const handleAddRow = () => {
        // Create a new time row
        const newTimeRow = createNewTimeRow({ jobId: jobId, uuid: uuidv4(), registration_mode: durationOnly ? 'duration' : 'start-end' });
    
        // Add the row to the list of timerows
        const updatedTimeRows = [...timeRows, newTimeRow];
    
        // Update the time rows and updated data with sale price as float
        setTimeRows(updatedTimeRows);
        setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: updatedTimeRows }))
    };

    // Handle deletion of a row
    const handleDeleteRow = (identifier: string) => {
        let updatedRows = [...timeRows];
        const rowIndex = updatedRows.findIndex(row => row.id?.toString() === identifier || row.uuid === identifier);

        if (rowIndex !== -1) {
            // Get the row from the updated rows
            const row = updatedRows[rowIndex]

            // Check if the row is a new row added in the frontend (it has an uuid but no id)
            if (!row.id && row.uuid) {
                // Directly remove it from the updated rows
                updatedRows = updatedRows.filter((_, index) => index !== rowIndex)
        
            } else {
                // Flag the backend known row as deleted without actually delete it
                updatedRows[rowIndex].deleted = true;
            }
    
            // Update the ordering of the items which are not marked as deleted
            const nonDeletedItems = updatedRows.filter(row => !row.deleted);
            const reorderedNonDeletedItems = nonDeletedItems.map((row, index) => ({
                ...row,
                position: index + 1,
            }));

            // Add the deleted items to the reordered list to keep them for the updated data
            const finalItems = [...reorderedNonDeletedItems, ...updatedRows.filter(row => row.deleted)];
    
            setTimeRows(finalItems);
            setUpdatedData((currentUpdatedData: any) => ({ ...currentUpdatedData, [name]: finalItems }))
        } 
    }

    // Format the total price value
    const formatTotalPrice = (timeRow: TimeRowType, showFallback?: boolean): string => {
        if (timeRow.total_price) {
            // Find the hourly rate of the timerow to determine the currency
            const hourlyRate = hourlyRates.find(rate => rate.id === timeRow.hourly_rate);

            let currency;
            if (hourlyRate && hourlyRate.rate_currency !== null) {
                // If an hourly rate is found, use the currency of the hourly rate
                currency = hourlyRate.rate_currency.toUpperCase();
            } else {
                // Otherwise fallback to the default currency of the environment
                currency = defaultCurrency?.toUpperCase() || 'EUR';
            }

            // Format the total price
            const formattedTotalPrice = formatPriceCurrencyString(timeRow.total_price.toString(), userLocale, currency);

            return formattedTotalPrice;

        } else if (showFallback) {
            // Show zero as a fallback
            const formattedZero = formatPriceString("0", userLocale);

            return `${defaultCurrencySymbol} ${formattedZero}`
        } else {
            return '';
        }
    }

    // Handle the showing of the end date field manually if needed
    const handleEndDateSuggestionClick = () => {
        setShowEndDateField(true);
    };

    return (
        <div className='rows-field'>
            {showErrorAlert &&
                <div className="alert form-alert alert-danger" role="alert">
                    {t(errorMessages.general, { defaultValue: errorMessages.general })}
                </div>
            }
            {editing ? (
                // Edit mode
                <div className='edit-mode'>
                    {timeRows.length > 0 && (
                        <div className={`rows-header timerows-row ${durationOnly ? 'duration-only' : ''} ${showPriceFields ? 'show-price-fields' : ''} ${showEndDateField ? 'show-end-date-field' : ''}`}>
                            <div className='header-item'>{t('job.timerows.time_type_label')}</div>
                            {durationOnly ? (
                                <>
                                    <div className='header-item'>{t('job.timerows.date_label')}</div>
                                    <div className='header-item'>{t('job.timerows.hours_label')}</div>
                                </>
                            ) : (
                                <>
                                    <div className='header-item'>{t('job.timerows.date_label')}</div>
                                    <div className='header-item'>{t('job.timerows.start_time_label')}</div>
                                    <div className='header-item'>{t('job.timerows.end_time_label')}</div>
                                    {showEndDateField && (
                                        <div className='header-item'>{t('job.timerows.end_date_label')}</div>
                                    )} 
                                    <div className='header-item'>{t('job.timerows.hours_label')}</div>
                                </>
                            )}
                            <div className='header-item'>{t('job.timerows.assignee_label')}</div>
                            {showPriceFields && 
                                <>
                                    <div className='header-item'>{t('job.timerows.hourly_rate_label')}</div>
                                    <div className='header-item'>{t('job.timerows.total_price_label')}</div>
                                </>
                            }
                            <div className='delete-placeholder'></div>
                        </div>
                    )}
                    {timeRows && timeRows.filter(timeRow => timeRow.deleted === false).map((timeRow, index) => {
                        const identifier = timeRow.id?.toString() || timeRow.uuid;
                        
                        return (
                            <div key={identifier} className='list-item'>
                                <div className={`timerows-row ${durationOnly ? 'duration-only' : ''} ${showPriceFields ? 'show-price-fields' : ''} ${showEndDateField ? 'show-end-date-field' : ''}`}>
                                    <Dropdown<TimeTypeType>
                                        options={timeTypes}
                                        id={`time_type_${identifier}`}
                                        name={`time_type_${identifier}`}
                                        disabled_selected={t('job.timerows.time_type_placeholder')}
                                        selectedOption={timeTypes.find(timeType => timeType.id === timeRow.time_type)}
                                        onChange={(selectedValue) => handleDropdownChange(selectedValue, 'time_type', timeRow.id?.toString() || timeRow.uuid || '')}
                                        isInvalid={identifier && errorMessages?.timerows?.[identifier]?.['time_type'] ? true : false}
                                        selectionFormat={(option) => `${option.name}`}
                                        optionFormat={(option) => `${option.name}`}
                                        allowNoneOption={false} 
                                    />
                                    {durationOnly ? (
                                        // When registrating duration only, the 'date' field is updated. No datetime is needed.
                                        <>
                                            <DateInput
                                                id={`date_${identifier}`}
                                                name={`date_${identifier}`}
                                                value={timeRow.date ? new Date(timeRow.date) : undefined}
                                                onChange={(selectedValue) => handleDurationDateChange(selectedValue, timeRow.id?.toString() || timeRow.uuid || '')}
                                                isInvalid={identifier && errorMessages?.timerows?.[identifier]?.['date'] ? true : false}
                                                placeholder='job.timerows.date_placeholder'
                                            />
                                            <input 
                                                type="text"
                                                id={`duration_${identifier}`}
                                                name={`duration_${identifier}`}
                                                value={timeRow.duration_string ?? ''}
                                                onChange={event => handleDurationChange(event, identifier || '')}
                                                placeholder={t('job.timerows.hours_placeholder')}
                                                className={identifier && errorMessages?.timerows?.[identifier]?.['duration'] ? 'is-invalid' : undefined}
                                                autoFocus={false}
                                            />
                                        </>
                                    ) : (
                                        // When registrating start and end times, the 'start_date' field is updated.
                                        <>
                                            <DateInput
                                                id={`start_date_${identifier}`}
                                                name={`start_date_${identifier}`}
                                                value={timeRow.start_date ? new Date(timeRow.start_date) : undefined}
                                                onChange={(selectedValue) => handleDateChange(selectedValue, 'start_date', timeRow.id?.toString() || timeRow.uuid || '')}
                                                isInvalid={identifier && errorMessages?.timerows?.[identifier]?.['start_date'] ? true : false}
                                                placeholder='job.timerows.date_placeholder' 
                                            />
                                            <TimeInput
                                                type='time'
                                                id={`start_time_${timeRow.id || timeRow.uuid}`}
                                                name={`start_time_${timeRow.id || timeRow.uuid}`}
                                                value={timeRow.start_time ?? undefined}
                                                onChange={(selectedTime) => handleTimeChange(selectedTime, 'start_time', timeRow.id?.toString() || timeRow.uuid || '')}
                                                isInvalid={identifier && errorMessages?.timerows?.[identifier]?.['start_time'] ? true : false}
                                            />
                                            <TimeInput
                                                type='time'
                                                id={`end_time_${timeRow.id || timeRow.uuid}`}
                                                name={`end_time_${timeRow.id || timeRow.uuid}`}
                                                value={timeRow.end_time ?? undefined}
                                                onChange={(selectedTime) => handleTimeChange(selectedTime, 'end_time', timeRow.id?.toString() || timeRow.uuid || '')}
                                                isInvalid={identifier && errorMessages?.timerows?.[identifier]?.['end_time'] ? true : false}
                                            />
                                            {showEndDateField && (
                                                // Show in case the end date is given but differs from the start date
                                                (timeRow.end_date !== null && timeRow.end_date !== timeRow.start_date) || 
                                                // Show in case the end time is earlier than the start time, this probably means the use wants to set the timerow till tomorrow
                                                (timeRow.end_time && timeRow.start_time && timeRow.end_time < timeRow.start_time) ? (
                                                    <DateInput
                                                        id={`end_date_${identifier}`}
                                                        name={`end_date_${identifier}`}
                                                        value={timeRow.end_date ? new Date(timeRow.end_date) : undefined}
                                                        onChange={(selectedValue) => handleDateChange(selectedValue, 'end_date', timeRow.id?.toString() || timeRow.uuid || '')}
                                                        placeholder='job.timerows.date_placeholder' 
                                                    />
                                                ) : (
                                                    <div></div>
                                                )
                                            )}
                                            <div className='string-value'>
                                                {timeRow.duration_string}
                                            </div>
                                        </>
                                    )}
                                    <Dropdown<UserType>
                                        options={assignees}
                                        id={`assignee_${identifier}`}
                                        name={`assignee_${identifier}`}
                                        disabled_selected={t('job.timerows.assignee_placeholder')}
                                        selectedOption={assignees.find(assignee => assignee.id === timeRow.assignee)}
                                        onChange={(selectedValue) => handleDropdownChange(selectedValue, 'assignee', timeRow.id?.toString() || timeRow.uuid || '')}
                                        isInvalid={identifier && errorMessages?.timerows?.[identifier]?.['assignee'] ? true : false}
                                        selectionFormat={(option) => `${option.full_name}`}
                                        optionFormat={(option) => `${option.full_name}`}
                                        allowNoneOption={false}                  
                                    />
                                    {showPriceFields && 
                                        <>
                                            <Dropdown<HourlyRateType>
                                                options={hourlyRates}
                                                id={`hourly_rate_${identifier}`}
                                                name={`hourly_rate_${identifier}`}
                                                disabled_selected={t('job.timerows.hourly_rate_placeholder')}
                                                selectedOption={hourlyRates.find(rate => rate.id === timeRow.hourly_rate)}
                                                onChange={(selectedValue) => handleHourlyRateChange(selectedValue, timeRow.id?.toString() || timeRow.uuid || '')}
                                                isInvalid={identifier && errorMessages?.timerows?.[identifier]?.['hourly_rate'] ? true : false}
                                                selectionFormat={(option) => `${option.currency_rate}`}
                                                optionFormat={(option) => `${option.currency_rate} - ${option.description}`}
                                                allowNoneOption={true}                            
                                            />
                                            <div className='string-value'>
                                                {formatTotalPrice(timeRow, true)}
                                            </div>
                                        </>
                                    }
                                    <div className='delete-icon tooltip-icon'>
                                        <FontAwesomeIcon 
                                            icon={faTrash} 
                                            onClick={() => handleDeleteRow(timeRow.id?.toString() || timeRow.uuid || '')} />
                                        <span className="tooltip">{t('general.delete')}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {errorMessages && 
                        <div className='error-message'>
                            {renderRowsFieldErrorMessages(errorMessages, 'timerows', t, handleEndDateSuggestionClick)}
                        </div>
                    }
                    <div onClick={(e) => {e.preventDefault(); handleAddRow(); }} 
                        className="add-new-button">
                            {t('job.timerows.add_new_timerow')}
                    </div>    
                    {helperText && 
                        <div className="helper-text">
                            {t(helperText)}
                        </div>
                    }
                </div>
            ) : (
                // View mode
                <div className={`full-width-alignment ${isEditable?.editability && !userHasOnlyViewRights ? 'editable' : ''}`}>
                    <div className="view-mode">
                        <span className='p'>
                            <div className='item-list'>
                                {timeRows.filter(timeRow => timeRow.id).map((timeRow) => {
                                    const identifier = timeRow.id?.toString() || timeRow.uuid;

                                    // Get the assignee of the time row
                                    const assignee = assignees.find(assignee => assignee.id === timeRow.assignee);

                                    // Get the time type of the time row
                                    const timeType = timeTypes.find(timeType => timeType.id === timeRow.time_type);

                                    // Format the duration only date field if given
                                    const durationOnlyDate = timeRow.date ? new Date(timeRow.date).toLocaleDateString(userLocale, { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';

                                    // Format the start date field if given
                                    const startDate = timeRow.start_date ? new Date(timeRow.start_date).toLocaleDateString(userLocale, { year: 'numeric', month: '2-digit', day: '2-digit' }) : '';

                                    // Format the end date if it exists and differs from the start date
                                    const endDate = (timeRow.end_date && timeRow.end_date !== timeRow.start_date)
                                        ? new Date(timeRow.end_date).toLocaleDateString(userLocale, { year: 'numeric', month: '2-digit', day: '2-digit' }) 
                                        : null;
                                    
                                    return (
                                        <div key={identifier} className='item'>
                                            {timeType && 
                                                <span>{timeType.name} • </span>
                                            }
    
                                            {durationOnly ? (
                                                // If time is tracked by duration only, show the date and duration
                                                <span>{durationOnlyDate} • {timeRow.duration_string} {t('date_time.general.hour.singular')}</span>
                                            ) : (
                                                // For else, show the start- and endtime and duration
                                                <>
                                                    <span>{startDate} - {timeRow.start_time}-{timeRow.end_time} ({timeRow.duration_string})</span>
                                                    {endDate && (
                                                        <span> - {endDate}</span>
                                                    )}
                                                </> 
                                            )}

                                            {assignee && 
                                                <span> • {assignee.full_name}</span>
                                            }
                                            
                                            {showPriceFields && timeRow.total_price && 
                                                <span> • {formatTotalPrice(timeRow)}</span>
                                            }
                                        </div>
                                    )
                                })}
                            </div>
                            {isEditable?.editability && !userHasOnlyViewRights && (
                                // Only show add item label when the object is editable
                                <div className='add-item'>
                                    {t('job.timerows.add_timerow')}
                                </div>
                            )}
                        </span>
                        <span className='edit-icon'>
                            <FontAwesomeIcon icon={faPen} />
                        </span> 
                    </div>
                </div>
            )}
        </div>
    );
}

export default TimeRowsFieldset;
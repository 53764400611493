import { getNavigatorLanguageOrFallback } from "internationalization/i18n";
import { ReactNode, createContext } from "react";

/*
 * SettingsTypes.tsx
 * This file contains type definitions and interfaces of 
 * settings states and fields.
 */ 

// Add user based preferences here:
export interface UserPreferenceType {
    first_name: string | null;
    last_name: string | null;
    profile_picture: string | null;
    timezone: string;
    user_locale: string;
    language: string;
    default_currency: string | null;
    open_tabs: boolean;
}

export const createUserPreferenceObject = (): UserPreferenceType => ({
    first_name: null,
    last_name: null,
    profile_picture: null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC',
    user_locale: getNavigatorLanguageOrFallback(navigator.language, 'locale'),
    language: getNavigatorLanguageOrFallback(navigator.language, 'language'),
    default_currency: null,
    open_tabs: true,
})


// Add environment settings here:
export interface EnvironmentSettingsType {
    job_appointment_method: string | null,
    job_default_billing_type: string | null,
    job_time_registration_mode: string | null,
    scheduling_default_view: string | null,
    scheduling_direction: string | null,
    scheduling_display_mode: string | null,
    scheduling_display_start_time: string | null,
    scheduling_display_end_time: string | null,
    scheduling_time_blocks: boolean,
    scheduling_appointment_colors: string | null,
    scheduling_appointment_text: string[] | null,
    scheduling_automatic_open_job_inbox: boolean,
    scheduling_show_profile_pictures: boolean,
    invoice_default_currency: string | null,
    invoice_numbering_scheme: string | null,
    invoice_current_number: number | null,
    invoice_show_tax_blocked_acount: boolean,
    contact_default_entity_type: string | null,
    contact_numbering_scheme: string | null,
    contact_current_number: number | null,
    proposal_numbering_scheme: string | null,
    proposal_current_number: number | null,
    proposal_use_orders: boolean,
    order_numbering_scheme: string | null,
    order_current_number: number | null,
}

export const createEnvironmentSettingsObject = (): EnvironmentSettingsType => ({
    job_appointment_method: null,
    job_default_billing_type: null,
    job_time_registration_mode: null,
    scheduling_default_view: null,
    scheduling_direction: null,
    scheduling_display_mode: null,
    scheduling_display_start_time: null,
    scheduling_display_end_time: null,
    scheduling_time_blocks: false,
    scheduling_appointment_colors: null,
    scheduling_appointment_text: null,
    scheduling_automatic_open_job_inbox: true,
    scheduling_show_profile_pictures: true,
    invoice_default_currency: null,
    invoice_numbering_scheme: null,
    invoice_current_number: null,
    invoice_show_tax_blocked_acount: false,
    contact_default_entity_type: null,
    contact_numbering_scheme: null,
    contact_current_number: null,
    proposal_numbering_scheme: null,
    proposal_current_number: null,
    proposal_use_orders: false,
    order_numbering_scheme: null,
    order_current_number: null,
})


// Define the settings context type
type SettingsContextType = {
    // Keep track of the language setting of the user
    setLanguage: (language: string) => void;

    // Keep track of the locale of the user
    userLocale: string;

    // Keep track of the timezone of the user
    userTimezone: string;

    // Keep track of the default currency
    defaultCurrency: string | null;
    defaultCurrencySymbol: string | null;

    // Hook to get the time format based on the user locale
    getTimeFormat: () => '12h' | '24h',

    // Keep track of the user preferences
    userPreferences: UserPreferenceType;

    // Keep track of the environment settings
    environmentSettings: EnvironmentSettingsType;
}

// Create the settings context
export const SettingsContext = createContext<SettingsContextType>({
    setLanguage: () => {},
    userLocale: getNavigatorLanguageOrFallback(navigator.language, 'locale'),
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC',
    getTimeFormat: () => '24h',
    defaultCurrency: null,
    defaultCurrencySymbol: null,
    userPreferences: createUserPreferenceObject(),
    environmentSettings: createEnvironmentSettingsObject()
});

export interface SettingsContextProviderProps {
    children: ReactNode;
}
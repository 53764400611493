import React from 'react';
import { ImportFields } from 'components/import/ImportTypes';
import ImportWizard from 'components/import/ImportWizard';

// Rename the name of the config
const AttachmentImport: React.FC = () => {

    // Set the api object
    const apiObject = 'attachment'

    // Set the url to return to on close
    const closeUrl = 'settings/custom-fields/data-imports'

    // Define the import fields
    const importFields: ImportFields = {
        baseFields: [
            {
                attachment: [
                    { value: 'file', name: 'attachment.file', required: true },
                    { value: 'filename', name: 'attachment.filename', required: true },
                    { value: 'reference', name: 'attachment.reference' },
                    { value: 'contact', name: 'attachment.contact', required: true  },
                    { value: 'attachment_type', name: 'attachment.attachment_type' },
                ],
            },
        ]
    };

    return (
        <ImportWizard
            apiObject={apiObject}
            importFields={importFields}
            closeUrl={closeUrl}
        />
    );
};

export default AttachmentImport;
import { ReactNode, createContext } from "react";

/*
 * AuthenticationTypes.tsx
 * This file contains type definitions and interfaces of 
 * authentication states and fields.
 */ 

type AuthenticationContextType = {
    // Keep track if a current session exist
    currentSession: boolean | null;

    // Keep track wheter the context states are fully loaded
    statesLoaded: boolean;
    setStatesLoaded: (isLoaded: boolean) => void;

    // Keep track of the logged in state
    isLoggedIn: boolean | null;
    setIsLoggedIn: (loggedIn: boolean | null) => void;

    // Keep track of the user hash
    userHash: string | undefined;
    setUserHash: (userHash: string | undefined) => void;

    // Keep track of the user first and last name
    firstName: string | null;
    lastName: string | null;
    setFirstName: (firstName: string | null) => void;
    setLastName: (firstName: string | null) => void;

    // Keep track of the environment hash
    environmentHash: string;
    setEnvironmentHash: (value: string) => void;

    // Keep track of the environment package
    environmentPackage: string;
    setEnvironmentPackage: (value: string) => void;

    // Keep track of the environment type
    environmentType: 'trial' | 'beta' | 'client' | 'demo' | 'admin';
    setEnvironmentType: (value: 'trial' | 'beta' | 'client' | 'demo' | 'admin') => void;

    // Keep track of the environment status
    environmentStatus: 'active' | 'expired' | 'suspended' | 'cancelled';
    setEnvironmentStatus: (value: 'active' | 'expired' | 'suspended' | 'cancelled') => void;

    // Keep track of the environment package features
    packageFeatures: string[];
    setPackageFeatures: (value: string[]) => void;

    // Keep track of the environment active features
    activeFeatures: string[];
    setActiveFeatures: (value: string[]) => void;

    // Keep track of the access rights of the logged in user
    accessTo: string[];
    setAccessTo: (value: string[]) => void;

    // Keep track of the allowed features of the logged in user
    allowedFeatures: string[];
    setAllowedFeatures: (value: string[]) => void;

    // Keep track of the allowed rights of the logged in user
    allowedRights: string[];
    setAllowedRights: (value: string[]) => void;

    // Share the handleLogin and handleLogout function
    handleLogin: (email: string, password: string, navigator_language: string, navigator_locale: string, navigator_timezone: string | null, remember_login: boolean) => Promise<{status: string, error?: string}>;
    handleLogout: (options?: { logoutFromServer?: boolean, showSessionAlert?: boolean }) => Promise<void>;

    // Keep track of the redirect state
    redirectTo: string | null;
    setRedirectTo: (value: string | null) => void;
}

// Create the authentication context
export const AuthenticationContext = createContext<AuthenticationContextType>({
    currentSession: null,
    statesLoaded: false,
    setStatesLoaded: () => {},
    isLoggedIn: false,
    setIsLoggedIn: () => {},
    userHash: undefined,
    setUserHash: () => {},
    firstName: null,
    setFirstName: () => {},
    lastName: null,
    setLastName: () => {},
    environmentHash: '',
    setEnvironmentHash: () => {},
    environmentPackage: '',
    setEnvironmentPackage: () => {},
    environmentType: 'client',
    setEnvironmentType: () => {},
    environmentStatus: 'active',
    setEnvironmentStatus: () => {},
    packageFeatures: [],
    setPackageFeatures: () => {},
    activeFeatures: [],
    setActiveFeatures: () => {},
    accessTo: [],
    setAccessTo: () => {},
    allowedFeatures: [],
    setAllowedFeatures: () => {},
    allowedRights: [],
    setAllowedRights: () => {},
    handleLogin: async () => ({ status: 'idle' }),
    handleLogout: async () => {},
    redirectTo: null,
    setRedirectTo: () => {},
});

export interface AuthenticationContextProviderProps {
    children: ReactNode;
};
/*
 * formValidation.tsx
 * General utility function that validates form fields. This function performs 
 * the associated validations based on the type of the field and returns the error 
 * messages associated with the field.
 * 
 * In case of SingleFields it is possible to perform the validation per field. 
 * In that case, the variables you use to call the function are (fieldItem, updatedData, 
 * and optionally the is12hourFormat prop in case of a time field).
 * 
 * In case of WidgetFields it is possible to perform the validation for all fields inside
 * the widget. In that case, the variable you use to call the function are a string of the
 * field type (for example 'phonenumber-widget' and the updatedData of that field).
 */

import i18n from "internationalization/i18n";
import { convertTo24HourFormat } from "./dateTimeUtils";
import { TimeRowType } from "../../views/timesheets/TimesheetTypes";
import { AppointmentType, JobItemType } from "../../views/jobs/JobTypes";


/*
 * Reusable validation helper functions
 */

// Helper function to validate time values in 12- or 24-hour format
const validateTimeFormat = (value: string, is12HourFormat?: boolean): boolean => {

    // Accepted values for the 12-hours (AM - PM) time field
    const ampmFormat = /^([0-9]|0[0-9]|1[0-2]):[0-5][0-9] (AM|PM)$/;

    // Accepted values for the 24-hours time field
    const twentyFourHourFormat = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

    // Initiate validation result
    let validationResult: boolean;

    // Validate the entered time in the 12-hour timeformat field
    if (is12HourFormat) {
        validationResult = ampmFormat.test(value);

    // Validate the entered time in the 24-hour timeformat field
    } else {
        validationResult = twentyFourHourFormat.test(value);
    }
    
    return validationResult
};

// Helper function to validate duration strings
const isValidDurationString = (duration: string): boolean => {
    const durationRegex = /^([0-9]+(:[0-5][0-9])?|[0-9]+(\.[0-9]{1,2})?|[0-9]+(,[0-9]{1,2})?)$/;
    return durationRegex.test(duration);
};

// Helper function to validate date inputs
const isValidDate = (date: string): boolean => {
    const dateParts = date.split('-');
    if (dateParts.length !== 3 || dateParts.some(part => isNaN(Number(part)))) {
        return false;
    }
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[0], 10);
    const dateValue = new Date(year, month, day);
    return !isNaN(dateValue.getTime());
};


/*
 * Validation functions per field type
 */

const email = (value: string): string | null => {
    const emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegexp.test(value) ? null : "validation.email.valid_email_message";
};

const number = (value: string | number | undefined | null, min?: string, max?: string): string | null => {
    const safeValue = value !== undefined && value !== null ? String(value) : '';
    
    const onlyNumbersAndSeparators = /^[\d.,]+$/;
    const onlyNumbersAndOneOrNoSeparator = /^[0-9]*(.[0-9]*)?$/;

    const numberValue = safeValue.replace(/\./g, '.');

    if (safeValue === '') {
        return null;
    } else if (isNaN(Number(numberValue))) {
        return 'validation.number.invalid_input_message';
    } else if (!onlyNumbersAndOneOrNoSeparator.test(safeValue)) {
        return 'validation.number.one_comma_message';
    } else if (!onlyNumbersAndSeparators.test(safeValue)) {
        return 'validation.number.use_numbers_comma_message';
    } else {
        const floatValue = parseFloat(numberValue);
        if (min !== undefined && floatValue < parseFloat(min)) {
            return i18n.t('validation.number.value_greater_than_message', { value: min });
        } else if (max !== undefined && floatValue >= parseFloat(max)) {
            return i18n.t('validation.number.value_less_than_message', { value: max });
        } else {
            return null;
        }
    }
};

const date = (value: string, min?: string, max?: string): string | null => {
    if (!value) {
        return null;
    }

    // Check if the date input has three numeric parts
    const dateParts = value.split('-');
    if (dateParts.length !== 3 || dateParts.some(part => !/^\d+$/.test(part))) {
        return 'validation.date.valid_format_message';
    }

    // Validate the entered date in the field
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[0], 10);
    const dateValue = new Date(year, month, day);
    if (isNaN(dateValue.getTime())) {
        return 'validation.date.valid_date_message';
    }

    // Year length validation
    if (![2, 4].includes(year.toString().length)) {
        return 'validation.date.valid_date_message';
    }

    // Check if the date is before the minimum date
    if (min) {
        const minDate = new Date(min);
        if (dateValue < minDate) {
            return i18n.t('validation.date.date_after_message', { date: minDate.toLocaleDateString() });
        }
    }

    // Check if the date is after the maximum date
    if (max) {
        const maxDate = new Date(max);
        if (dateValue > maxDate) {
            return i18n.t('validation.date.date_before_message', { date: maxDate.toLocaleDateString() });
        }
    }

    return null;
};

const time = (value: string, min?: string, max?: string, is12HourFormat?: boolean): string | null => {
    if (!value) {
        return null;
    }

    // Check if the entered time format is correct
    if (is12HourFormat && validateTimeFormat(value, is12HourFormat)) {
        if (is12HourFormat) {
            return "validation.time.valid_time_12h_message";
        } else {
            return "validation.time.valid_time_24h_message";
        }
    }

    // Convert the time value to 24 hours
    const convertedValue = convertTo24HourFormat(value, is12HourFormat);

    // Check if the time is before the minimum time
    if (min) {
        const convertedMin = convertTo24HourFormat(min, is12HourFormat);
        if (convertedValue < convertedMin) {
            return i18n.t('validation.time.time_after_message', { time: min });
        }
    }

    // Check if the time is after the maximum time
    if (max) {
        const convertedMax = convertTo24HourFormat(max, is12HourFormat);
        if (convertedValue > convertedMax) {
            return i18n.t('validation.time.time_before_message', { time: max });
        }
    }
    
    return null;
};

const timerows = (timeRows: TimeRowType[], is12HourFormat: boolean): Record<string, Record<string, string>> | null => {
    // Return null if there are no timerows as input given
    if (!timeRows || !Array.isArray(timeRows)) return null;

    // Initialize an object to store the errors
    const errors: Record<string, Record<string, string>> = {};

    // Loop through the non-deleted time rows and execute the validation logic for every row
    timeRows.filter((row: TimeRowType) => !row.deleted).forEach((row: TimeRowType) => {

        // Initialize object to store the errors of this row
        const rowErrors: Record<string, string> = {};

        // Check if a timetype is selected
        if (!row.time_type) {
            rowErrors['time_type'] = "validation.timerow.time_type_required";
        }

        // Check if an assignee is selected
        if (!row.assignee) {
            rowErrors['assignee'] = "validation.timerow.assignee_required";
        }

        // Validations based on the duration registration mode
        if (row.registration_mode === 'duration') {

            // Check if a valid date is entered
            if (!row.date) {
                rowErrors['date'] = "validation.timerow.date_required";

            // Check if the entered date is valid
            } else if (row.date && !isValidDate(row.date)) {
                rowErrors['start_date'] = "validation.timerow.date_invalid";
            }

            // Check if the duration is not zero
            if (row.duration === 0) {
                rowErrors['duration'] = "validation.timerow.duration_required";
            }

            // Validate if the duration string 
            if (!isValidDurationString(row.duration_string || '')) {
                rowErrors['duration'] = "validation.timerow.invalid_duration_string";
            }

        // Validations based on the start-end registration mode
        } else if (row.registration_mode === 'start-end') {

            // Check if a start date is entered
            if (!row.start_date) {
                rowErrors['start_date'] = "validation.timerow.start_date_required";

            // Check if the entered start date is valid
            } else if (row.start_date && !isValidDate(row.start_date)) {
                rowErrors['start_date'] = "validation.timerow.start_date_invalid";
            }

            // Check if a start time is entered
            if (!row.start_time) {
                rowErrors['start_time'] = "validation.timerow.start_time_required";
            
            // Check if the entered start time is valid
            } else if (row.start_time && !validateTimeFormat(row.start_time, is12HourFormat)) {
                if (is12HourFormat) {
                    rowErrors['start_time'] = "validation.timerow.valid_time_12h_message";
                } else {
                    rowErrors['start_time'] = "validation.timerow.valid_time_24h_message";
                }
            }

            // Check if a valid end time is entered
            if (!row.end_time) {
                rowErrors['end_time'] = "validation.timerow.end_time_required";

            // Check if the entered end time if valid
            } else if (row.start_time && !validateTimeFormat(row.start_time, is12HourFormat)) {
                if (is12HourFormat) {
                    rowErrors['start_time'] = "validation.timerow.valid_time_12h_message";
                } else {
                    rowErrors['start_time'] = "validation.timerow.valid_time_24h_message";
                }
            }

            // Check if the end date is not before the start date
            if (row.start_datetime && row.end_datetime && row.end_datetime < row.start_datetime) {
                rowErrors['start_date'] = "validation.timerow.end_date_before_start_date";
            }

            // Check if the end time is not before the start time when start date and end date are equal
            if (row.start_date === row.end_date && row.start_time && row.end_time && row.start_time > row.end_time) {
                rowErrors['end_time'] = "validation.timerow.end_time_before_start_time";
            }
        }

        // Add the errors to the row errors object with the id or uuid as key
        if (Object.keys(rowErrors).length > 0) {
            const key = row.id ? row.id.toString() : row.uuid || '';
            errors[key] = rowErrors;
        }
    })

    // Return the errors object
    return Object.keys(errors).length > 0 ? errors : null;
}

const appointments = (appointments: AppointmentType[], is12HourFormat: boolean): Record<string, Record<string, string>> | null => {
    // Return null if there are no appointments as input given
    if (!appointments || !Array.isArray(appointments)) return null;

    // Initialize an object to store the errors
    const errors: Record<string, Record<string, string>> = {};

    // Loop through the non-deleted time rows and execute the validation logic for every row
    appointments.filter((row: AppointmentType) => !row.deleted).forEach((row: AppointmentType) => {

        // Initialize object to store the errors of this row
        const rowErrors: Record<string, string> = {};

        // Check if a start date is entered
        if (!row.start_date) {
            rowErrors['start_date'] = "validation.appointment.start_date_required";

        // Check if the entered start date is valid
        } else if (row.start_date && !isValidDate(row.start_date)) {
            rowErrors['start_date'] = "validation.appointment.start_date_invalid";
        }

        // Check if a start time is entered
        if (!row.start_time) {
            rowErrors['start_time'] = "validation.appointment.start_time_required";
        
        // Check if the entered start time is valid
        } else if (row.start_time && !validateTimeFormat(row.start_time, is12HourFormat)) {
            if (is12HourFormat) {
                rowErrors['start_time'] = "validation.appointment.valid_time_12h_message";
            } else {
                rowErrors['start_time'] = "validation.appointment.valid_time_24h_message";
            }
        }

        // Check if a valid end time is entered
        if (!row.end_time) {
            rowErrors['end_time'] = "validation.appointment.end_time_required";

        // Check if the entered end time if valid
        } else if (row.start_time && !validateTimeFormat(row.start_time, is12HourFormat)) {
            if (is12HourFormat) {
                rowErrors['start_time'] = "validation.appointment.valid_time_12h_message";
            } else {
                rowErrors['start_time'] = "validation.appointment.valid_time_24h_message";
            }
        }

        // Check if the end date is not before the start date
        if (row.start_datetime && row.end_datetime && row.end_datetime < row.start_datetime) {
            rowErrors['start_date'] = "validation.appointment.end_date_before_start_date";
        }

        // Check if the end time is not before the start time when start date and end date are equal
        if (row.start_date === row.end_date && row.start_time && row.end_time && row.start_time > row.end_time) {
            rowErrors['end_time'] = "validation.appointment.end_time_before_start_time";
        }

        // Add the errors to the row errors object with the id or uuid as key
        if (Object.keys(rowErrors).length > 0) {
            const key = row.id ? row.id.toString() : row.uuid || '';
            errors[key] = rowErrors;
        }
    })

    // Return the errors object
    return Object.keys(errors).length > 0 ? errors : null;
}

const items = (items: JobItemType[]): Record<string, Record<string, string>> | null => {
    // Return null if there are no items as input given
    if (!items || !Array.isArray(items)) return null;

    // Initialize an object to store the errors
    const errors: Record<string, Record<string, string>> = {};

    // Loop through the non-deleted rows and execute the validation logic for every row
    items.filter((row: JobItemType) => !row.deleted).forEach((row: JobItemType) => {

        // Initialize object to store the errors of this row
        const rowErrors: Record<string, string> = {};

        // // Check if a name is given
        if (!row.name) {
            rowErrors['name'] = "validation.job-item.name_required";
        }

        // Add the errors to the row errors object with the id or uuid as key
        if (Object.keys(rowErrors).length > 0) {
            const key = row.id ? row.id.toString() : row.uuid || '';
            errors[key] = rowErrors;
        }
    })

    // Return the errors object
    return Object.keys(errors).length > 0 ? errors : null;
}

const phonenumber = (value: string): string | null => {
    // Accepts the following digits: + ( ) -, only + at the beginning
    const generalStructureRegexp = /^\+?(\d|[-()\s])+$/;
    const digitCount = (value.match(/\d/g) || []).length;

    // If the value is empty, just return
    if (value === '') {
        return null;

    // Check if the phone number only contains the accepted digits
    } else if (!generalStructureRegexp.test(value)) {
        return "validation.phone.valid_phone_message";

    // Check if the phone number has the accepted length
    } else if (digitCount < 6 || digitCount > 15) {
        return "validation.phone.contain_message";
    }

    return null;
};


/*
 * Generic validation utils
 * TODO: needs to be cleaned / refactored
 */

// Timerow validation wrapper
const timerowValidationWrapper = (value: TimeRowType[], min?: string, max?: string, is12HourFormat?: boolean): Record<string, Record<string, string>> | null => {
    if (!value || !Array.isArray(value)) return null;
    return timerows(value, !!is12HourFormat);
};

// Appointment validation wrapper
const appointmentValidationWrapper = (value: AppointmentType[], min?: string, max?: string, is12HourFormat?: boolean): Record<string, Record<string, string>> | null => {
    if (!value || !Array.isArray(value)) return null;
    return appointments(value, !!is12HourFormat);
};

// Maps the validation functions
const validationFunctions: { [key: string]: (value: any, min?: string, max?: string, is12HourFormat?: boolean) => string | Record<string, string> | Record<string, Record<string, string>> | null } = {
    email,
    number,
    date,
    time,
    timerows: timerowValidationWrapper,
    appointments: appointmentValidationWrapper,
    items,
    phonenumber,
}

// In case of different field types, map it to the validation function 
function mapDifferentFieldTypes(fieldType: string): string {
    const fieldTypeToValidationType: { [key: string]: string } = {
        'job-items': 'items',
        'phonenumber-widget': 'phonenumber',
        'email-widget': 'email',
        'expiration-days': 'number'
    };

    return fieldTypeToValidationType[fieldType] || fieldType;
}

// Executes the form validation for the field type
export const formValidation = (
    fieldItemOrType: any | string, 
    updatedData: any,
    is12HourFormat?: boolean
): Record<string, string | Record<string, Record<string, string>>> | null => {
    let name, type, min, max;

    // If the given fieldItemOrType is a string with the type of the field (for example: 'phonenumber-widget')
    if (typeof fieldItemOrType === 'string') {
        type = fieldItemOrType;
        name = Object.keys(updatedData)[0];
    // If the given fieldItemOrType is a fieldItem
    } else {
        ({ name, type, min, max } = fieldItemOrType);
    }

    const value = updatedData[name];
    const mappedType = mapDifferentFieldTypes(type);

    const validationFunction = validationFunctions[mappedType];
    if (validationFunction) {
        const validationError = validationFunction(value, min, max, is12HourFormat);

        // If the validation error is a single error, return as string under the name key of the fielditem
        if (typeof validationError === 'string') {
            return { [name]: validationError };

        // If the validation error is an object with multiple errors, return the object under the name key of the fielditem
        } else if (typeof validationError === 'object' && validationError !== null) {
            return { [String(name)]: validationError as Record<string, Record<string, string>> };
        }
    }
    return null;
};
export interface DocumentLineType {
    id: number | null;
    uuid?: string;
    invoice: number | null;
    proposal: number | null;
    product: number | null;
    service: number | null;
    hourly_rate: number | null;
    quantity_string: string | null;
    quantity: number | null;
    unit: string | null;
    description: string;
    sale_price_string: string | null;
    sale_price: number | null;
    sale_price_currency: string | null;
    total_price: number | null;
    vat_rate: number | null;
    ledger_account: number | null;
    discount: number | null;
    position: number | null;
    deleted: boolean;
};

export const createNewDocumentLine = (
    documentId: number, documentType: 'proposal' | 'invoice', uuid: string, currency: string, position: number
): DocumentLineType => ({
    id: null,
    uuid: uuid,
    invoice: documentType === 'invoice' ? documentId : null,
    proposal: documentType === 'proposal' ? documentId : null,
    product: null,
    service: null,
    hourly_rate: null,
    quantity_string: '1 st',
    quantity: 1,
    unit: 'st',
    description: '',
    sale_price_string: null,
    sale_price: null,
    sale_price_currency: currency,
    total_price: null,
    vat_rate: null,
    ledger_account: null,
    discount: null,
    position: position,
    deleted: false,
});
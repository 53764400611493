import React, { useEffect } from 'react';
import { t } from 'i18next';
import { ColumnsMappingResults, ImportField, ImportFieldGroup, ImportFields } from './ImportTypes';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ImportMappingProps {
    columnsMapping: ColumnsMappingResults[];
    previewRows: string[][];
    importType: string | null;
    importFields: ImportFields;
    usedFields: string[];
    onMappingChange: (updatedMapping: ColumnsMappingResults[]) => void;
    onUsedFieldsChange: (updatedUsedFields: string[] | ((prev: string[]) => string[])) => void;
    onRequirementsChange: (missedRequiredFields: string[]) => void;
}

const ImportMapping: React.FC<ImportMappingProps> = ({ 
    columnsMapping, previewRows, importType, importFields, usedFields, onMappingChange, onUsedFieldsChange, 
    onRequirementsChange
}) => {

    // Initiate the required fields validation on first render
    useEffect(() => {
        // Get the missing fields on initialization
        const missingFields = validateMappingRequirements();

        // Translate the missing field strings
        const translatedMissingFields = missingFields.map(field => t(`import.mapping.${field}`));

        // Return the translated missing field strings
        onRequirementsChange(translatedMissingFields);
    }, []);

    // Validate if the required mapped fields are set
    const validateMappingRequirements = (currentMapping: ColumnsMappingResults[] = columnsMapping) => {
        const missingFields: Set<string> = new Set();
    
        Object.entries(combinedFields).forEach(([_, fields]) => {
            const selectedFields = currentMapping
                .filter((col) =>
                    col.mapping && (fields as ImportField[]).some((field: ImportField) => field.value === col.mapping)
                )
                .map((col) => col.mapping);
    
            const hasAnyFieldSelected = selectedFields.length > 0;
    
            (fields as ImportField[]).forEach((field: ImportField) => {
                // Add required fields if not selected
                if (field.required && !selectedFields.includes(field.value)) {
                    missingFields.add(field.name);

                // Remove if now selected
                } else if (field.required) {
                    missingFields.delete(field.name);
                }
    
                // Add conditionalRequired fields if not selected but relevant
                if (field.conditionalRequired && hasAnyFieldSelected && !selectedFields.includes(field.value)) {
                    missingFields.add(field.name);

                // Remove if now selected
                } else if (field.conditionalRequired) {
                    missingFields.delete(field.name); 
                }
            });
        });
    
        // Convert Set back to an array
        return Array.from(missingFields); 
    };

    // Show default import fields and dynamic import fields based on the import type
    const combinedFields = React.useMemo(() => {
        const dynamicFields = importFields.dynamicFields
            ? importFields.dynamicFields(importType || "")
            : [];
    
        // Start with the base fields
        const groupedBaseFields = importFields.baseFields.reduce<ImportFieldGroup>((acc, item) => {
            // Helper function to determine if it is an import field
            const isImportField = (item: ImportField | ImportFieldGroup): item is ImportField => {
                return 'value' in item && 'name' in item;
            };

            if (isImportField(item)) {
                acc['ungrouped'] = acc['ungrouped'] || [];
                acc['ungrouped'].push(item);
            } else {
                Object.entries(item).forEach(([group, fields]) => {
                    acc[group] = acc[group] || [];
                    acc[group].push(...fields);
                });
            }
            return acc;
        }, {});
    

        // Add the dynamic fields to the right base fields groups
        dynamicFields.forEach((dynamicGroup) => {
            Object.entries(dynamicGroup).forEach(([group, fields]) => {
                groupedBaseFields[group] = groupedBaseFields[group] || [];
                groupedBaseFields[group].push(...fields);
            });
        });
    
        return groupedBaseFields;
    }, [importFields, importType]);

    // Handle the mapping change
    const handleMappingChange = (index: number, value: string) => {

        // Update the columns mapping
        const updatedMapping = columnsMapping.map((col) =>
            col.index === index
                ? { ...col, mapping: value } 
                : col
        );
    
        onMappingChange(updatedMapping);

        // Update the used fields
        onUsedFieldsChange((prev) => {
            const previousMapping = columnsMapping.find((col) => col.index === index)?.mapping;
            const updatedFields = new Set(prev);
    
            // Handle changes to an already selected field
            if (previousMapping && previousMapping !== "skip") {
                updatedFields.delete(previousMapping);
            }
    
            if (value && value !== "skip") {
                updatedFields.add(value);
            }
    
            return Array.from(updatedFields);
        });

        // Validate mapping requirements and notify the parent
        const missingFields = validateMappingRequirements(updatedMapping);

        // Translate the missing field strings
        const translatedMissingFields = missingFields.map(field => t(`import.mapping.${field}`));

        // Return the translated missing field strings
        onRequirementsChange(translatedMissingFields);
    };

    // Filter out the already selected import fields from the dropdown
    const getFilteredFields = (
        fields: any[],
        usedFields: string[],
        currentMapping: string
    ): any[] => {
        return fields.filter(
            (field) =>
                // Always show fields which allow duplicate selections
                field.allowDuplicates ||

                // Always show the current mapped field in the dropdown, to be the selected value
                field.value === currentMapping ||

                // Only show the fields in the dropdown if it isn't already been used
                !usedFields.includes(field.value)
        );
    };

    // Convert the column numbers to excel like column names
    function getExcelColumnName(index: number) {
        let columnName = '';
        while (index >= 0) {
            columnName = String.fromCharCode((index % 26) + 65) + columnName;
            index = Math.floor(index / 26) - 1;
        }
        return columnName;
    }

    // Get the name of the mapped field
    const getMappedFieldName = (mapping: string | undefined, importType: string | null): string | null => {
        if (!mapping) return null;

        // Handle skip field translation
        if (mapping === 'skip') {
            return 'import.general.import_mapping_skip_field';
        }

        // Combine baseFields en dynamicFields
        const allFields = [
            ...importFields.baseFields,
            ...(importType && importFields.dynamicFields ? importFields.dynamicFields(importType) : []),
        ];
    
        // For mapped fields, get the name of the field
        for (const fieldGroup of allFields) {
            for (const [_, fields] of Object.entries(fieldGroup)) {
                const matchedField = fields.find((field: ImportField) => field.value === mapping);
                if (matchedField) {
                    return `import.mapping.${matchedField.name}`;
                }
            }
        }
        return null;
    };

    return (
        <div className='wizard-step'>
            <h3>{t('import.general.import_mapping_header')}</h3>
            <h5>{t('import.general.import_mapping_description')}</h5>
            <div className='step-content import-mapping-grid'>
                {columnsMapping.map((col) => (
                    <div className={`import-mapping-column ${col.mapping && col.mapping !== 'skip' ? 'mapped' : ''}`}
                         key={`column-${col.index}`}>
                        <h4>
                            {t('import.general.import_mapping_column_label')} {getExcelColumnName(col.index)}
                            {col.mapping && (
                                <span> <FontAwesomeIcon icon={faArrowRight} /> {t(getMappedFieldName(col.mapping, importType))}</span>
                            )}
                        </h4>
                        <ul className='mapping-preview'>
                            {previewRows.map((row, i) => (
                                <li key={i}>
                                    <p>{row[col.index] || "-"}</p>
                                </li>
                            ))}
                        </ul>
                        <div className='mapping-dropdown'>
                            <select value={col.mapping || ""}
                                    onChange={(e) => handleMappingChange(col.index, e.target.value)}>
                                <option value="" disabled>{t('import.general.import_mapping_choose_field')}</option>
                                {importFields && (
                                    Object.entries(combinedFields).map(([section, fields]) => (
                                        <optgroup key={section} label={section}>
                                            {getFilteredFields(fields, usedFields, col.mapping).map((field) => (
                                                <option key={field.value} value={field.value}>
                                                    {t(`import.mapping.${field.name}`)}
                                                    {field.description ? ` (${t(`import.mapping.${field.description}`)})` : ''}
                                                </option>
                                            ))}
                                        </optgroup>
                                    ))
                                )}
                                <option value="skip">{t('import.general.import_mapping_skip_field')}</option>
                            </select>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ImportMapping;
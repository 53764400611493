import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnsMappingResults, ImportValidations, ValidationResults } from 'components/import/ImportTypes';
import CheckboxCheck from 'assets/CheckboxCheck.svg';

interface ValidateImportProps {
    csvData: string[][];
    columnsMapping: ColumnsMappingResults[];
    importType?: string | null;
    skipFirstRow: boolean;
    validations?: ImportValidations;
    validationResults: ValidationResults;
    onValidationChange: (results: ValidationResults | ((prev: ValidationResults) => ValidationResults)) => void;
    onSkipFirstRowChange: (value: boolean) => void;
}

const ImportValidation: React.FC<ValidateImportProps> = ({
    csvData, columnsMapping, importType, skipFirstRow, validations, validationResults, onValidationChange, onSkipFirstRowChange
}) => {
    const { t } = useTranslation();

    // Show default import validations and dynamic import validations based on the import type
    const combinedValidations = React.useMemo(() => {
        const staticValidations = validations?.validations || [];
        const dynamicValidations = validations?.dynamicValidations
            ? validations.dynamicValidations(importType || "")
            : [];
        return [...staticValidations, ...dynamicValidations];
    }, [validations, importType]);
    
    // Determine the validations to render
    useEffect(() => {
        const validateFields = () => {
            onValidationChange((prev: ValidationResults) => {
                const validationMap: ValidationResults = { ...prev };
            
                combinedValidations.forEach(({ field, validation: type, resolve, dependentOn }) => {
                    const isMapped = columnsMapping.some((col) => col.mapping === field);
            
                    // Check if the validation is depending on another selected field 
                    const dependenciesSelected = dependentOn
                        ? dependentOn.every((dependentField) => columnsMapping.some((col) => col.mapping === dependentField))
                        : true;
            
                    // Show not selected validation if the belonging field is not selected, and the optional dependencies are selected
                    if (type === "notSelected" && !isMapped && dependenciesSelected) {
                        validationMap[field] = {
                            validation: "notSelected",
                            resolve: resolve || '',
                        };
                    }
            
                    // Show map values to options validation if the belonging field is selected
                    if (type === "mapValuesToOptions" && isMapped) {
                        const targetColumn = columnsMapping.find((col) => col.mapping === field);
                        if (targetColumn) {
                            const columnIndex = targetColumn.index;
            
                            // Create a list of unique values to map to the options
                            const uniqueValues = Array.from(

                                // Skip the first row, if it contains the headers of the file
                                new Set(csvData
                                    .slice(skipFirstRow ? 1 : 0)
                                    .map((row) => row[columnIndex])
                                )
                            )

                            // Filter out empty cells, cells without a value
                            .filter((value) => value !== null && value !== undefined && value.trim() !== "")

                            // Sort the values numerical or alphabetical
                            .sort((a, b) => {
                                // Convert strings to numbers if possible
                                const numA = parseFloat(a);
                                const numB = parseFloat(b);
        
                                const isNumA = !isNaN(numA);
                                const isNumB = !isNaN(numB);
        
                                // If both are numbers, sort numerically
                                if (isNumA && isNumB) {
                                    return numA - numB;
                                }
        
                                // Otherwise, sort alphabetically
                                return a.localeCompare(b);
                            });
            
                            validationMap[field] = {
                                validation: "mapValuesToOptions",
                                resolve: uniqueValues.map((value) => ({
                                    unique_value: value,

                                    // Only continue if the resolve is an array
                                    backend_option: Array.isArray(prev[field]?.resolve)
                                        ? (prev[field]?.resolve as { unique_value: string; backend_option: string | null }[])
                                              .find((res) => res.unique_value === value)?.backend_option || null
                                        : null,
                                })),
                            };
                        }
                    }
                });
            
                return validationMap;
            });            
        };
    
        if (csvData.length > 0 && columnsMapping.length > 0) {
            validateFields();
        }
    }, [csvData, columnsMapping, combinedValidations, skipFirstRow, onValidationChange]);
    
    // Handle values to options mapping change
    const handleValuesToOptionsMappingChange = (field: string, valueIndex: number, newMapping: string) => {
        const currentField = validationResults[field];
    
        if (currentField?.validation === "mapValuesToOptions" && Array.isArray(currentField.resolve)) {
            const updatedValidationResults: ValidationResults = {
                ...validationResults,
                [field]: {
                    ...currentField,
                    resolve: currentField.resolve.map((item, idx) =>
                        idx === valueIndex
                            ? { ...item, backend_option: newMapping }
                            : item
                    ),
                },
            };
    
            onValidationChange(updatedValidationResults);
        }
    };
    
    return (
        <div className='wizard-step'>
            <h3>{t('import.general.validations_header')}</h3>
            <h5>{t('import.general.validations_description')}</h5>
            <div className='step-content import-validation-grid'>
                <div className='import-validation-column'>
                    <h4>{t('import.general.skip_first_row_header')}</h4>
                    <p>{t('import.general.skip_first_row_description')}</p>
                    <div className='checkbox-item'>
                        <div className='checkbox-wrapper'>
                            <input 
                                type='checkbox'
                                id='skip_first_row'
                                checked={skipFirstRow}
                                onChange={(event) => onSkipFirstRowChange(event.target.checked)}
                                className='hidden-checkbox' />
                            <label htmlFor='skip_first_row'
                                className={`custom-checkbox left ${skipFirstRow === true ? 'checked' : ''}`}>
                                {skipFirstRow === true && <img src={CheckboxCheck} alt="CheckboxCheck" className='checkbox-check' />}
                            </label>
                        </div>
                        <label htmlFor={`skip_first_row`} className='checkbox-label'>
                            {t('import.general.skip_first_row_checkbox_label')}
                        </label>
                    </div>

                    {/* Example table with first row */}
                    <table className='import-example-table'>
                        <tbody>
                            {csvData
                                .slice(skipFirstRow ? 1 : 0, 3)
                                .map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td className='row-number'>
                                            {rowIndex + (skipFirstRow ? 2 : 1)}
                                        </td>
                                        {columnsMapping
                                            .filter((col) => col.mapping && col.mapping !== 'skip')
                                            .map((col) => (
                                                <td key={col.index}>
                                                    <p>{row[col.index]}</p>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                )
                            )}
                        </tbody>
                    </table>
                </div>

                {Object.entries(validationResults).map(([field, result], index) => {
                    // Find the right validations
                    const validationConfig = combinedValidations.find(
                        (v) => v.field === field && v.validation === result.validation
                    );
        
                    // Skip if no validations are found
                    if (!validationConfig) return null;
        
                    // Render not selected validation
                    if (result.validation === "notSelected") {
                        return (
                            <div key={index}
                                 className='import-validation-column'>
                                <h4>{t(validationConfig.title)}</h4>
                                <p>{t(validationConfig.message)}</p>
                            </div>
                        );
                    }
        
                    // Render map value to options validation
                    if (result.validation === "mapValuesToOptions" && Array.isArray(result.resolve)) {
                        return (
                            <div key={index}
                                 className='import-validation-column'>
                                <h4>{t(validationConfig.title)}</h4>
                                <p>{t(validationConfig.message)}</p>
                                <div className='import-validation-mapping-options'>
                                    <div className='import-validation-mapping-label'>
                                        <div>{t('import.general.validations_mapping_import_value_label')}</div>
                                        <div>{t('import.general.validations_mapping_system_option_label')}</div>
                                    </div>
                                    {result.resolve.map((uniqueValue: { unique_value: string; backend_option: string | null }, valueIndex: number ) => (
                                        <div key={valueIndex}
                                            className='import-validation-mapping'>
                                            <div className='import-validation-mapping-value'>
                                                {uniqueValue.unique_value}
                                            </div>
                                            <select value={uniqueValue.backend_option || ""}
                                                    onChange={(e) => handleValuesToOptionsMappingChange(field, valueIndex, e.target.value)}>
                                                <option value="" disabled>{t('import.general.validations_mapping_choose_option')}</option>
                                                {validationConfig.options?.map(
                                                    ({ value, label }: { value: string; label: string }) => (
                                                        <option key={value} value={value}>
                                                            {t(label)}
                                                        </option>
                                                    )
                                                )}
                                                <option value="" disabled></option>
                                                <option value="skip">{t('import.general.import_mapping_skip_field')}</option>
                                            </select>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    }
        
                    return null;
                })}
            </div>
        </div>
    );
};

export default ImportValidation;
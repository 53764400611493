import React from 'react';
import { FieldsCardProps } from 'types/DetailPageTypes'; 
import { useTranslation } from 'react-i18next';
import { FieldType } from 'types/FieldTypes';
import LiveEditField from 'components/forms/LiveEditField';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { roleSpecificFeatures } from 'services/permissions/roleSpecificFeatures';
import '../../style/scss/card.scss';

const FieldsCard: React.FC<FieldsCardProps> = ({ 
    viewKey, apiObject, itemId, title, fields = [], fieldColumns, data, dropdownData, groupOptions, 
    alignment, isEditable, loadStatus, refetchData 
}) => {
    const { t } = useTranslation();
    const { activeFeatures, allowedFeatures } = useAuthContext();

    // Determine if the fields card has multiple columns
    const hasColumns = fieldColumns && fieldColumns.length > 0;

    // Helper function to get the value from a nested object using a path
    const getNestedValue = (obj: any, path: string) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    // Determine if the field should be rendered based on data condition or active feature restriction
    const shouldRenderField = (field: FieldType): boolean => {
        // Check on data condition
        let meetsDataCondition = true;        
        if ('dataCondition' in field) {
            if (typeof field.dataCondition === 'string') {
                // If data condition is a string, check if this has a value in the data object
                meetsDataCondition = data.hasOwnProperty(field.dataCondition) && data[field.dataCondition] != null;

            } else if (Array.isArray(field.dataCondition)) {
                // If data condition is an array of conditions, check if the condition match a value in the data object field
                meetsDataCondition = field.dataCondition.every(condition => {
                    if ('field' in condition) {
                        const nestedValue = getNestedValue(data, condition.field);
    
                        if (condition.notValues) {
                            // Exclude fields with values in notValues
                            return !condition.notValues.includes(nestedValue);
                        }
                        if (condition.values) {
                            // Include fields with values in values
                            return condition.values.includes(nestedValue);
                        }
                    } else {
                        return Object.entries(condition).every(([key, value]) => {
                            const nestedValue = getNestedValue(data, key);
                            return nestedValue === value;
                        });
                    }
                    return true;
                });
            }

            // // If data condition is a string, check if this has a value in the data object
            // if (typeof field.dataCondition === 'string') {
            //     meetsDataCondition = data.hasOwnProperty(field.dataCondition) && data[field.dataCondition] != null;
            // } 

            // // If data condition is an array, check if the condition match a value in the data object field
            // else if (Array.isArray(field.dataCondition)) {
            //     meetsDataCondition = field.dataCondition.every(condition =>
            //         Object.entries(condition).every(([key, value]) => {
            //             const nestedValue = getNestedValue(data, key);
            //             return nestedValue === value;
            //         })
            //     );
            // }
        }

        // Check on active feature
        const isActiveFeature = !('activeFeature' in field) || (
            field.activeFeature && 
            activeFeatures.includes(field.activeFeature) &&
            (!roleSpecificFeatures.includes(field.activeFeature) || allowedFeatures.includes(field.activeFeature))
        ) ? true : false;

        // Field needs to be rendered if both is true
        return meetsDataCondition && isActiveFeature;
    };

    // Function to render the fields
    const renderFields = (fields: FieldType[]) => {
        return fields.filter(shouldRenderField).map((field, index) => {

            // Use the is editable prop of the field itself, if given. Otherwise fallback to the is editable prop given by the parent component
            const fieldEditable = 'isEditable' in field && field.isEditable !== undefined ? field.isEditable : isEditable;

            return (
                <LiveEditField 
                    key={index} 
                    viewKey={viewKey}
                    apiObject={apiObject}
                    fieldItem={field}
                    itemId={itemId}
                    data={data} 
                    dropdownData={dropdownData}
                    groupOptions={groupOptions}
                    alignment={alignment}
                    isEditable={fieldEditable}
                    refetchData={refetchData ? refetchData : () => {}}
                />
            )
        });
    };

    return (
        <div className='card fieldscard'>
            {title && 
                <h6>{t(title)}</h6>
            }
            {loadStatus === "loading" ? (
                <div className="loader"></div>
            ) : loadStatus === "loaded" && (
                hasColumns ? (
                    // If field columns are given, render the fields inside the columns
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                         className='multi-columns'>
                        {fieldColumns.map((column, columnIndex) => (
                            <div key={columnIndex} style={{ flex: 1 }}>
                                {renderFields(column)}
                            </div>
                        ))}
                    </div>
                ) : (
                    // If no field columns are given, but just fields, render them 
                    fields?.map((field) => renderFields([field]))
                )                
            )}
        </div>
    );
}

export default FieldsCard;
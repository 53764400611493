import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import ProductForm from './ProductForm';
import { useSettings } from 'services/settings/SettingsContext';
import { renderPriceFloatColumn } from 'components/lists/functions/renderListColumn';

// Rename the name of the function:
const ProductList: React.FC = () => {
    const { userLocale } = useSettings();

    // Specify the options of this list:
    const objectName = 'product.general.object_name'
    const query = {
        endpoint: 'get_product_list',
        defaultParams: { 'is_active': true },
        object: 'product'
    }
    const showSearch = true
    const showDeleteOrDeactivate = 'flag_deactivated'
    const showImport = true
    const showExport = true
    const showColumnSelector = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = true

    // Specify form, detail modal or detailpage for this list
    const formModal = <ProductForm />;
    const onRowClick = 'detailPage' // Either plug in 'editModal' or 'detailPage'
    const detailPageUrl = 'products' // E.g. www.example.com/product/12010

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '7fr', field: 'name', label: 'product.general.name_label' },
        { width: '5fr', field: 'number', label: 'product.general.number_label' },
        { width: '8fr', field: 'description', label: 'product.general.description_label' },
        { width: '4fr', field: 'sale_price', label: 'product.general.sale_price_label', render: (_, row) => renderPriceFloatColumn(row.sale_price, row.sale_price_currency, userLocale) },
    ];

    // If the list is paginated, define the additional columns for the column selector:
    const additionalColumns: ListColumnType[] = [
        { width: '8fr', field: 'created', label: 'product.general.created_label' },
        { width: '8fr', field: 'modified', label: 'product.general.modified_label' },
        { width: '5fr', field: 'unit', label: 'product.general.unit_label' },
        { width: '9fr', field: 'category', label: 'product.general.category_label' },
        { width: '5fr', field: 'division', label: 'product.general.division_label' },
    ];

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'active',
            label: 'product.general.active_products_label',
            params: { 'is_active': true },
            default: true,
        },
        {
            group: 'general.archive',
            filters: [
                {
                    id: 'inactive',
                    label: 'product.general.inactive_products_label',
                    shortName: 'general.deactivated',
                    params: { 'is_active': false },
                }
            ]   
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            filters={filters}
            columns={columns}
            additionalColumns={additionalColumns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            showImport={showImport}
            showExport={showExport}
            showColumnSelector={showColumnSelector}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
            detailPageUrl={detailPageUrl}
        />
    );
};

export default ProductList;
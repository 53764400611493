import React from 'react';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';

/*
 * GenerateHeaderTitle.tsx
 * Generate the header title for detail modals based on the api 
 * object of the modal.
 */

interface HeaderTitleProps {
    apiObject?: string;
    data?: any;
}

const GenerateHeaderTitle: React.FC<HeaderTitleProps> = ({ apiObject, data }) => {
    const { t } = useTranslation();
    const { environmentSettings } = useSettings();

    if (!data || !apiObject) {
        return null;
    }

    let headerTitle = null;

    // Generate the header for jobs
    if (apiObject === 'job') {
        // Header for a single job
		if (data['project'] === null) {
			headerTitle = <>{t('job.general.job_header', { number: t(data['id']) })}</>;

        // Header for a sub job inside a project
        } else {
            headerTitle = (
				<>
					{t('job.general.project_header', { number: t(data['project'].number) })} <FontAwesomeIcon icon={faGreaterThan} /> {t('job.general.job_header', { number: t(data['id']) })}
				</>
			);
        }

    // Generate the header for a project
    } else if (apiObject === 'project') {
        headerTitle = <>{t('job.general.project_header', { number: t(data['id']) })}</>;

    // Generate the header for a proposal
	} else if (apiObject === 'proposal') {
        // Header for draft proposals
        if (data['status'] === 'draft') {
            headerTitle = <>{t('proposal.general.proposal_draft_header', { number: t(data['number']) })}</>;

        // Header for accepted proposals
        } else if (data['status'] === 'accepted') {

            // If the environment uses orders, show order header
            if (environmentSettings.proposal_use_orders) {
                headerTitle = <>{t('order.general.order_header', { number: t(data['order_number']) })}</>;

            // Or show the proposal header
            } else {
                headerTitle = <>{t('proposal.general.proposal_header', { number: t(data['number']) })}</>;
            }

        // Header for sent proposals
        } else {
            headerTitle = <>{t('proposal.general.proposal_header', { number: t(data['number']) })}</>;
        }    

    // Generate the header for an invoice
	} else if (apiObject === 'invoice') {
        // Header for draft invoices
        if (data['status'] === 'draft') {
            headerTitle = <>{t('invoice.general.invoice_draft_header', { number: t(data['number']) })}</>;
        
        // Header for sent invoices
        } else {
            headerTitle = <>{t('invoice.general.invoice_header', { number: t(data['number']) })}</>;
        }

    // Generate the header for a release
    } else if (apiObject === 'release') {
        headerTitle = <>{t('release.general.edit_release_header', { version: t(data['version'] )})}</>
    }

    return (
        <>
            {headerTitle}
        </>
    );
};

export default GenerateHeaderTitle;
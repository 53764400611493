import React, { ReactElement } from 'react';
import { saveData } from "services/api/saveData";
import { ModalProps } from "../ModalContext";
import InvoiceModal from "../../../views/invoices/InvoiceModal";

export const handleCreateInvoice = async ({
    itemId,
    data,
    initializeModal,
    setFloatingAlert,
    setButtonLoader,
    buttonAction,
    workflowId,
}: {
    itemId: number;
    data: any;
    initializeModal: (content: ReactElement<any>, props?: ModalProps, itemId?: number | null) => void; 
    setFloatingAlert: Function;
    setButtonLoader: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
    buttonAction: string;
    workflowId?: number;
}) => {

    // If no layout id is given, return
    if (!workflowId) return;

    console.log("data", data);

    try {
        // Zet de loader aan
        setButtonLoader((prev) => ({ ...prev, [buttonAction]: true }));

        // Configure the data object from the item data
        const submitData: Record<string, any> = {
            order_id: itemId,
            order_number: data.order_number,
        }

        // Check of workflowId aanwezig is
        if (workflowId) {
            submitData.workflow_id = workflowId;
        }

        // Check of recipient aanwezig is en een id heeft
        if (data.recipient?.id) {
            submitData.recipient = data.recipient.id;
        }

        // Check of address aanwezig is en een id heeft
        if (data.address?.id) {
            submitData.address = data.address.id;
        }

        // Voeg accepted_date toe als het bestaat
        if (data.accepted_date) {
            submitData.accepted_date = data.accepted_date;
        }

        // Post de nieuwe invoice
        const response = await saveData({
            apiUrl: `post_invoice`,
            method: 'post',
            data: submitData
        });

        if (response && response.status === 201) {
            const newInvoiceId = response.data.id;

            if (newInvoiceId) {
                initializeModal(
                    <InvoiceModal itemId={newInvoiceId} />,
                    { modalSize: 'large', itemId: newInvoiceId }
                )
            }

            // Toon succesmelding
            setFloatingAlert({
                type: 'success',
                message: 'Invoice created successfully!',
            });
        }
    } catch (error) {
        console.error('Error creating invoice:', error);
        setFloatingAlert({
            type: 'danger',
            message: 'Failed to create invoice.',
        });
    } finally {
        // Zet de loader uit
        setButtonLoader((prev) => ({ ...prev, [buttonAction]: false }));
    }
};
// Group the data by field, if given
export const groupDataByField = <T>(data: T[], groupByField: keyof T | undefined): Record<string, T[]> => {

    // If the property is not given, don't group the data
    if (!groupByField) {
        return { ungrouped: data };
    }
        
    // Group the data on the given field
    return data.reduce((groups, item) => {
        const key = item[groupByField] as string | undefined;

        // Skip rows without a value in the given field
        if (!key) return groups;

        if (!groups[key]) groups[key] = [];
        groups[key].push(item);
        return groups;
    }, {} as Record<string, T[]>);
};
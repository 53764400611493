import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import ProposalModal from './ProposalModal';
import { useSettings } from 'services/settings/SettingsContext';
import { renderDate, renderDocumentNumber, renderPriceDecimalColumn, renderStatusBadge } from 'components/lists/functions/renderListColumn';

// Rename the name of the function:
const ProposalList: React.FC = () => {
    const { userLocale } = useSettings();

    // Specify the options of this list:
    const query = {
        endpoint: 'get_proposal_list',
        defaultParams: { 'deleted': false },
        object: 'proposal'
    }
    const objectName = 'proposal.general.object_name'
    const showSearch = true
    const showDeleteOrDeactivate = 'flag_deleted'
    const showImport = true
    const showExport = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = true

    // Specify form, detail modal or detailpage for this list
    const postNewItem = { url: 'post_proposal' }

    const detailModal = <ProposalModal />;
    const onRowClick = 'detailModal'  

    // Specify the columns of this list
    const columns: ListColumnType[] = [
        { width: '1.5fr', field: 'number', label: 'proposal.general.proposal_number_label', render: (_, row) => renderDocumentNumber(row.number, row.status, 'proposal') },
        { width: '3fr', field: 'internal_reference', label: 'proposal.general.internal_reference_label' },
        { width: '2fr', field: 'proposal_date', label: 'proposal.general.date_label', render: (_, row) => renderDate(row.proposal_date, userLocale) },
        { width: '4fr', field: 'recipient_name', label: 'proposal.general.recipient_label' },
        { width: '1.5fr', field: 'amount', label: 'proposal.general.amount_label', render: (_, row) => renderPriceDecimalColumn(row.amount, row.currency, userLocale) },
        { width: '144px', field: 'status', label: 'proposal.general.status_label', render: (_, row) => renderStatusBadge(row.status) }
    ];

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'active',
            label: 'proposal.general.all_proposals_filter_label',
            params: { 'deleted': false },
            default: true,
        },
        {
            group: 'proposal.general.status_filter_group_label',
            filters: [
                {
                    id: 'draft',
                    label: 'proposal.status.draft',
                    params: { 'status': 'draft' },
                },
                {
                    id: 'open',
                    label: 'proposal.status.open',
                    params: { 'status': 'open' },
                },
                {
                    id: 'expired',
                    label: 'proposal.status.expired',
                    params: { 'status': 'expired' },
                },
                {
                    id: 'accepted',
                    label: 'proposal.status.accepted',
                    params: { 'status': 'accepted' },
                },
                {
                    id: 'rejected',
                    label: 'proposal.status.rejected',
                    params: { 'status': 'rejected' },
                },
            ]
        },
        {
            group: 'general.archive',
            filters: [
                {
                    id: 'deleted',
                    label: 'proposal.general.deleted_proposals_label',
                    shortName: 'general.deleted',
                    params: { 'deleted': true },
                }
            ]   
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            filters={filters}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            showImport={showImport}
            showExport={showExport}
            postNewItem={postNewItem}
            detailModal={detailModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default ProposalList;
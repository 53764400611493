import React from 'react';
import { ListColumnType, ListFilterType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import EnvironmentForm from './EnvironmentForm';

// Rename the name of the function:
const EnvironmentList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_environment_as_superuser_list',
        defaultParams: { 'is_active': true },
        object: 'environment_as_superuser'
    }
    const objectName = 'environment.general.object_name'
    const showSearch = true

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = true

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'id', label: 'environment.general.environment_id_label' },
        { width: '9fr', field: 'name', label: 'environment.general.environment_name_label' },
    ];

    // Specify form, detail modal or detailpage for this list
    const formModal = <EnvironmentForm />;
    const onRowClick = 'editModal'

    // Specify the filters of this list:
    const filters: ListFilterType[] = [
        {
            id: 'active',
            label: 'environment.general.active_environments_filter_label',
            params: { 'is_active': true },
            default: true,
        },
        {
            group: 'environment.general.environment_status_filter_group_label',
            filters: [
                {
                    id: 'expired',
                    label: 'environment.status.expired',
                    params: { 'status': 'expired' },
                },
                {
                    id: 'suspended',
                    label: 'environment.status.suspended',
                    params: { 'status': 'suspended' },
                },
                {
                    id: 'cancelled',
                    label: 'environment.status.cancelled',
                    params: { 'status': 'cancelled' },
                },
            ]
        },
        {
            group: 'environment.general.environment_type_filter_group_label',
            filters: [
                {
                    id: 'trials',
                    label: 'environment.general.trial_environments_filter_label',
                    params: { 'type': 'trial' },
                },
                {
                    id: 'beta-environments',
                    label: 'environment.general.beta_environments_filter_label',
                    params: { 'type': 'beta' },
                },
                {
                    id: 'clients',
                    label: 'environment.general.client_environments_filter_label',
                    params: { 'type': 'client' },
                },
            ]
        },
        {
            group: 'general.archive',
            filters: [
                {
                    id: 'deactivated',
                    label: 'environment.general.deactivated_environments_filter_label',
                    shortName: 'general.deactivated',
                    params: { 'is_active': false },
                }
            ]   
        }
    ]

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            filters={filters}
            columns={columns}
            showSearch={showSearch}
            formModal={formModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default EnvironmentList;
import React from 'react';
import DetailModal from 'components/modals/DetailModal';
import { DetailCardType } from 'types/DetailPageTypes';
import { HeaderButtons } from 'types/ButtonTypes';
import { FieldOption } from 'types/FieldTypes';

// Function to determine the client field link url based on its entity type
const determineLinkUrl = (recipient: any) => {
    if (recipient && recipient['entity_type'] && recipient['id']) {
        const baseUrl = recipient['entity_type'] === 'organization' ? '/contacts/organization' : '/contacts/person';
        return `${baseUrl}/${recipient['id']}`;
    }
    return null;
}

const filterLocationsOnRecipient = (recipient: any) => {
    if (recipient) {

        const contactFilterParam = { organization_id: recipient['id'] };

        console.log(contactFilterParam)

        return contactFilterParam;
    }
    return null;
}

// Rename the name of the function:
const ProposalModal: React.FC<{ viewKey?: string, itemId?: number }> = ({ viewKey, itemId }) => {

    // Specify the parameters of this detail modal:
    const apiObject = 'proposal';
    const object = 'proposal';
    const showStatusBar = false;

    // Define the header buttons of the modal:
    const headerButtons: HeaderButtons = [
        {
            type: 'secondary',
            label: 'button.download_pdf_button_label',
            action: 'download_pdf',
            objectType: 'order',
            allowedRight: ['can_download'],
            condition: [{ field: 'is_order', values: [true] }],
            query: {
                endpoint: 'get_pdf_layout_list/order',
                object: 'pdf_layout',
                defaultParams: { deleted: false },
            },
        },
        {
            type: 'secondary',
            label: 'button.create_invoice_button_label',
            action: 'create_invoice',
            restrictedRight: ['only_view'],
            condition: [{ field: 'is_order', values: [true] }],
            query: {
                endpoint: 'get_workflow_for_feature_list/invoice',
                object: 'invoice_workflow',
                defaultParams: { is_active: true },
            },
        },
        // {
        //     type: 'secondary',
        //     label: 'button.download_pdf_button_label',
        //     action: 'download_pdf',
        //     objectType: 'proposal',
        //     allowedRight: ['can_download'],
        //     condition: [{ field: 'is_order', values: [false] }],
        //     query: {
        //         endpoint: 'get_pdf_layout_list/proposal',
        //         object: 'pdf_layout',
        //         defaultParams: { deleted: false },
        //     },
        // },
        {   
            type: 'dropdown',
            label: 'button.more_button_label',
            buttons: [
                // {
                //     label: 'button.duplicate_proposal_button_label',
                //     action: 'duplicate',
                //     restrictedRight: ['only_view'],
                // },
                {   
                    label: 'button.create_invoice_button_label',
                    action: 'create_invoice',
                    restrictedRight: ['only_view'],
                    allowedFeature: ['invoices'],
                    condition: [{ field: 'is_order', values: [true] }]
                },
                {   
                    label: 'button.create_job_button_label',
                    action: 'create_job',
                    restrictedRight: ['only_view'],
                    allowedFeature: ['invoices'],
                    condition: [{ field: 'is_order', values: [true] }]
                },
                {
                    label: 'button.delete_proposal_button_label',
                    action: 'delete',
                    restrictedRight: ['only_view'],
                    allowedRight: ['can_delete'],
                    condition: [{ field: 'is_order', values: [false] }]
                },
                {
                    label: 'button.delete_order_button_label',
                    action: 'delete',
                    restrictedRight: ['only_view'],
                    allowedRight: ['can_delete'],
                    condition: [{ field: 'is_order', values: [true] }]
                }
            ]
        },
        {   
            type: 'primary',
            label: 'button.send_proposal_button_label',
            action: 'send_proposal',
            restrictedRight: ['only_view'],
            customClass: 'sent_proposal',
            condition: [{ field: 'status', values: ['draft'] }]
        },
        {   
            type: 'primary',
            label: 'button.won_proposal_button_label',
            action: 'proposal_won',
            restrictedRight: ['only_view'],
            customClass: 'won',
            condition: [{ field: 'status', values: ['open', 'expired'] }]
        },
        {   
            type: 'primary',
            label: 'button.lost_proposal_button_label',
            action: 'proposal_lost',
            restrictedRight: ['only_view'],
            customClass: 'lost',
            condition: [{ field: 'status', values: ['open', 'expired'] }]
        },
    ];

    // Define the summary of the modal
    const summary = true;

    // Define the base fields of the modal
    const fields: DetailCardType[] = [
        {
            component: 'FieldsCard',
            title: 'proposal.general.recipient_header',
            alignment: 'horizontal',
            fieldColumns: [
                [
                    { 
                        type: 'searchselect', 
                        name: 'recipient',
                        label: 'proposal.general.recipient_label',
                        placeholder: 'proposal.general.recipient_placeholder',
                        objectName: 'recipient.general.object_name.singular',
                        query: {
                            // Get all contacts which have the contact type 'contact', 'prospect' and 'client'
                            endpoint: 'get_contact_search',
                            params: {
                                contact_type: ['contact', 'prospect', 'client'] 
                            }
                        },
                        postEndpoint: 'post_contact',
                        viewModeLinkUrl: { function: determineLinkUrl },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                            subtitle: { field: 'primary_location', format: (value) => value.address, optional: true},
                        },
                    },
                    { 
                        type: 'searchselect', 
                        name: 'recipient_person',
                        label: 'proposal.general.recipient_person_label',
                        placeholder: 'proposal.general.recipient_person_placeholder',
                        objectName: 'person.general.object_name.singular',
                        query: {
                            // Only get persons and when a recipient is selected, get the persons connected to that contact
                            endpoint: 'get_person_search',
                            params: {
                                
                            }
                        },
                        postEndpoint: 'post_contact',
                        dataCondition: [{'recipient.entity_type': 'organization'}],
                        viewModeLinkUrl: { baseUrl: '/contacts/person' },
                        selectionFormat: 'name',
                        optionFormat: {
                            title: { field: 'name', format: (value) => value },
                        },
                    },
                    { 
                        type: 'searchselect', 
                        name: 'address',
                        label: 'proposal.general.address_label',
                        placeholder: 'proposal.general.address_placeholder',
                        objectName: 'location.general.object_name.singular',
                        query: {
                            // Get all active locations
                            // TODO: Get all locations and when a client is selected, get the primary locations connected to that contact
                            endpoint: 'get_location_search',
                            params: filterLocationsOnRecipient
                        },
                        postEndpoint: 'post_location',
                        selectionFormat: 'address',
                        optionFormat: {
                            title: { field: 'address', format: (value) => value },
                        },
                    },
                ],
                [
                    { 
                        type: 'dropdown', 
                        name: 'workflow', 
                        label: 'proposal.general.workflow_label', 
                        apiObject: 'workflow',
                        apiRequest: [{
                            endpoint: 'get_workflow_list',
                            object: 'workflow',
                            params: { is_active: true, connected_feature: 'proposal' }
                        }],
                        selectionFormat: 'name',
                        optionFormat: (option: FieldOption) => option.name,
                        backendField: 'id',
                        // activeFeature: 'workflows',
                        disabled_selected: 'proposal.general.workflow_disabled_selected',
                        allowNoneOption: false,
                        showSearch: false,
                    },
                    { 
                        type: 'reference', 
                        name: 'reference',
                        label: 'proposal.general.reference_label',
                    },
                    // { 
                    //     type: 'multiselect', 
                    //     name: 'labels', 
                    //     label: 'proposal.general.labels_label', 
                    //     apiObject: 'label',
                    //     apiRequest: [{
                    //         endpoint: 'get_label_list',
                    //         object: 'label',
                    //         params: { is_active: true, connected_feature: 'proposals' }
                    //     }],
                    //     allowNoneOption: false,
                    //     showSearch: false,
                    //     disabled_selected: 'proposal.general.labels_disabled_selected',
                    // },
                    {
                        type: 'sent-date', 
                        name: 'proposal_date', 
                        label: 'proposal.general.proposal_date_label',
                        tooltipText: 'proposal.general.proposal_date_tooltipText',
                        isEditable: { editability: false },
                        dataCondition: [{'is_order': false}],
                    },
                    {
                        type: 'sent-date', 
                        name: 'accepted_date', 
                        label: 'order.general.accepted_date_label',
                        tooltipText: 'order.general.accepted_date_tooltipText',
                        isEditable: { editability: false },
                        dataCondition: [{'is_order': true}],
                    },
                    {
                        type: 'expiration-days',
                        name: 'expiration_days',
                        label: 'proposal.general.expiration_date_label',
                        featureName: 'proposal',
                        dataCondition: [{'is_order': false}],
                    }
                ]
            ]
        },
        {
            component: 'FieldsCard',
            title: 'proposal.general.introduction_text_header',
            alignment: 'stacked',
            fields: [
                { 
                    type: 'textarea', 
                    name: 'introduction_text', 
                    emptyLabel: 'proposal.general.introduction_text_emptyLabel',
                    placeholder: 'proposal.general.introduction_text_placeholder', 
                    enableStyling: 'simple',
                    showToolbarInitially: true,
                    rows: 3,
                },
            ]
        },
        {
            component: 'FieldsCard',
            title: 'proposal.general.lines_header',
            fields: [
                { 
                    name: 'lines',
                    type: 'document-lines',
                    featureName: 'proposal',
                    documentId: itemId,
                    apiRequest: [
                        {
                            endpoint: 'get_vatrate_list',
                            object: 'vatrate',
                            params: { is_active: true }
                        },
                        {
                            endpoint: 'get_ledgeraccount_list',
                            object: 'ledgeraccount',
                            params: { is_active: true, ledger_account_type: 'revenue' }
                        },
                        {
                            endpoint: 'get_currency_list',
                            object: 'currency',
                            params: {}
                        }
                    ]
                }
            ]
        },
        {
            component: 'FieldsCard',
            title: 'proposal.general.terms_and_conditions_header',
            alignment: 'stacked',
            fields: [
                { 
                    type: 'textarea', 
                    name: 'terms_and_conditions', 
                    emptyLabel: 'proposal.general.terms_and_conditions_emptyLabel',
                    placeholder: 'proposal.general.terms_and_conditions_placeholder', 
                    enableStyling: 'simple',
                    showToolbarInitially: true,
                    rows: 3,
                },
            ]
        }
    ]

    return (
        <DetailModal 
            viewKey={viewKey}
            objectType={object}
            apiObject={apiObject}
            itemId={itemId}
            headerButtons={headerButtons}
            statusBar={showStatusBar}
            sections={fields}
            summary={summary}
        />
    );
};

export default ProposalModal;
/*
 * timezoneConversions.ts
 * General utility functions to convert UTC datetime strings into local strings, and
 * convert local strings back into UTC datetime strings.
 */

//// UTC<>Local Timezone Conversion functions ////

// Converts a utc datetime string into a datetime string in the local timezone of the user
export const convertUTCToLocalDateTimeString = (utcDatetime: string | Date, userLocale: string, userTimezone: string): string => {
    
    // Create a date object from the given utc datetime string
    const utcDate = new Date(utcDatetime);

    // Convert the date object into the right timezone, based on the given date
    const dateTimeFormatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });

    // Format the formatted datetime string to the right user timezone, taking into account the right daylight saving time of the given date
    const formattedParts = dateTimeFormatter.formatToParts(utcDate).reduce((acc: any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    // Convert to an iso-like string in the right format
    const localDateTimeString = `${formattedParts.year}-${formattedParts.month}-${formattedParts.day}T${formattedParts.hour}:${formattedParts.minute}:${formattedParts.second}`;
    
    return localDateTimeString;
};

// Converts a utc datetime string into a date string in the local timezone of the user
export const convertUTCToLocalDateString = (utcDatetime: string, userLocale: string, userTimezone: string): string => {
    const dateFormatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    return dateFormatter.format(new Date(utcDatetime));
};

// Converts a utc datetime string into a date object in the local timezone of the user
export const convertUTCToLocalDateObject = (utcDateTime: string, userLocale: string, userTimezone: string): Date => {
    // Convert the utcDateTime string into a date object
    const date = new Date(utcDateTime);

    // Format the date based on the user timezone and locale
    const formatter = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
    });

    // Get the formatted date from the formatter and create a new date
    const parts = formatter.formatToParts(date).reduce((acc: any, part: any) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    // Create a datestring which is always accepted by the Date constructor
    const formattedDateString = `${parts.year}-${parts.month}-${parts.day}`;

    // Return the formatted date string as date object
    return new Date(formattedDateString);
}

// Convert the server UTC datetime into a timestring of the local timezone
export const convertUTCToLocalTimeString = (utcDateTime: string, userLocale: string, userTimezone: string, is12HourFormat: boolean): string => {
    const date = new Date(utcDateTime);
    return date.toLocaleTimeString(userLocale, {
        timeZone: userTimezone,
        hour: '2-digit',
        minute: '2-digit',
        hour12: is12HourFormat
    }).substring(0, 5);
};

// Convert a local datetime string in the timezone of the user to an utc datetime string to save in the backend
export const convertLocalDateTimeToUTC = (localDateTime: string | Date, userLocale: string, userTimezone: string): string => {
    // Create a new date object of the given local datetime string
    const localDate = new Date(localDateTime);

    // Convert the local date object to utc, based on the timezone of the user on the given date
    const localDateTimeParts = new Intl.DateTimeFormat(userLocale, {
        timeZone: userTimezone,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    }).formatToParts(localDate).reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
    }, {} as { [key: string]: string });

    // Create a utc-like string
    const localDateTimeString = `${localDateTimeParts.year}-${localDateTimeParts.month}-${localDateTimeParts.day}T${localDateTimeParts.hour}:${localDateTimeParts.minute}:${localDateTimeParts.second}`;

    // Convert to the utc iso format
    const utcDate = new Date(localDateTimeString).toISOString();

    return utcDate;
};


//// Formatting Conversion Functions ////

// Extract a time string from the local iso datetime string. For example to be used in time fields to only show the time value
export const extractTimeFromLocalDateTime = (localDatetime: string, userLocale: string, is12HourFormat: boolean ): string => {
    const date = new Date(localDatetime);
    const timeFormatter = new Intl.DateTimeFormat(userLocale, {
        hour: '2-digit',
        minute: '2-digit',
        hour12: is12HourFormat
    });

    return timeFormatter.format(date);
};

// Extract the date from the the datetime string
export const extractDateFromLocalDateTime = (
    localDatetime: string, 
    userLocale?: string,
): string => {
    const date = new Date(localDatetime);

    // If no userLocale is given, just extract the date from the datetime string
    if (!userLocale) {
        return localDatetime.split('T')[0];
    }

    // If a userLocale is given, extract the date and format it based on the user locale
    const dateFormatter = new Intl.DateTimeFormat(userLocale, {
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    });

    return dateFormatter.format(date)
};

// Format the date based on the user locale
export const formatDisplayDate = (date: Date, userLocale: string): string => {
    return date.toLocaleDateString(userLocale, { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    });
}
import axios, { AxiosError } from 'axios';

/*
 * handleSaveErrors.tsx
 * General utility function that handles save errors after saving data to the server.
 * It gives console errors when the request is cancelled or saving is not possible due
 * to a general api error. It also ensures that errors associated with form fields can be
 * displayed at the specified field, and form errors which are not associated to field can
 * be displayed in a general error message on top of the form.
 */

// Helper function to map default backend errors to translation strings
function mapDefaultBackendErrorsToTranslation(error: string): string {
    const mapping: Record<string, string> = {
        "A valid integer is required.": "validation.number.cannot_null",
        // Add more translation values for default backend errors here
    };
    return mapping[error] || error;
}

export function handleSaveErrors(error: any, fieldNames: string[]): Record<string, any> {
    if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
        return {};
        
    } else {
        const apiError = error as AxiosError;
        console.error('Saving not possible', error);

        if (apiError.response && typeof apiError.response.data === 'object') {
            const errorDataBackend = apiError.response.data as Record<string, any>;
        
            // Convert the backend error data to match frontend fields
            const errorData: Record<string, any> = {};
            for (const [backendField, errorMessage] of Object.entries(errorDataBackend)) {
                const isIndex = /^\d+$/.test(backendField);

                // If the field has an index
                if (isIndex) {
                    const index = parseInt(backendField, 10);

                    // Ensure we have an object to store the errors for the given index
                    if (!errorData[index]) {
                        errorData[index] = {};
                    }

                    // Process the fields inside this index
                    for (const [fieldKey, fieldErrors] of Object.entries(errorMessage)) {

                        // Convert the default backend errors to a translation string
                        let processedError;

                        // Only grab the first error message if there are more than 1 errors given for the field
                        if (Array.isArray(fieldErrors)) {
                            // Ensure fieldErrors[0] is a string
                            processedError = typeof fieldErrors[0] === 'string'
                                ? mapDefaultBackendErrorsToTranslation(fieldErrors[0])
                                : fieldErrors[0];
                        } else {
                            // Ensure fieldErrors is a string
                            processedError = typeof fieldErrors === 'string'
                                ? mapDefaultBackendErrorsToTranslation(fieldErrors)
                                : fieldErrors;
                        }
        
                        errorData[index][fieldKey] = processedError;
                    }
                } 
                // If field does not have an index
                else {
                    if (fieldNames.includes(backendField)) {
                        // Convert the default backend errors to a translation string
                        const processedError = mapDefaultBackendErrorsToTranslation(errorMessage);
                        errorData[backendField] = processedError;
                    }
                    
                    // If it's a regular field
                    if (!errorData[backendField]) {
                        // Convert the default backend errors to a translation string
                        const processedError = mapDefaultBackendErrorsToTranslation(errorMessage);
                        errorData[backendField] = processedError;
                    }
                }
            }
            
            // If we didn't match any frontend fields, generate a general error message
            if (Object.keys(errorData).length === 0) {
                let errorMessage = 'alert.general_save_error_message';
                for (let field in errorDataBackend) {
                    const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
                    errorMessage += ` ${capitalizedField}: ${errorDataBackend[field]}`;
                }
                return { general: errorMessage };
            }

            return errorData;

        } else {
            return { general: error.message || 'alert.general_save_error_message' };
        }
    }
}
import React from 'react';
import SettingsContainer, { SettingsSettingType } from '../../../components/settings/SettingsContainer';

const ProposalSettings: React.FC = () => {
    const settings: SettingsSettingType[] = [
        {
            title: 'Offerte nummerreeks',
            explanationText: 'Uitleg hoe dit werkt',
            fields: [
                {
                    type: 'text',
                    label: 'Nummerreeks',
                    name: 'proposal_numbering_scheme',
                    placeholder: '',
                    group: 'field-row'
                },
                {
                    type: 'number',
                    label: 'Volgend nummer',
                    name: 'proposal_current_number',
                    group: 'field-row'
                }
            ]
        },
        {
            title: 'Gewonnen offertes omzetten in orders',
            switchButton: {
                enabled: true,
                name: 'proposal_use_orders',
            }
        },
        {
            title: 'Order nummerreeks',
            dependentOn: { fieldName: 'proposal_use_orders', value: true },
            explanationText: 'Uitleg hoe dit werkt',
            fields: [
                {
                    type: 'text',
                    label: 'Nummerreeks',
                    name: 'order_numbering_scheme',
                    placeholder: '',
                    group: 'field-row'
                },
                {
                    type: 'number',
                    label: 'Volgend nummer',
                    name: 'order_current_number',
                    group: 'field-row'
                }
            ]
        },
    ];

    return (
        <SettingsContainer settings={settings} />
    );
};

export default ProposalSettings;
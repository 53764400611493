import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useModal } from 'components/modals/ModalContext';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useWebSocket } from 'services/api/useWebSocket';
import { ColumnsMappingResults, ImportConfig, ValidationResults } from 'components/import/ImportTypes';
import ImportMapping from 'components/import/ImportMapping';
import ImportValidation from 'components/import/ImportValidation';
import ImportOverview from './ImportOverview';
import ImportProgress from './ImportProgress';
import SecondaryButton from 'components/buttons/SecondaryButton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import WarningModal from 'components/modals/WarningModal';
import Dropzone from 'components/forms/basefields/Dropzone';
import { fetchData } from 'services/api/fetchData';
import { saveData } from 'services/api/saveData';
import { parseCsv } from 'components/import/functions/parseCsv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import '../../style/scss/detailpage.scss';
import '../../style/scss/wizard.scss';
import '../../style/scss/import.scss';

const ImportWizard: React.FC<ImportConfig> = ({
    apiObject, importTypes, importFields, importValidations, closeUrl
}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { initializeModal } = useModal();
    const { environmentHash, handleLogout } = useAuthContext();
    const { message: environmentWebsocket } = useWebSocket({ url: 'websockets', websocketGroup: environmentHash });
    const [file, setFile] = useState<File | null>(null);
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [previewRows, setPreviewRows] = useState<string[][]>([]);
    const [csvData, setCsvData] = useState<string[][]>([]);
    const [importType, setImportType] = useState<string | null>(null);
    const [usedFields, setUsedFields] = useState<string[]>([]);
    const [missedRequiredFields, setMissedRequiredFields] = useState<string[]>([]);
    const [skipFirstRow, setSkipFirstRow] = useState<boolean>(false);
    const [columnsMapping, setColumnsMapping] = useState<ColumnsMappingResults[]>([]);
    const [validationResults, setValidationResults] = useState<ValidationResults>({});
    const [importTag, setImportTag] = useState<string | null>(null);
    const [buttonLoader, setButtonLoader] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [importId, setImportId] = useState<number | null>(null);
    const [importStatus, setImportStatus] = useState<string | null>(null);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [processedRows, setProcessedRows] = useState<number>(0);
    const [failedRows, setFailedRows] = useState<Array<{ row_number: number; error: string }> | null>(null);

    // Parse and store the file
    useEffect(() => {
        if (file) {
            const loadCsvData = async () => {
                try {
                    // Parse the csv data
                    const { data } = await parseCsv(file);
    
                    // Set the preview rows to show in the map import componemt
                    setPreviewRows(data.slice(0, 5));
    
                    // Initialize the columns mapping state with the columns from the csv
                    setColumnsMapping(data[0]?.map((_, index) => ({ index, mapping: '' })) || []);

                    // Save the csv data to use in the validate import component
                    setCsvData(data);
                } catch (error) {
                    console.error("Error parsing CSV:", error);
                }
            };
            loadCsvData();
        };
    }, [file]);

    // Only allow going to the second step if the import type is set or don't exist
    useEffect(() => {
        if (currentStep === 1 && file && (!importTypes || importTypes.length === 0)) {
            nextStep();
        }
    }, [currentStep, file, importTypes]);

    // Receive websocket updates during the import process
    useEffect(() => {
        if (importId) {
            const { import_id, type, import_status, progress, failed_rows } = environmentWebsocket || {};

            // Only handle messages of this import
            if (type === 'data_import_progress' && import_id === importId) {
                setProgress(progress);
                setImportStatus(import_status);

                // Set the failed rows if they are given
                if (import_status === 'completed_with_errors') {
                    setFailedRows(failed_rows || []);
                }
                
                // Stop the button loader on completion
                if (import_status === 'completed' || import_status === 'completed_with_errors' || import_status === 'failed') {
                    setButtonLoader(false);   
                }
            }
        }        
    }, [environmentWebsocket, importId]);    

    // Fetch the import data every 5 seconds when the import is in progress
    useEffect(() => {
        let interval: NodeJS.Timeout | null = null;

        const fetchImportStatus = async () => {
            try {
                if (importId) {
                    const responseData = await fetchData({ apiUrl: `get_import/${importId}`, handleLogout });

                    // Update the progress, status and failed rows on response
                    if (responseData) {
                        const { total_rows, processed_rows, progress, status, failed_rows } = responseData;

                        setTotalRows(total_rows);
                        setProcessedRows(processed_rows);
                        setProgress(progress);
                        setImportStatus(status);
                        setFailedRows(failed_rows || []);

                        // Stop interval and button loader if status changes to completed
                        if (status === "completed" || status === "completed_with_errors" || status === "failed") {
                            setButtonLoader(false);
                            if (interval) clearInterval(interval);
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching import status:", error);
            }
        };

        // Fetch the import data every 5 seconds when the import is in progress
        if (importStatus === "in_progress" && importId) {
            interval = setInterval(fetchImportStatus, 5000);
        }

        // Cleanup at unmount
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [importStatus, importId]);

    // Navigate to the next step
	const nextStep = () => {
        if (currentStep === 2 && missedRequiredFields.length > 0) {
            initializeModal(
                <WarningModal
                    header='import.general.missing_field_warning'
                    message={t('import.general.missing_field_message', { missing_fields: missedRequiredFields.join(', ') })}
                    buttonLabel={'OK'}
                />, { modalSize: 'extra-small' }
            );
            return;
        }
        
		if (currentStep < 4) {
			const nextStep = currentStep + 1;
			setCurrentStep(nextStep);
			history.push(`/import-data/${apiObject}/${nextStep}`);
		}
	};

	// Navigate to the previous step
	const prevStep = () => {
		if (currentStep > 1) {
			const previousStep = currentStep - 1;
			setCurrentStep(previousStep);
			history.push(`/import-data/${apiObject}/${previousStep}`);
		}
	};

    // Renders the step count indicator
    const renderStepCountIndicator = () => (
        <div className='step-counter'>
            {t('wizard.general.step_counter', { currentStep: currentStep, totalSteps: 4 })}
        </div>
    )

    // Encode the file into a base64 file
    const encodeFileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = (error) => reject(error);
        });
    };

    // Handle the submit of the import
    const handleSubmit = async () => {
        try {
            if (!file || !importTag) return;
            
            // Start the button loader
            setButtonLoader(true);

            // Convert the selected file into a base 64 file
            const base64File = await encodeFileToBase64(file);

            // Configure the final import data
            const importData = {
                'file': base64File,
                'import_type': importType,
                'mapping': columnsMapping,
                'skip_first_row': skipFirstRow,
                'import_tag_name': importTag,
                'validations': validationResults,
            };

            // Post the import data to the backend
            const response = await saveData({ apiUrl: `import_data/${apiObject}`, method: 'post', data: importData })
            
            // Set the import id when the task is accepted by the backend and set the import status to in progress
            if (response && response.status === 202 && response.data.import_id) {
                setImportId(response.data.import_id);
                setImportStatus('in_progress');
            }
        } catch (error) {
            console.error("Error while submitting import", error);
            setButtonLoader(false);
        };        
	};

    return (
        <div className='container-filled'>
            <div className='detailpage-header row'>
                <div className='header-title'>
                    <h2>{t('import.general.import_object_label', { object_name: t(`${apiObject}.general.object_name.plural`) })}</h2>
                </div>
            </div>
            <div className='wizard import-wizard'>

                {/* Step 1: Select the file and optionally select the import type */}
                {currentStep === 1 && (
                    !file ? (
                        <div className='wizard-step'>
                            <h3>{t('import.general.upload_file_header')}</h3>
                            <Dropzone
                                acceptedFileTypes={[".xls", ".xlsx", ".csv"]}
                                placeholder="import.general.drag_drop_placeholder"
                                onFileSelect={(file) => setFile(file)}
                            />
                        </div>
                    ) : (
                        importTypes && importTypes.length > 0 ? (
                            <div className='wizard-step'>
                                <h3>{t('import.general.import_type_header', { object_name: t(`${apiObject}.general.object_name.plural`) })}</h3>
                                <div className='step-content import-type-grid'>
                                    {importTypes.map(importType => (
                                        <div className='import-type-column'
                                             key={importType.value}
                                             onClick={() => {setImportType(importType.value); nextStep()}}>
                                            <div className='import-type-icon'>
                                                {importType.icon && <FontAwesomeIcon icon={importType.icon} />}
                                            </div>
                                            <div className='label'>
                                                {t(importType.label)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null
                    )
                )}

                {/* Step 2: Map the columns of the file to the fields */}
                {currentStep === 2 && (
                    <ImportMapping 
                        columnsMapping={columnsMapping} 
                        previewRows={previewRows}
                        importType={importType}
                        importFields={importFields}
                        usedFields={usedFields}
                        onMappingChange={setColumnsMapping}
                        onUsedFieldsChange={setUsedFields}
                        onRequirementsChange={setMissedRequiredFields}
                    />
                )}

                {/* Step 3: Handle the selected fields validation */}
                {currentStep === 3 && (
                    <ImportValidation
                        csvData={csvData} 
                        columnsMapping={columnsMapping}
                        importType={importType}
                        skipFirstRow={skipFirstRow}
                        validations={importValidations}
                        validationResults={validationResults}
                        onValidationChange={setValidationResults}
                        onSkipFirstRowChange={(value) => setSkipFirstRow(value)}
                    />
                )}

                {/* Step 4: Import summary */}
                {currentStep === 4 && (
                    !importStatus ? (
                        <ImportOverview
                            csvData={csvData}
                            importType={importType}
                            importFields={importFields}
                            importValidations={importValidations}
                            columnsMapping={columnsMapping}
                            skipFirstRow={skipFirstRow}
                            validationResults={validationResults}
                            apiObject={apiObject}
                            importTag={importTag}
                            onTagGenerated={(tag) => setImportTag(tag)}
                        />
                    ) : (
                        <ImportProgress 
                            progress={progress}
                            totalRows={totalRows}
                            processedRows={processedRows}
                            importStatus={importStatus}
                            failedRows={failedRows}
                        />
                    )
                )}

                {/* Navigation buttons */}
                <div className="button-footer-fixed">
                    <div className="container-filled">
                        {currentStep === 1 ? (
                            renderStepCountIndicator()
                        ) : (
                            <>
                                {currentStep < 4 ? (
                                    <>
                                        <SecondaryButton
                                            label='general.previous'
                                            size='small'
                                            onClick={() => prevStep()} 
                                        />
                                        {renderStepCountIndicator()}
                                        <PrimaryButton
                                            label='general.next'
                                            size='small'
                                            icon={<FontAwesomeIcon icon={faAngleRight} />}
                                            onClick={() => nextStep()} 
                                        />
                                    </>
                                ) : (
                                    importStatus !== 'completed' && importStatus !== 'completed_with_errors' && importStatus !== 'failed' ? (
                                        <>
                                            <SecondaryButton
                                                label='general.previous'
                                                size='small'
                                                onClick={() => prevStep()} 
                                            />
                                            {renderStepCountIndicator()}
                                            <PrimaryButton
                                                label='import.general.start_import_button_label'
                                                size='small'
                                                loading={buttonLoader}
                                                onClick={() => handleSubmit()} 
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <div></div>
                                            <SecondaryButton
                                                label='general.close'
                                                size='small'
                                                onClick={() => history.push(`/${closeUrl}`)} 
                                            />
                                        </>
                                    )
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImportWizard;
import React, { useEffect, useRef, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../style/scss/quill-customized.scss';

/*
 * CustomQuillEditor.tsx
 * Configuration file for the React Quill plugin in our platform. In this file the toolbar
 * is configured. There are three levels that determine which options are displayed in the
 * toolbar, for simple notes we prefer a 'simple' toolbar using the enableStyling prop. 
 * 
 * Also this file makes the editor focused when it's opened, when the initial focus prop is
 * set to true from the parent component. The styling toolbar will only show when the editor
 * is focused, so by setting this property the toolbar is shown directly.
 * 
 * Pay attention! When implementing a <CustomQuillEditor> in a component, there are two
 * important requirements:
 * 1. Use the convertTabsToSpaces function before setting the value inside the textarea.
 * This is needed to show tabs in the frontend, otherwise tabs are not showing, and
 * 2. Sanitize the textarea input before submitting the data to the server, to prevent
 * XXS Cross Site Scripting attacks and keep the data clean.
 * These functions can be found in utils/convertTextareaData.ts.
 */

interface CustomQuillEditorProps {
    value: string;
    onChange?: (value: string) => void;
    enableStyling?: 'simple' | 'extended' | 'comprehensive';
    initialFocus?: boolean;
    placeholder?: string;
    className?: string;
    identifier?: string;
    onTabClose?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    showToolbarInitially?: boolean;
}

const CustomQuillEditor = forwardRef(({ 
    value, onChange, enableStyling = 'simple', initialFocus, placeholder, className, 
    onTabClose, onFocus, onBlur, showToolbarInitially = false, identifier
}: CustomQuillEditorProps, ref: any) => {
    const { t } = useTranslation();
    const [isToolbarVisible, setIsToolbarVisible] = useState(showToolbarInitially);
    const quillRef = useRef<ReactQuill>(null);

    // Makes the editor focused when opening the editor
    useEffect(() => {
        if (initialFocus) { 
            const editorInstance = ref?.current?.getEditor() || quillRef.current?.getEditor();
            editorInstance.focus();
        }
    }, []);

    // Add listeners for the key down event
    useEffect(() => {
        if (onTabClose) {
            const editorInstance = quillRef.current?.getEditor();
            if (editorInstance) {
                editorInstance.root.addEventListener('keydown', handleKeyDown);
            }

            return () => {
                if (editorInstance) {
                    editorInstance.root.removeEventListener('keydown', handleKeyDown);
                }
            };
        }
    }, [onTabClose]);

    // Call back to parent component when the tab key is pressed
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Tab') {
            event.preventDefault();

            // Call back the closeOnTab function
            if (onTabClose) {
                onTabClose();
            }
        }
    }

    // Configure the toolbar options
    const toolbarOptions = {
        simple: [
            ['bold', 'italic', 'underline', { 'header': 1 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            ['clean'],
        ],
        extended: [
            ['bold', 'italic', 'underline'], 
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            ['link'],
            ['clean'],
        ],
        comprehensive: [
            ['bold', 'italic', 'underline', { 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            ['link'],
            ['clean'],
        ]
    }

    // Configure the Quill textarea editor styling toolbar
    const quillModuleConfig = {
        clipboard: {
            matchVisual: false,
            // matchers: [
            //     ['*', (node: any, delta: any) => {
            //         delta.ops = delta.ops.map((op: any) => {
            //             if (op.insert && typeof op.insert === 'string') {
            //                 // Replace any text with its plain text version
            //                 op.insert = op.insert.replace(/<\/?[^>]+(>|$)/g, ""); 
            //             }
            //             return op;
            //         });
            //         return delta;
            //     }]
            // ]
        },
        toolbar: toolbarOptions[enableStyling],
    };

    return (
        <ReactQuill 
            ref={ref || quillRef}
            value={value}
            onChange={onChange}
            modules={quillModuleConfig}
            placeholder={t(placeholder)}
            className={`${className} ${isToolbarVisible ? 'show-toolbar' : 'hide-toolbar'}`}
            onFocus={() => {
                if (onFocus) onFocus(); 
                setIsToolbarVisible(true)
            }}
            onBlur={() => {
                if (onBlur) onBlur(); 
                if (!showToolbarInitially) {
                    setIsToolbarVisible(false)
                }
            }}
        />
    );
});

export default CustomQuillEditor;
import Papa from 'papaparse';

type CsvParseResult<T> = {
    data: T[][];
    errors: Record<string, any>[];
    meta: Record<string, any>[];
    [key: string]: any;
};

// Helper function to parse csv files
export const parseCsv = (file: File): Promise<CsvParseResult<string>> => {
    return new Promise((resolve, reject) => {

        const reader = new FileReader();

        reader.onload = (event) => {

            if (event.target?.result) {
                const content = event.target.result as string;

                Papa.parse(content, {
                    header: false,
                    skipEmptyLines: true,
                    complete: (result: CsvParseResult<string>) => {
                        resolve({
                            data: result.data as string[][],
                            errors: result.errors || [],
                            meta: result.meta || [],
                        });
                    },
                    error: (error: unknown) => reject(error),
                });
            }
        };
        reader.onerror = (error) => reject(error);
        reader.readAsText(file);
    })
};
import React, { useContext, useState, useEffect } from 'react';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import FormFieldContext from '../FormFieldContext';
import { DateFieldType, FieldData } from 'types/FieldTypes';
import FieldWrapper from '../FieldWrapper';
import DateInput from '../basefields/DateInput';
import FieldViewMode from './elements/FieldViewMode';
import '../../../style/scss/live-edit.scss';
import '../../../style/scss/forms.scss';
import '../../../style/scss/tooltip.scss';
import "react-datepicker/dist/react-datepicker.css";

interface DateFieldProps extends DateFieldType {
    shouldAutoFocus?: boolean;
    errorMessage?: string | string[] | undefined;
}

const SentDate: React.FC<DateFieldProps & { data: FieldData, viewKey: string }> = ({ 
    viewKey, name, label, data, convertTimezone, utcDatetime, localDatetime, helperText, 
    tooltipText, placeholder, viewInEditMode, showIfFilled, isEditable, disabled, 
    alignment
}) => {
    const { editing, updatedData, setUpdatedData } = useContext(FormFieldContext);
    const { setUnsavedChanges } = useGlobalContext();
    const { userLocale } = useSettings();
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [sentDate, setSentDate] = useState<Date | null>(null);

    // Set the current value from the received data
    useEffect(() => {
        if (data && data[name]) {
            const dateValue = new Date(data[name]);

            // Check if the date value is a legit date object
            if (!isNaN(dateValue.getTime())) {
                setSelectedDate(dateValue);
            } else {
                setSelectedDate(null);
            }
        };
    }, [data, name]);

    // Determine the sent date to show as field view value
    useEffect(() => {
        if (data) {
            // Initialize the sent date
            let sentDateBackendValue;

            // Determine the backend sent date for invoices
            if (data['invoice_date'] !== undefined || data['invoice_date'] === null) {
                sentDateBackendValue = data['invoice_date'];
            }

            // Add other cases here, e.g. for proposals or purchases

            // Determine the sent date
            const determineSentDate = sentDateBackendValue 
            
                // If the sent date exists in the backend, use it
                ? new Date(sentDateBackendValue)

                // If the sent date doesn't exist in the backend, use today
                : new Date();

            // Set the sent date
            setSentDate(determineSentDate);
        }
    }, [data])

    // Don't show the field if it only may be visible if it contains a value
    if (showIfFilled && (!selectedDate)) {
        return null;
    }

    // Handles the date change
    const handleDateChange = (dateString: string | null) => {
        if (dateString === null) {
            // If the date is deleted, set it to null
            setSelectedDate(null);
            setUpdatedData({ ...updatedData, [name]: null })
        } else {
            const parsedDate = new Date(dateString);
            if (!isNaN(parsedDate.getTime())) {
                setSelectedDate(parsedDate);
            } else {
                setSelectedDate(null);
            }
        }
    
        // If there are changes, set unsaved changes and updated data
        const hasChanged = dateString !== (data[name] ? new Date(data[name]).toISOString().split('T')[0] : null);
        setUnsavedChanges(viewKey, hasChanged);
        if (hasChanged) {
            setUpdatedData({ ...updatedData, [name]: dateString });
        }
    };

    // Format the sent date
    const formattedSentDate = sentDate && sentDate.toLocaleDateString(userLocale, {year: 'numeric', month: '2-digit', day: '2-digit'});

    return (
        <FieldWrapper
            name={name}
            label={label}
            tooltipText={tooltipText}
            helperText={helperText}
            isEditable={isEditable}
            disabled={disabled}
            viewInEditMode={viewInEditMode}
            alignment={alignment}>
            {(editing || viewInEditMode) ? (
                // Edit mode
                <DateInput
                    id={name}
                    name={name}
                    value={selectedDate}
                    convertTimezone={convertTimezone}
                    utcDatetime={utcDatetime}
                    localDatetime={localDatetime}
                    onChange={(date) => handleDateChange(date)}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            ) : (
                // View mode
                <FieldViewMode 
                    value={formattedSentDate} 
                    alignment={alignment}
                />
            )}
        </FieldWrapper>
    );
};

export default SentDate;
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { useListClickHandlers } from './functions/useListClickHandlers';
import { ListProps, ListRowType } from 'types/ListTypes';
import ListContext from 'components/lists/ListContext';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import FilterButton from './FilterButton';
import IconButton from 'components/buttons/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

const ListButtons = <T extends ListRowType>({
    query, objectName, filters, showAddButton = true, showSearch, showImport, showExport, showColumnSelector, postNewItem, 
    formModal, detailModal, modalSize, buttonSize = 'medium', refetchList
}: ListProps<T>) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { userLocale, userTimezone } = useSettings();
    const hasRightCheck = useAllowedRight;
    const { searchTerm, loading, handleSearchChange } = useContext(ListContext);
    const { handlePrimaryButtonClick, handleSecondaryButtonClick } = useListClickHandlers({objectName, postNewItem, formModal, detailModal, modalSize, userLocale, userTimezone, refetchList});
    
    // Determine to show the list button container if no button feature is active
    let showListButtonContainer = true
    if (!showAddButton && !showSearch && (!showImport && !showExport || !hasRightCheck('can_export')) && !showColumnSelector && !filters) {
        showListButtonContainer = false;
    }

    return (
        showListButtonContainer ? (
            <div className='container list-button-wrapper'>
                <div className='button-container'>
                    {showAddButton && (
                        <PrimaryButton 
                            onClick={handlePrimaryButtonClick} 
                            onlyViewRestriction={true}
                            label={t('button.plus_add_object_label', { object_name: t(`${objectName}.singular`) })}
                            size={buttonSize} 
                        />
                    )}
                    {loading === 'show-loader' && <div className="loader"></div>}
                </div>
                <div className='button-container right-buttons'>
                    {showSearch && (
                        <div className='search-container' style={{ marginTop: '2px', marginRight: '1rem' }}>
                            <input
                                type='text'
                                placeholder={t('forms.search_placeholder')}
                                value={searchTerm}
                                onChange={handleSearchChange}
                            />
                        </div>
                    )}
                    {filters &&
                        <FilterButton 
                            filters={filters} 
                            size={buttonSize} 
                            defaultParams={query?.defaultParams} />
                    }
                    {showImport || (showExport && hasRightCheck('can_export')) ? (
                        <>
                            <IconButton
                                onClick={() => (history.push(`/import-data/${query.object}/`))}
                                icon={<FontAwesomeIcon icon={faCloudArrowUp} />}
                                tooltipText='button.import_button_label'
                                size="medium" />
                        
                            {/* <SecondaryButton
                                onClick={handleSecondaryButtonClick} 
                                size={buttonSize} 
                                label={showImport && (showExport && hasRightCheck('can_export')) ? t('button.import_export_button_label') : showImport ? t('button.import_button_label') : t('button.export_button_label')} /> */}
                        </>
                        
                    ) : null }
                    {/* 
                    // Temporarily commented out. Feature not yet released
                    {showColumnSelector && activeFeature('custom_column_selection') &&
                        <IconButton
                            onClick={() => setIsColumnSelectorOpen(true)} 
                            icon={<FontAwesomeIcon icon={faCog} />}
                            tooltipText={t('button.column_selector_button_label')}/> 
                    } 
                    */}
                </div>
            </div>
        ) : (
            null
        )
    );
};

export default ListButtons;
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';
import { useAllowedRight } from 'services/permissions/permissionChecks';
import { useListClickHandlers } from './functions/useListClickHandlers';
import ListContext from './ListContext';
import { ListRowProps, ListRowType } from 'types/ListTypes';
import { getDeletionLabel } from 'services/utils/deletionUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faGripLines } from '@fortawesome/free-solid-svg-icons';

const ListRow = <T extends ListRowType>({
    row, gridClass, query, objectName, columns, isDraggable, showDeleteOrDeactivate, showDeleteButton, 
    onRowClick, detailPageUrl, detailModal, detailModalName, releaseViewModal, wizardModal, formModal, editModal, modalSize, detailModalSize, 
    provided = null, snapshot = null
}: ListRowProps<T>) => {
    const { t } = useTranslation();
    const hasRightCheck = useAllowedRight;
    const { userLocale, userTimezone } = useSettings();
    const { refetchList } = useContext(ListContext);
    const { handleRowClick, handleDelete } = useListClickHandlers({
        apiObject: query.object, objectName, detailPageUrl, refetchList, onRowClick, detailModal, detailModalName, releaseViewModal, wizardModal, 
        formModal, editModal, modalSize, detailModalSize, userLocale, userTimezone
    });

    // Set the style for a draggable row. If the row is currently being dragged, the background color is changed
    const setDragStyle = (isDragging: boolean, draggableStyle: React.CSSProperties | undefined): React.CSSProperties => {
        return {    
            background: isDragging ? '#f4f4f4' : '',
            ...draggableStyle
        };
    }

    // Set the row props and style for draggable or non draggable lists
    const rowStyle = { gridTemplateColumns: gridClass, ...isDraggable ? setDragStyle(snapshot.isDragging, provided.draggableProps.style) : {} }
    
    return (
        <tr {...(isDraggable ? { ...provided.draggableProps, ...provided.dragHandleProps, ref: provided.innerRef } : {} )} 
            style={rowStyle} 
            id={row.id.toString()}
            key={row.id}
            onClick={() => handleRowClick(row.id, row)}>
            {isDraggable && (
                // Shows a drag icon if the list is draggable
                <td className='tooltip-column'>
                    <span className='list-drag-icon tooltip-icon'>
                        <FontAwesomeIcon icon={faGripLines} />
                        <span className="tooltip">{t('general.reorder')}</span>
                    </span>
                </td>
            )}
            {columns && columns.map((column) => (
                // Renders the row columns
                <td key={`${row.id}-${column.field}`}>
                    <div className='data'>
                        {column.render 
                            ? column.render(row[column.field], row as ListRowType) 
                            : row[column.field]}
                    </div>
                </td>
            ))}
            {showDeleteButton && (
                // Shows a delete button in lists with active or non-deleted query params, don't show it for users without delete rights
                <td className='delete-column'>
                    {hasRightCheck('can_delete') &&
                        <div className='list-delete-icon tooltip-icon'>
                            <FontAwesomeIcon
                                icon={faTrash}
                                className='fas fa-trash'
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => { e.stopPropagation(); handleDelete(row.id, showDeleteOrDeactivate);}} />
                            <span className="tooltip">{t(getDeletionLabel(showDeleteOrDeactivate))}</span>
                        </div>
                    }
                </td>
            )}
        </tr>
    )
}

export default ListRow;
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSettings } from 'services/settings/SettingsContext';
import { ColumnsMappingResults, ImportField, ImportFields, ImportValidations, ValidationResults } from 'components/import/ImportTypes';
import { convertUTCToLocalDateTimeString } from 'internationalization/timezoneConversions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faTag } from '@fortawesome/free-solid-svg-icons';

interface ImportOverviewProps {
    csvData: string[][];
    importFields: ImportFields;
    importValidations?: ImportValidations;
    importType: string | null;
    columnsMapping: ColumnsMappingResults[];
    skipFirstRow: boolean;
    validationResults: ValidationResults;
    apiObject: string;
    importTag: string | null;
    onTagGenerated: (tag: string) => void;
}

const ImportOverview: React.FC<ImportOverviewProps> = ({
    csvData, importFields, importValidations, importType, columnsMapping, skipFirstRow, validationResults, 
    apiObject, importTag, onTagGenerated
}) => {
    const { t } = useTranslation();
    const { userLocale, userTimezone } = useSettings();

    // Generate the import tag
    useEffect(() => {
        const generateImportTag = () => {
            // Get the current local date
            const localImportDate = convertUTCToLocalDateTimeString(new Date(), userLocale, userTimezone);

            // Configure the tag
            const tag = `${t(`${apiObject}.general.object_name.singular`)} import ${localImportDate}`;

            // Capitalize the first letter of the tag
            const capitalizedTag = tag.charAt(0).toUpperCase() + tag.slice(1);

            // Return the generated tag to the parent component
            onTagGenerated(capitalizedTag)
        };
        generateImportTag();
    }, [apiObject, onTagGenerated]);

    // Find the label of the validation backend options
    const findValidationOptionLabel = (field: string, backend_option: string | null, validations: ImportValidations | null, importType: string | null): string | null => {
        if (!backend_option || !validations) return null;
    
        // Combine base  and dynamic validaties
        const allValidations = [
            ...validations.validations,
            ...(importType && validations.dynamicValidations ? validations.dynamicValidations(importType) : []),
        ];
    
        // Find the validation for the specific field name
        const fieldValidation = allValidations.find((validation) => validation.field === field && validation.validation === 'mapValuesToOptions');
    
        // Find the label based on the value of the options
        if (fieldValidation?.options) {
            const option = fieldValidation.options.find((opt) => opt.value === backend_option);
            return option?.label || null;
        }
    
        return null;
    };

    // Get the name of the mapped field
    const getMappedFieldName = (mapping: string | undefined, importType: string | null): string | null => {
        if (!mapping) return null;

        // Combine base fields and dynamic fields
        const allFields = [
            ...importFields.baseFields,
            ...(importType && importFields.dynamicFields ? importFields.dynamicFields(importType) : []),
        ];
    
        // For mapped fields, get the name of the field
        for (const fieldGroup of allFields) {
            for (const [_, fields] of Object.entries(fieldGroup)) {
                const matchedField = fields.find((field: ImportField) => field.value === mapping);
                if (matchedField) {
                    return `import.mapping.${matchedField.name}`;
                }
            }
        }
        return null;
    };
     
    return (
        <div className='wizard-step'>
            <h3>{t('import.general.import_overview_header')}</h3>
            <div className='step-content import-overview'>
                <div className='import-overview-column'>

                    {/* Example table with mapped columns */}
                    <h4>{t('import.general.import_overview_imported_data_header')}</h4>
                    <h6>{t('import.general.import_overview_columns_label')}</h6>
                    <table className='import-example-table'>
                        <>
                            <thead>
                                <tr>
                                    {columnsMapping
                                        .filter((col) => col.mapping && col.mapping !== 'skip')
                                        .map((col, colIndex) => (
                                            <th key={colIndex}>
                                                {t(getMappedFieldName(col.mapping, importType))}
                                            </th>
                                    ))}
                                </tr>
                            </thead>
                            {csvData
                                .slice(skipFirstRow ? 1 : 0, 3)
                                .map((row, rowIndex) => (
                                    <tbody>
                                        <tr key={rowIndex}>
                                            {columnsMapping
                                                .filter((col) => col.mapping && col.mapping !== 'skip')
                                                .map((col) => (
                                                    <td key={col.index}>
                                                        <p>{row[col.index]}</p>
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    </tbody>
                                )
                            )}
                        </>
                    </table>

                    {/* Validations overview */}
                    <h6>{t('import.general.import_overview_conversions_label')}</h6>
                    {Object.entries(validationResults).map(([field, result], index) => (
                        <div key={index}>
                            {result.validation === 'notSelected' && (
                                <p>{t(`import.validation_result.${result.resolve}`)}</p>
                            )}
                            {result.validation === 'mapValuesToOptions' && Array.isArray(result.resolve) && importValidations && (
                                <div>
                                    <p>{t(`import.validation_result.${field}_${result.validation}`)}</p>
                                    {result.resolve.map((item, idx) => {
                                        const label = findValidationOptionLabel(
                                            field,
                                            item.backend_option,
                                            importValidations,
                                            importType
                                        );

                                        return (
                                            <div key={idx} className="mapping-item">
                                                <p>{item.unique_value}</p>
                                                <span className='icon'><FontAwesomeIcon icon={faArrowRightLong} /></span>
                                                <p>{label ? t(label) : t('import.general.validations_mapping_dont_import')}</p>                          
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>
                    ))}
                </div>

                {/* Import tag */}
                <div className='import-overview-column'>
                    <h4>{t('import.general.import_overview_import_tag_label')}</h4>
                    <p>{t('import.general.import_overview_import_tag_description')}</p>
                    {importTag && (
                        <div className="import-tag">
                            <span className='icon'><FontAwesomeIcon icon={faTag} /></span> 
                            <p>{importTag}</p>
                        </div>
                    )}
                </div>

                {/* Start import */}
                <div className='import-overview-column'>
                    <h4>{t('import.general.import_overview_start_import_label')}</h4>
                    <p>{t('import.general.import_overview_start_import_description')}</p>
                </div>
            </div>
        </div>
    );
};

export default ImportOverview;
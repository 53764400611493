import React from 'react';
import SettingsContainer, { SettingsSettingType } from '../../../components/settings/SettingsContainer';

const InvoiceSettings: React.FC = () => {
    const settings: SettingsSettingType[] = [
        {
            title: 'Standaard valuta',
            fields: [
                {
                    type: 'dropdown',
                    name: 'invoice_default_currency',
                    disabled_selected: 'forms.general_disabled_selected',
                    allowNoneOption: false,
                    saveOnBlur: true,
                    apiObject: 'currency',
                    backendField: 'key'
                },
            ]
        },
        {
            title: 'Factuur nummerreeks',
            explanationText: 'Uitleg hoe dit werkt',
            fields: [
                {
                    type: 'text',
                    label: 'Nummerreeks',
                    name: 'invoice_numbering_scheme',
                    placeholder: '',
                    group: 'field-row'
                },
                {
                    type: 'number',
                    label: 'Volgend nummer',
                    name: 'invoice_current_number',
                    group: 'field-row'
                }
            ]
        },
        {
            title: 'G-rekening veld tonen',
            explanationText: 'Toon een veld op je facturen waarop je het G-rekening bedrag en informatie kunt invullen.',
            switchButton: {
                enabled: true,
                name: 'invoice_show_tax_blocked_acount',
            }
        },
    ];

    return (
        <SettingsContainer settings={settings} />
    );
};

export default InvoiceSettings;
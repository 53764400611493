// Restrict homepage access based on environment type
export const isHomepageAllowed = (route: string, environmentType: string) => {
    
    // Admin environments can access all routes
    if (environmentType === 'admin') {
        return !['/trial-homepage', '/beta-homepage'].includes(route);
    }

    // Trial environments cannot access beta homepage or admin dashboard
    if (environmentType === 'trial') {
        return !['/beta-homepage', '/admin-dashboard'].includes(route);
    }

    // Beta environments cannot access trial homepage or admin dashboard
    if (environmentType === 'beta') {
        return !['/trial-homepage', '/admin-dashboard'].includes(route);
    }

    // Client users cannot access trial homepage, beta homepage and admin dashboard
    if (environmentType === 'client' || environmentType === 'demo') {
        return !['/trial-homepage', '/beta-homepage', '/admin-dashboard'].includes(route);
    }

    return false;
};
import React from 'react';
import { ListColumnType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';

// Rename the name of the function:
const ImportList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_import_list',
        // defaultParams: { 'is_active': true },
        object: 'import'
    }
    const objectName = 'import.general.object_name'

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const showAddButton = false;
    const detailModalName = 'import-detail-modal'
    const detailModalSize = 'medium-large';
    const onRowClick = 'detailModal'
    
    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'import_tag_name', label: 'import.general.import_label' },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            columns={columns}
            isPaginated={isPaginated}
            showAddButton={showAddButton}
            detailModalName={detailModalName}
            detailModalSize={detailModalSize}
            onRowClick={onRowClick}
        />
    );
};

export default ImportList;
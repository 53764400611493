import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useModal } from './ModalContext';
import PrimaryButton from 'components/buttons/PrimaryButton';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import '../../style/scss/modals.scss';

interface WarningModalProps {
    header: string,
    message: string;
    buttonLabel: string;
    onApproval?: () => void;
}

const WarningModal: React.FC<WarningModalProps> = ({ 
    header, message, buttonLabel, onApproval
}) => {
    const { t } = useTranslation();
    const { revealModal, closeModal } = useModal();

    // Reveal the modal on first render
    useEffect(() => {
        revealModal();
    }, []);

    // Handle the delete
    const handleApproval = async () => {
        // Execute approval callback
        if (onApproval) {
            onApproval();
        }

        // Close the modal
        closeModal();
    };

    return (
        <div className='warning-modal'>
            <div className='warning-icon'>
                <FontAwesomeIcon 
                    icon={faTriangleExclamation} 
                />
            </div>
            <h3 className='header text-center'>
                {t(header)}
            </h3>
            <p className='text-center'>
                {t(message)}
            </p>
            <div className='button-row'>
                <SecondaryButton
                    onClick={() => closeModal()}
                    label='general.cancel'
                    size="small"
                />
                <PrimaryButton
                    onClick={() => handleApproval()}
                    label={buttonLabel}
                    customClass='approve'
                    size="small"
                />
            </div>
        </div>
    )
}

export default WarningModal;
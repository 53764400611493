import React from 'react';
import { EditModalType, ListColumnType } from 'types/ListTypes';
import AbstractList from 'components/lists/AbstractList';
import WorkflowForm from './WorkflowForm';
import WorkflowEditForm from './WorkflowEditForm';

// Rename the name of the function:
const WorkflowList: React.FC = () => {

    // Specify the options of this list:
    const query = {
        endpoint: 'get_workflow_list',
        defaultParams: { 'is_active': true },
        object: 'workflow'
    }
    const groupByField = 'connected_feature'
    const objectName = 'workflow.general.object_name'
    const showSearch = false
    const showDeleteOrDeactivate = 'flag_deactivated'

    // Set isPaginated to true if this list may have 100.000+ items:
    const isPaginated = false

    // Specify form, detail modal or detailpage for this list
    const formModal = <WorkflowForm />;
    const onRowClick = 'editModal'
    const editModal: EditModalType = {
        modal: <WorkflowEditForm />,
        modalSize: 'medium'
    }

    // Specify the columns of this list:
    const columns: ListColumnType[] = [
        { width: '1fr', field: 'name', label: 'workflow.general.name_label' },
    ];

    return (
        <AbstractList
            query={query}
            objectName={objectName}
            groupByField={groupByField}
            columns={columns}
            showSearch={showSearch}
            showDeleteOrDeactivate={showDeleteOrDeactivate}
            formModal={formModal}
            editModal={editModal}
            isPaginated={isPaginated}
            onRowClick={onRowClick}
        />
    );
};

export default WorkflowList;
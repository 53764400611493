import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'services/authentication/AuthenticationContext';
import { useGlobalContext } from 'GlobalContext';
import { useSettings } from 'services/settings/SettingsContext';
import { useModal } from './ModalContext';
import { EditableStatusType, FieldOption, FieldOptionFetchResponse, FieldType } from 'types/FieldTypes';
import { fetchDataAndOptionsOfDetailCards } from 'services/api/fetchFieldData';
import { TabType } from 'types/TabTypes';
import { DetailCardType } from 'types/DetailPageTypes';
import { TabBar } from 'components/layout/TabBar';
import StatusBar from 'components/layout/StatusBar';
import FieldsCard from 'components/detailcards/FieldsCard';
import PeopleCard from 'components/detailcards/PeopleCard';
import GenerateHeaderTitle from './functions/GenerateHeaderTitle';
import PhotosCard from 'components/detailcards/PhotosCard';
import TabsCard from 'components/detailcards/TabsCard';
import MessagesCard from 'components/detailcards/MessagesCard';
import { HeaderButtons } from 'types/ButtonTypes';
import generateHeaderButtons from './functions/generateHeaderButtons';
import '../../style/scss/modals.scss';
import DetailModalSummary from './DetailModalSummary';
import { isArrayOfConditions } from 'services/utils/isArrayOfConditions';

export interface DetailModalProps {
    viewKey?: string;
    objectType?: 'proposal' | 'job' | 'project' | 'invoice';
    apiObject?: string;
    itemId?: number;
    headerButtons?: HeaderButtons;
    statusBar?: boolean;
    sections?: DetailCardType[];
    tabs?: TabType[];
    summary?: boolean;
    toggleSideTab?: (showSideTab: boolean) => void;
    refetchList?: () => void;
}

const DetailModal: React.FC<DetailModalProps> = ({ 
    viewKey, objectType, apiObject, itemId, headerButtons, statusBar, sections, tabs, summary = false, refetchList
}) => {
    const { t } = useTranslation();
    const { setFloatingAlert } = useGlobalContext();
    const { activeFeatures, allowedFeatures, allowedRights, handleLogout } = useAuthContext();
    const { userLocale, userTimezone } = useSettings();
    const { initializeModal, revealModal, closeModal } = useModal();
    const [data, setData] = useState(null);
    const [statuses, setStatuses] = useState(null);
    const [currentStatus, setCurrentStatus] = useState<number | null>(null);
    const [loadStatus, setLoadStatus] = useState<"idle" | "loading" | "loaded">("idle");
    const [dropdownData, setDropdownData] = useState<Record<string, FieldOptionFetchResponse>>({});
    const [groupOptions, setGroupOptions] = useState<FieldOption[]>([])
    const [buttonLoader, setButtonLoader] = useState<Record<string, boolean>>({});
    const [editable, setEditable] = useState<EditableStatusType>({ editability: true });

    // Fetch the data on initial render
    useEffect(() => {
        fetchData();
    }, []);

    // Function to fetch the data to be used on initial render and for refetches
    const fetchData = async () => {
        if (!itemId || !apiObject) return;
        
        // Fetch the data
        const fetchedData = await fetchDataAndOptionsOfDetailCards(
            apiObject, 
            itemId.toString(),
            dropdownData, 
            groupOptions,
            headerButtons, 
            sections, 
            setData, 
            setDropdownData, 
            setGroupOptions, 
            setLoadStatus, 
            handleLogout
        )

        if (!fetchedData) return;

        // Get the fetched data of the object
        const [fetchedObjectData] = fetchedData;

        // Set editability of the modal based on the data
        if (objectType) {

            // For jobs which have the finished resolution
            if (objectType === 'job' && fetchedObjectData['resolution'] === 'finished') {
                setEditable({ editability: false });
            }

            // For invoices which are not drafts
            if (objectType === 'invoice' && fetchedObjectData['status'] != 'draft') {
                setEditable({ 
                    editability: false, 
                    warning: {
                        header: 'invoice.general.edit_warning_header',
                        message: 'invoice.general.edit_warning_message',
                        buttonLabel: 'invoice.general.edit_warning_button_label' 
                    }
                });
            }

            // For proposals which are not drafts. Exclude proposals which are orders, they are always editable
            if ((objectType === 'proposal' && fetchedObjectData['status'] != 'draft') && fetchedObjectData['is_order'] == false) {
                setEditable({ 
                    editability: false, 
                    warning: {
                        header: 'proposal.general.edit_warning_header',
                        message: 'proposal.general.edit_warning_message',
                        buttonLabel: 'proposal.general.edit_warning_button_label' 
                    }
                });
            }
        }

        // Set the statuses from the received data
        if (statusBar) {
            setStatuses(fetchedObjectData['workflow']['statuses']);
            setCurrentStatus(fetchedObjectData['status']);
        }

        // Return the fetched data
        return fetchedData;
    }
    
    useEffect(() => {
        if (data !== null) {
            // Reveal the modal after successfully fetching the data
            revealModal();
        }
    }, [data])

    // Initialize the tab bar if there are tabs given
    let tabLabel, tabComponent;
    if (tabs && itemId && viewKey) {
        ({ tabLabel, tabComponent } = TabBar({tabs, viewKey, itemId: itemId.toString(), pageContext: apiObject}));
    }
    
    // Generates the card components
    const generateCardComponent = (
        viewKey: string,
        editable: EditableStatusType,
        componentType: string, 
        fields: FieldType[] | undefined, 
        fieldsColumns: FieldType[][] | undefined, 
        title: string | undefined,
        itemId: string, 
        apiObject: string, 
        data: any, 
        apiField: string | undefined, 
        alignment: 'horizontal' | 'stacked' | undefined,
        dataCondition?: { [key: string]: any },
    ) => {
        // If a data condition is set for the card, only render it if the corresponding data object has this value
        if (dataCondition && data) {
            const shouldDisplayCard = dataCondition.every((condition: { [key: string]: any }) =>
                Object.entries(condition).every(([key, value]) => data[key] === value)
            );
            if (!shouldDisplayCard) {
                return null;
            }
        }

        switch (componentType) {
            case 'FieldsCard':
                return <FieldsCard apiObject={apiObject} viewKey={viewKey} isEditable={editable} itemId={itemId} title={title} fields={fields} fieldColumns={fieldsColumns} data={data} dropdownData={dropdownData} groupOptions={groupOptions} alignment={alignment} loadStatus={loadStatus} refetchData={fetchData} />
            case 'PhotosCard':
                return <PhotosCard apiObject={apiObject} viewKey={viewKey} isEditable={editable} itemId={itemId} title={title} data={data} dropdownData={dropdownData} groupOptions={groupOptions} alignment={alignment} loadStatus={loadStatus} refetchData={fetchData} />
            case 'TabsCard':
                return <TabsCard apiObject={apiObject} viewKey={viewKey} itemId={itemId} title={title} data={data} dropdownData={dropdownData} groupOptions={groupOptions} alignment={alignment} loadStatus={loadStatus} refetchData={fetchData} />
            case 'MessagesCard':
                return <MessagesCard apiObject={apiObject} viewKey={viewKey} itemId={itemId} title={title} data={data} dropdownData={dropdownData} groupOptions={groupOptions} alignment={alignment} loadStatus={loadStatus} refetchData={fetchData} />
            case 'PeopleCard':
                return <PeopleCard apiObject={apiObject} viewKey={viewKey} isEditable={editable} itemId={itemId} personIds={apiField ? data[apiField] : undefined} loadStatus={loadStatus} />
            // Plug in other card components
            default:
                return null;
        }
    }

    // Generate the header buttons
    const generatedHeaderButtons = data && headerButtons && itemId && apiObject ? generateHeaderButtons(
        headerButtons, 
        apiObject, 
        data, 
        dropdownData,
        itemId, 
        initializeModal, 
        buttonLoader, 
        setButtonLoader, 
        setFloatingAlert,
        handleLogout,
        { activeFeatures, allowedFeatures, allowedRights },
        userTimezone,
        userLocale,
        t,
        closeModal,
        fetchData,
        refetchList
    ) : null;

    return (
        <div className={`detailmodal row ${summary ? 'summary' : ''} `}>
            <div style={{ width: summary ? '80%' : '100%' }}>
                <div className='header'>
                    <div className='title-bar'>
                        <h2>
                            <GenerateHeaderTitle apiObject={apiObject} data={data} />
                        </h2>
                        {headerButtons && 
                            // Render the header buttons of the detail modal
                            <div className='button-container'>
                                {generatedHeaderButtons}
                            </div>
                        }
                    </div>
                    {statusBar && statuses && objectType && (
                        // Render the status bar of the detail modal
                        <StatusBar statuses={statuses} 
                                   currentStatus={currentStatus} 
                                   workflowType={objectType} 
                        />
                    )}
                </div>
                <div className='content'>
                    {sections &&
                        // Render the sections of the detail modal
                        <div>
                            {sections.map((section) =>
                                <div key={`${section.title}-${section.component}`}>
                                    {section.component && itemId && apiObject && viewKey &&
                                        generateCardComponent(
                                            viewKey,
                                            editable,
                                            section.component,
                                            section.fields || [],
                                            section.fieldColumns || [],
                                            section.title,
                                            itemId.toString(),
                                            apiObject,
                                            data,
                                            section.apiField,
                                            section.alignment,
                                            isArrayOfConditions(section.dataCondition) ? section.dataCondition : undefined
                                        )
                                    }
                                </div>
                            )}
                        </div>
                    }
                    {tabs &&
                        // Render the tabs with its child components of the detail modal
                        <div>
                            {tabLabel && tabLabel.length > 1 && (
                                <ul className='card tabbar'>
                                    {tabLabel}
                                </ul>
                            )}
                            {tabComponent}                       
                        </div>
                    }
                </div>
            </div>
            
            {summary && objectType &&
                // Render the summary next to the detail modal
                <DetailModalSummary objectType={objectType} data={data} />
            }
        </div>
    );
};

export default DetailModal;
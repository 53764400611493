import React from 'react';
import { useFilteredDropdownOptionsByFeature } from 'services/permissions/permissionChecks';
import { FieldType } from 'types/FieldTypes';
import FormModal from 'components/modals/FormModal';

// Rename the name of the function:
const LayoutForm: React.FC<{ viewKey?: string }> = ({ viewKey }) => {

    const apiObject = 'layout'

    // Plug in the fields and their properties:
    const fields: FieldType[] = [
        { 
            type: 'radio', 
            name: 'layout_type',
            label: 'layout.general.layout_type_label',
            defaultValue: 'pdf', 
            options: [
                { value: 'pdf', name: 'layout.general.layout_type_pdf_label'},
                { value: 'email', name: 'layout.general.layout_type_email_label'},
            ],
        },
        {
            name: 'name',
            label: 'layout.general.name_label',
            type: 'text',
            placeholder: 'layout.general.name_placeholder',
        },
        { 
            type: 'dropdown', 
            name: 'connected_feature', 
            dependentOn: { fieldName: 'layout_type', value: 'pdf' },
            label: 'layout.general.connected_feature_label', 
            disabled_selected: 'forms.select_type_disabled_selected',
            allowNoneOption: false,
            showSearch: false,
            hardcodedOptions: useFilteredDropdownOptionsByFeature([
                { value: 'proposal', name: 'layout.connected_feature.proposal', requiredFeature: 'proposals' },
                { value: 'order', name: 'layout.connected_feature.order', requiredFeature: 'proposals' },
                { value: 'job', name: 'layout.connected_feature.job', requiredFeature: 'jobs' },
                { value: 'invoice', name: 'layout.connected_feature.invoice', requiredFeature: 'invoices' },
            ]),
        },
    ];

    return (
        <FormModal 
            viewKey={viewKey}
            apiObject={apiObject}
            fields={fields}
        />
    );
};

export default LayoutForm;